import React, { Component } from "react";
import {Link} from "react-router-dom";
import { FiChevronDown } from 'react-icons/fi';
import Select from "react-select";

import './ScheduleApproval.scss';
import MyContext from '../MyContext.jsx';


class ScheduleApproval extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schedules:  [],
      requested_schedules : [],
      approved_schedules : [],
      cancelled_schedules : [],
      schedule_id : '',
      executives  : [],
      executive : '',
    }
  }

  componentDidMount(){    
    var obj_this = this;
    const url = `${process.env.REACT_APP_SSS}/api/get/scheduleRequestsByExecutive`;
    this.context.loading(1);
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      if(return_data.status == "success"){ 
        const schedules=return_data.schedules;
        const requested_schedules = schedules.filter((item) => item.status == "REQ");
        const approved_schedules = schedules.filter((item) => item.status == "APR");
        const cancelled_schedules= schedules.filter((item)=> item.status == "CNL"||item.status=="APR-CNL")
        obj_this.setState({
          requested_schedules :requested_schedules,
          approved_schedules  : approved_schedules,
          cancelled_schedules : cancelled_schedules,
          executives  : return_data.executives,
        });

        obj_this.context.loading(0);
        console.log(obj_this.state);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      obj_this.context.loading(0);
      alert("Network error");
    });

    $(".schedule_approval_page_container").on("click",".schedule_approve_button",function(e){
      e.stopPropagation();
      if(!confirm("Do you want to approve this schedule?")){
        return;
      }
      const schedule_id = $(this).attr("schedule_id");
      obj_this.setState({
        schedule_id : $(this).attr("schedule_id")
      });
      const url = `${process.env.REACT_APP_SSS}/api/scheduleRequestLocationApproval`;
      obj_this.context.loading(1);
      $.ajax({
        url : url,
        data : {
          sid : obj_this.context.user_details.sid,
          schedule_id : schedule_id,
        },
        type : "POST"
      }).done(function(return_data){
          console.log(return_data);
        if(return_data.status == "success"){
          obj_this.afterExecutiveChange();
          obj_this.context.loading(0);
          alert("Schedule Approved.")
        }else{
          obj_this.context.loading(0);
          obj_this.props.history.replace("/");
        }
      }).fail(function(){
        obj_this.context.loading(0);
        alert("Network error");
      });
  });

  $(".schedule_approval_page_container").on("click",".schedule_cancel_button",function(e){
    e.stopPropagation();
    if(!confirm("Do you want to cancel this schedule?")){
      return;
    }
    obj_this.setState({
      schedule_id : $(this).attr("schedule_id")
    });
    const schedule_id = $(this).attr("schedule_id");

    const url = `${process.env.REACT_APP_SSS}/api/cancel/schedulerequest`;
    obj_this.context.loading(1);
    $.ajax({
      url : url,
      data : {
        sid : obj_this.context.user_details.sid,
        schedule_id : schedule_id,
      },
      type : "POST"
    }).done(function(return_data){
        console.log(return_data);
      if(return_data.status == "success"){
        obj_this.afterExecutiveChange();
        obj_this.context.loading(0);
        alert("Schedule Cancelled.")
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      obj_this.context.loading(0);
      alert("Network error");
    });

  });

  $(".schedule_approval_page_container").on("click",".schedule_approve_all_button",function(e){
    e.stopPropagation();
    if(!confirm("Do you want to approve all schedules?")){
      return;
    }
    let schedule_ids=null;
    schedule_ids=obj_this.state.requested_schedules.map(item=>item.id);
    const url = `${process.env.REACT_APP_SSS}/api/approveAll/scheduleRequestsAllLocationsApproval`;
    obj_this.context.loading(1);
    $.ajax({
      url : url,
      data : {
        sid : obj_this.context.user_details.sid,
        schedule_ids : schedule_ids,
      },
      type : "POST"
    }).done(function(return_data){
        console.log(return_data);
      if(return_data.status == "success"){
        obj_this.afterExecutiveChange();
        obj_this.context.loading(0);
        alert("Schedule Approved.")
      }else if(return_data.status == "fail"){
        obj_this.context.loading(0);
        alert("No Schedules to Approve");
      }
      else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      obj_this.context.loading(0);
      alert("Network error");
    });
  });

  $(".schedule_approval_page_container").on("click",".schedule_cancel_all_button",function(e){
    e.stopPropagation();
    if(!confirm("Do you want to reject all schedules?")){
      return;
    }
    let schedule_ids=null;
    schedule_ids=obj_this.state.requested_schedules.map(item=>item.id);
    const url = `${process.env.REACT_APP_SSS}/api/rejectAll/scheduleRequestsAllLocationsReject`;
    obj_this.context.loading(1);
    $.ajax({
      url : url,
      data : {
        sid : obj_this.context.user_details.sid,
        schedule_ids : schedule_ids,
      },
      type : "POST"
    }).done(function(return_data){
        console.log(return_data);
      if(return_data.status == "success"){
        obj_this.afterExecutiveChange();
        obj_this.context.loading(0);
        alert("Schedules Rejected.")
      }else if(return_data.status == "fail"){
        obj_this.context.loading(0);
        alert("No Schedules to Reject");
      }
      else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      obj_this.context.loading(0);
      alert("Network error");
    });
  });

  ui.swipeMenu(".sales_orders_container");
}

executiveChange(value){
  this.setState({
    executive : value
  }, () => {
    this.afterExecutiveChange();
});
}

afterExecutiveChange(){
  var obj_this = this;
    const url = `${process.env.REACT_APP_SSS}/api/get/scheduleRequestsByExecutive`;
    this.context.loading(1);
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid,
        executive_id  : obj_this.state.executive.value
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      if(return_data.status == "success"){ 
        const schedules=return_data.schedules;
        const requested_schedules = schedules.filter((item) => item.status == "REQ");
        const approved_schedules = schedules.filter((item) => item.status == "APR");
        const cancelled_schedules= schedules.filter((item)=> item.status == "CNL"||item.status=="APR-CNL")
        obj_this.setState({
          requested_schedules :requested_schedules,
          approved_schedules  : approved_schedules,
          cancelled_schedules : cancelled_schedules,
          executives  : return_data.executives,
        });

        obj_this.context.loading(0);
        console.log(obj_this.state);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      obj_this.context.loading(0);
      alert("Network error");
    });


}

  
  render(){
  return (
    <MyContext.Consumer>
      {context => (
        <div className="schedule_approval_page_container noselect">
          <div className="header_spacer"></div>
          <div className="sales_orders_container uijs swipemenu">
            <div className="page_header">
              <div className="page_title">Schedule List</div>
              <Select 
              options = {this.state.executives}
              value = {this.state.executive}
              onChange={value=>this.executiveChange(value)}
              />
              
            </div>
            {/* <div className="page_executive_select"> */}
              
              
            {/* </div> */}
          
            <div className="toggles_container">
              <div className="list_1 toggles" menu="0">
                <i className="fa fa-clipboard-list order_status_icon"></i>
                <div className="order_status">Requested</div>
                <div className="order_count">{this.state.requested_schedules.length}</div>
              </div>
              <div className="list_2 toggles" menu="1">
                <i className="fa fa-check-circle order_status_icon"></i>
                <div className="order_status">Approved</div>
                <div className="order_count">{this.state.approved_schedules.length}</div>
              </div>
              <div className="list_3 toggles" menu="2">
                <i className="fa fa-hourglass-start order_status_icon"></i>
                <div className="order_status">Cancelled</div>
                <div className="order_count">{this.state.cancelled_schedules.length}</div>
              </div>

            </div>
        
            <div className="list_blocks_container">
            <div className="lists_container">
                <div className="approve_all_cancel_all_schedule">
                  <div className="schedule_approve_all_button"
                    >
                      <i className="fas fa-check"><span>APPROVE ALL</span></i>
                  </div>
                  <div className="schedule_cancel_all_button"
                    >
                      <i className="fa fa-times-circle"><span>REJECT ALL</span></i>
                  </div>
                </div>
                {this.state.requested_schedules.map((item,idx)=>(
                   <div className="single_schedule_container" key={idx}>
                   <div className="scheduled_customer_name_delete_button_container">
                     <div className="scheduled_date_container">
                       <div className="scheduled_month">{new Date(item.date).toLocaleString('default', { month: 'short' })}</div>
                       <div className="scheduled_day">{new Date(item.date).getDate()}</div>
                     </div>
                     <div className="customer_name_check_in_button_container">
                        <div className="scheduled_customer_name"><i className='fa fa-map-marker-alt'></i> {item.location}</div>
                        <div className="scheduled_customer_name"><i className='fa fa-user'></i> {item.executive}</div>
                        <div className="approve_cancel_schedule">
                          <div className="schedule_approve_button"
                                schedule_id = {item.id}
                                style={{visibility:item.status=="REQ"?"visible":"hidden"}}
                            >
                              <i className="fas fa-check"></i>
                          </div>
                          <div className="schedule_cancel_button"
                                schedule_id = {item.id}
                                style={{visibility:item.status=="REQ"?"visible":"hidden"}}
                            >
                              <i className="fa fa-times-circle"></i>
                          </div>
                        </div>
                        {/* <div className="status_of_schedules">
                          <div className="schedule_requested" style={{display:item.status=="REQ"?"block":"none"}}><i className="fa fa-clock" aria-hidden="true"></i> REQUESTED</div>
                          <div className="schedule_approved" style={{display:item.status=="APR"?"block":"none"}}> <i className="fa fa-check" aria-hidden="true"></i>APPROVED</div>
                          <div className="schedule_rejected" style={{display:item.status=="CNL"?"block":"none"}}> <i className="fa fa-times" aria-hidden="true"></i>REJECTED</div>
                        </div> */}
                      </div>
                      
                     <div></div>
                   </div>
                 </div>
                ))}
              </div>
            <div className="lists_container">
              {this.state.approved_schedules.map((item,idx)=>(
                   <div className="single_schedule_container" key={idx}>
                   <div className="scheduled_customer_name_delete_button_container">
                     <div className="scheduled_date_container">
                       <div className="scheduled_month">{new Date(item.date).toLocaleString('default', { month: 'short' })}</div>
                       <div className="scheduled_day">{new Date(item.date).getDate()}</div>
                     </div>
                     <div className="customer_name_check_in_button_container">
                        <div className="scheduled_customer_name"><i className='fa fa-map-marker-alt'></i> {item.location}</div>
                        <div className="scheduled_customer_name"><i className='fa fa-user'></i> {item.executive}</div>
                        <div className="status_of_schedules">
                          <div className="schedule_requested" style={{display:item.status=="REQ"?"block":"none"}}><i className="fa fa-clock" aria-hidden="true"></i> REQUESTED</div>
                          <div className="schedule_approved" style={{display:item.status=="APR"?"block":"none"}}> <i className="fa fa-check" aria-hidden="true"></i>APPROVED</div>
                          <div className="schedule_rejected" style={{display:item.status=="CNL"||item.status=="APR-CNL"?"block":"none"}}> <i className="fa fa-times" aria-hidden="true"></i>REJECTED</div>
                        </div>
                      </div>
                      
                     <div></div>
                   </div>
                 </div>
                ))}
            </div>

              <div className="lists_container">
              {this.state.cancelled_schedules.map((item,idx)=>(
                   <div className="single_schedule_container" key={idx}>
                   <div className="scheduled_customer_name_delete_button_container">
                     <div className="scheduled_date_container">
                       <div className="scheduled_month">{new Date(item.date).toLocaleString('default', { month: 'short' })}</div>
                       <div className="scheduled_day">{new Date(item.date).getDate()}</div>
                     </div>
                     <div className="customer_name_check_in_button_container">
                        <div className="scheduled_customer_name"><i className='fa fa-map-marker-alt'></i> {item.location}</div>
                        <div className="scheduled_customer_name"><i className='fa fa-user'></i> {item.executive}</div>
                        <div className="status_of_schedules">
                          <div className="schedule_requested" style={{display:item.status=="REQ"?"block":"none"}}><i className="fa fa-clock" aria-hidden="true"></i> REQUESTED</div>
                          <div className="schedule_approved" style={{display:item.status=="APR"?"block":"none"}}> <i className="fa fa-check" aria-hidden="true"></i>APPROVED</div>
                          <div className="schedule_rejected" style={{display:item.status=="CNL"||item.status=="APR-CNL"?"block":"none"}}> <i className="fa fa-times" aria-hidden="true"></i>REJECTED</div>
                        </div>
                      </div>
                      
                     <div></div>
                   </div>
                 </div>
                ))}
              </div>

            </div>

          </div>
          <div className="bottom_navbar_spacer"></div>

        </div>
      )}
    </MyContext.Consumer>
  )}
}

export default ScheduleApproval;
ScheduleApproval.contextType = MyContext;