import React from 'react';
import { ReactComponent as Loadersvg } from './loader.svg';
import './Loading.scss';

const Loader = () => {
  return (
    <div className='loading_container'>
        <div className='loading_symbol'>
            <Loadersvg />
        </div>
    </div>
  );
};

export default Loader;