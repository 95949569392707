import React, { Component } from "react";
import {Link} from "react-router-dom";
import Select from "react-select";
import DatePicker from 'react-date-picker';
import './NewTravelAllowance.scss';
import MyContext from '../MyContext.jsx';
import {epochToDate} from '../../assets/js/handy.mjs';

class NewTravelAllowance extends Component {
  constructor(props) {
    super(props);
    this.typeSelect = React.createRef();
    this.state = {
      id : '',
      date : new Date(),
      allowance_head : [],
      total : 0,
      location:'',
      num_of_calls:0,
    }
    this.formChange = this.formChange.bind(this);
    this.checkboxChange = this.checkboxChange.bind(this);

  }

  componentDidMount(){   
    $(".react-date-picker__inputGroup input").attr("readonly","true");
    var obj_this = this;
    const {pathname} = this.props.location;
    if(pathname === "/travel/allowance/new") {
      const url = `${process.env.REACT_APP_SSS}/api/getTravelAllowanceDetails`;
      this.context.loading(1);
      $.ajax({
        url : url,
        data : {
          sid : this.context.user_details.sid
        },
        type : "POST"
      }).done(function(return_data){
        console.log(return_data);
        // return;
        if(return_data.status == "success"){
          var allowance_head = return_data.allowance_head;
          if(allowance_head.length){
            for(var i=0;i<allowance_head.length;i++){
              if(allowance_head[i]['child'].length){
                allowance_head[i]['type'] = allowance_head[i]['child'][0];
                allowance_head[i]['value'] = allowance_head[i]['child'][0]['amount'];
              }
            }
          }
          obj_this.setState({
            allowance_head : allowance_head,
          },() => {
            $(".new_travel_allowance_container").css("visibility","visible");
          });
          obj_this.context.loading(0);
        }else{
          obj_this.context.loading(0);
          obj_this.props.history.replace("/");
        }
      }).fail(function(){
        alert("Network error");
      });
    }else{
      const ta_id = this.props.match.params.ta_id;
      const url = `${process.env.REACT_APP_SSS}/api/editTravelAllowanceDetails`;
      this.context.loading(1);
      $.ajax({
        url : url,
        data : {
          ta_id : ta_id,
          sid : this.context.user_details.sid
        },
        type : "POST"
      }).done(function(return_data){
        console.log(return_data);
        // return;
        if(return_data.status == "success"){
          var state_object = return_data.state_object;
          state_object.date = epochToDate(state_object.date);
          state_object.total = parseFloat(state_object.total).toFixed(2);
          state_object.num_of_calls=state_object.num_calls;
          obj_this.setState(state_object,() => {
            for(var i=0;i<state_object.allowance_head.length;i++){
              if(state_object.allowance_head[i]['checkbox_value']){
                $("#" + i).heightAuto();
              }
              state_object.allowance_head[i]['from'] = state_object.allowance_head[i]['from_km'];
              state_object.allowance_head[i]['to'] = state_object.allowance_head[i]['to_km'];
               state_object.allowance_head[i]['kilometers'] = state_object.allowance_head[i]['to'] - state_object.allowance_head[i]['from'];
            }
            $(".new_travel_allowance_container").css("visibility","visible");
          });
          obj_this.context.loading(0);
        }else{
          obj_this.context.loading(0);
          obj_this.props.history.replace("/");
        }
      }).fail(function(){
        alert("Network error");
      });
    }

    const url3 = `${process.env.REACT_APP_SSS}/api/getVisitFrequencyCountPerDay`;
    // customer_XHR.abort();
    const sel_date=new Date();
    $.ajax({
      url : url3,
      data : {
        sid : this.context.user_details.sid,
        date : sel_date.toLocaleDateString('en-CA')
      },
      type : "POST"
    }).done(function(return_data){
      // console.log(return_data);
      if(return_data.status=="success"){
          // var node = singleCustomer_DOM.clone();
          obj_this.state.num_of_calls=return_data.visit_count;
        //   $(".customer_suggestions_container").append(node);
        // $(".customer_suggestions_container").css("display","block");
        obj_this.context.loading(0);
      }else{
        obj_this.state.num_of_calls=0;
        obj_this.context.loading(0);
      }
    });
  }

  locationChange(e){
    this.setState({
      [e.target.name] : e.target.value
    });
  }

  callChange(e){
    this.setState({
      [e.target.name] : e.target.value
    });
  }

 

  onDateChange(value){
    this.setState({date : value});

    const obj_this = this;
    const url = `${process.env.REACT_APP_SSS}/api/getVisitFrequencyCountPerDay`;
    // customer_XHR.abort();
    const sel_date=new Date(value);
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid,
        date : sel_date.toLocaleDateString('en-CA')
      },
      type : "POST"
    }).done(function(return_data){
      // console.log(return_data);
      if(return_data.status=="success"){
          // var node = singleCustomer_DOM.clone();
          obj_this.state.num_of_calls=return_data.visit_count;
        //   $(".customer_suggestions_container").append(node);
        // $(".customer_suggestions_container").css("display","block");
        obj_this.context.loading(0);
      }else{
        obj_this.state.num_of_calls=0;
        obj_this.context.loading(0);
      }
    });


  }

  formChange(e,idx){
    var allowance_head = [...this.state.allowance_head];
    var item = allowance_head[idx];
    var entered_amount = parseInt(e.target.value);
    if(entered_amount > item.value){
      alert("MAXIMUM ALLOWED FOR "+item.name+" PER DAY IS RS. "+parseInt(item.value));
      entered_amount = parseInt(item.value);
    }
    item[e.target.name] = entered_amount;
    // console.log(entered_amount);
    this.find_total(allowance_head);
    this.setState({allowance_head});
  }

   fromToChange(e,idx){
    var allowance_head = [...this.state.allowance_head];
    var item = allowance_head[idx];
    item[e.target.name] = e.target.value;
    item.kilometers = item.to - item.from;
    item.kilometers = item.kilometers>0?item.kilometers:0;
    item.rupees = parseInt(item.kilometers * item.value);
    // console.log(allowance_head);
    this.find_total(allowance_head);
    this.setState({allowance_head});
  }

  checkboxChange(e,idx){
    var allowance_head = [...this.state.allowance_head];
    var item = allowance_head[idx];
    if(item.checkbox_value){
      item.checkbox_value = 0;
      item.from = '';
      item.to = '';
      item.rupees = '';
      item.type ='';
      $("#" + idx).css("height","0px");
      if(item.name == "TRAVEL"){
        $("#" + idx).find(".from,.to,.rupees").prop("required",false);
      }else{
        $("#" + idx).find(".rupees").prop("required",false);
      }
    }else{
      item.checkbox_value =1;
      $("#" + idx).heightAuto();
      if(item.name == "TRAVEL"){
        $("#" + idx).find(".from,.to,.rupees").prop("required",true);
      }else{
        $("#" + idx).find(".rupees").prop("required",true);
      }
    }
    this.find_total(allowance_head);
    this.setState({allowance_head});

  }

  typeChange(option,idx){
    var allowance_head = [...this.state.allowance_head];
    var item = allowance_head[idx];
    item.type = option;
    item.value = option.amount;
    item.kilometers = item.to - item.from;
    item.kilometers = item.kilometers>0?item.kilometers:0;
    item.rupees = parseInt(item.kilometers * item.value);
    this.find_total(allowance_head);
    this.setState({allowance_head});
  }

  find_total(allowance_head){
    var total = 0;
    for(var i=0;i<allowance_head.length;i++){
      if(allowance_head[i]['rupees'])
        total = +parseFloat(total).toFixed(2) + +parseFloat(allowance_head[i]['rupees']).toFixed(2);
    }
    this.setState({
      total : parseFloat(total).toFixed(2)
    });
  }


  formSubmit(e){
    e.preventDefault();
    // console.log(this.state);
    // return;
    var allowance_details = this.state;
    allowance_details.ta_date = allowance_details.date.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
    console.log(this.state);
    // return;
    const url = `${process.env.REACT_APP_SSS}/api/saveAllowanceDetails`;
    this.context.loading(1);
    var obj_this = this;
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid,
        allowance_details : allowance_details
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      // return;
      if(return_data.status == "create"){
        alert("Allowance inserted.");
        obj_this.context.loading(0);
        obj_this.props.history.replace('/travel/allowance');
      }else if(return_data.status == "update"){
        alert("Allowance updated.");
        obj_this.context.loading(0);
        obj_this.props.history.replace('/travel/allowance');
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      alert("Network error");
    });
  }


  render(){
  return (
    <MyContext.Consumer>
      {context => (
        <div className="new_travel_allowance_container">
          <div className="page_header">
            <Link className="back_button" to="/travel/allowance"><i className="fas fa-arrow-left"></i></Link>
            <div className="page_title">{this.state.id?"Edit":"New"} Travel Allowance</div>
          </div> 
          <form className="travel_allowance_form"
            onSubmit={e => this.formSubmit(e)}
          >
            <div className="blocks date_container">
              <DatePicker
                className="input date"
                onChange={(value) => this.onDateChange(value)}
                value={this.state.date}
                maxDate={new Date()}
                format="dd-MM-y"
                required
              /> 
            </div>

            <div className="location_title">Location</div>
            <textarea className="input location_text custom_input" 
              name="location"
              value={this.state.location}
              onChange={(value) => this.locationChange(value)}
              autoComplete="off"
              required
            />
            <div className="input_grid">
              <div className="calls_title">Number of Calls</div>
              <div>
                <input className="input num_calls custom_input" 
                  type="number"
                  name="num_of_calls"
                  disabled
                  value={this.state.num_of_calls}
                  onChange={(value) => this.callChange(value)}
                  autoComplete="off"
                  required
                />
              </div>
            </div>

            {this.state.allowance_head.map((item,idx) => (

            <div className="blocks container" key={idx}>
              <div className="input_label">
                <input type="checkbox" 
                  name="checkbox_value"
                  checked={this.state.allowance_head[idx].checkbox_value}
                  onChange={e => this.checkboxChange(e,idx)}
                />
                <span className="block_title">{item.name}</span>
              </div>
              <div className="allowance_details" id={idx}>
                <div className="" style={{display:item.child.length?"block":"none"}}>
                <Select 
                  options={item.child}  
                  value ={this.state.allowance_head[idx].type}
                  className="type" 
                  ref={this.typeSelect}
                  onChange={e => this.typeChange(e,idx)}
                  /> 
                </div>
                <div className="input_grid" style={{display:item.name == "TRAVEL"?"grid":"none"}}>
                  <div>
                    <div className="input_label">From</div>
                    <input className="from input custom_input"
                      type="number"
                      name="from"
                      value={this.state.allowance_head[idx].from}
                      onChange={e => this.fromToChange(e,idx)}
                      autoComplete="off"
                    />
                  </div>
                  <div>
                    <div className="input_label">To</div>
                    <input className="to input custom_input"
                      type="number"
                      name="to"
                      value={this.state.allowance_head[idx].to}
                      onChange={e => this.fromToChange(e,idx)}
                      autoComplete="off"
                    />
                  </div>
                </div>

                  <div className="input_label">Rupees <span className="kilometers" style={{display:item.kilometers?"inline":"none"}}>({item.kilometers+" " +item.duration})</span></div>
                  <input className="rupees input custom_input"
                    type="number"
                    name="rupees"
                    value={this.state.allowance_head[idx].rupees}
                    onChange={e => this.formChange(e,idx)}
                    autoComplete="off"
                    disabled={item.name == "TRAVEL"}
                  />
                  <div className="maximum_price"><i className="fas fa-info-circle"></i> Maximum <i className="fas fa-rupee-sign"></i>{item.value}/{item.duration}</div>
              </div>
            </div>
              ))}
            <div type="button" className="blocks grand_total" style={{display:parseFloat(this.state.total)>0?"block":"none"}}>Grand Total : Rs. {this.state.total}</div>
            <button type="submit" className="submit_button" 
            style={{visibility:this.state.allowance_head.filter((item) => (item.checkbox_value == 1)).length?"visible":"hidden"}}>
            
            <i className="far fa-save"></i> Save</button>
          </form>           
        </div>
      )}
    </MyContext.Consumer>
  )}
}

export default NewTravelAllowance;
NewTravelAllowance.contextType = MyContext;
