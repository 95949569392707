import React , {Component} from "react";
import MyContext from '../MyContext.jsx';
import {Link} from "react-router-dom";
import { CgSearch } from 'react-icons/cg';
import Select from "react-select";
import DatePicker from 'react-date-picker';

import './SampleRequestReport.scss';

class SampleRequestReport extends Component{
  constructor(props){
    super(props);
    this.executiveSelect = React.createRef();
    const { begin, end } = this.calculateFinancialYear();

    this.state = {
      executives    :   [],
      executive :   {},
      products : [],
      showing_products:[],
      search:'',
      from_date : begin,
      to_date : end,
      grand_total : 0
    }
    this.executiveChange = this.executiveChange.bind(this);
    this.formChange = this.formChange.bind(this);
    this.formSubmit = this.formSubmit.bind(this);


  }
  calculateFinancialYear() {
    const today = new Date();
    const currentMonth = today.getMonth() + 1;
    const currentYear = today.getFullYear();
  
    let begin, end;
  
    if (currentMonth >= 4) {
      begin = new Date(currentYear, 3, 1); 
      end = today; 
    } else {
      begin = new Date(currentYear - 1, 3, 1); 
      end = today; 
    }
  
    return { begin, end };
  }
  componentDidMount(){
    var obj_this = this;
    const url = `${process.env.REACT_APP_SSS}/api/getOutstandingDistricts`;
    this.context.loading(1);
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      if(return_data.status == "success"){
        obj_this.setState({
         executives : return_data.executives,
        });
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
        obj_this.context.loading(0);
        alert("Network error");
    });

    obj_this.getSampleRequest();
    
  }

  formChange(e){
    // console.log(e.target.value);
    var showing_product =[];
    if(e.target.value){
      showing_product = this.state.showing_products.filter((item) => {return item.name.toLowerCase().includes(e.target.value.toLowerCase())});
    }
    else{
      showing_product = this.state.products;
    }
    // showing_products = showing_products.length?showing_products:this.state.doc_name;
    this.setState({
      showing_products : showing_product
    });
  }

  executiveChange(option){
    this.setState({
      executive : option
    });
  } 

  formSubmit(e){
    e.preventDefault();
    $(".customer_outstandings_table tr").css("backgroud-color","");
    // if(!this.state.executive.value){
    //   this.executiveSelect.current.focus();
    //   return;
    // }
    
    var obj_this = this;
    obj_this.getSampleRequest();
    
   
  
  }

  getSampleRequest(){
    const url2 = `${process.env.REACT_APP_SSS}/api/Reports/getSampleRequest`;
    this.context.loading(1);
    var obj_this = this;

    $.ajax({
      url : url2,
      data : {
        sid : this.context.user_details.sid,
        executive_id : this.state.executive.value,
        from_date : this.state.from_date.toLocaleDateString('en-CA'),
        to_date : this.state.to_date.toLocaleDateString('en-CA')
        // due_period_id : this.state.due_period.value
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      // products = products.sort(function(a,b){
      //   return a.no_due - b.no_due
      // });
      // return;
      
      if(return_data.status == "success"){
        obj_this.setState({
         products : return_data.products,
         showing_products:return_data.products,
         grand_total : return_data.grand_total
        });
        // debugger;
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      alert("Network error");
    });
  }

  fromDateChange(value){
    // console.log(value);
    this.setState({from_date : value});
  }
  toDateChange(value){
    // console.log(value);
    this.setState({to_date : value});
  }



  
    

  render(){
    return(
      <MyContext.Consumer>
        {context => (
        <div className="sample_request_page_container">
          <div className="page_header">
            <Link className="back_button" to="/dashboard"><i className="fas fa-arrow-left"></i></Link>
            <div className="page_title">Sample Request Report</div>
          </div> 
          <form className="executive_due_period_form" onSubmit={this.formSubmit}>
            <div className="blocks">
            <div className="input_grid">
                <div className="form_input">
                  <div className="from_to_label">
                    <div className="input_label">From Date</div>
                    <DatePicker
                      className="input custom_input"
                      onChange={(value) => this.fromDateChange(value)}
                      value={this.state.from_date}
                      // minDate = {new Date()}
                      clearIcon={null}
                      required
                      format="dd-MM-y"
                    />
                    <div className="input_label">To Date</div>
                    <DatePicker
                      className="input custom_input"
                      onChange={(value) => this.toDateChange(value)}
                      value={this.state.to_date}
                      minDate = {new Date(this.state.from_date)}
                      clearIcon={null}
                      required
                      format="dd-MM-y"
                    />
                  </div>
                  <div className="input_label">Executive</div>
                  <Select 
                    options={this.state.executives}  
                    value ={this.state.executive}
                    className="executive" 
                    ref={this.executiveSelect}
                    onChange={e => this.executiveChange(e)}
                    required
                  />
                  <div className="submit_button_container">
                    <button className="submit_button">Submit</button>
                  </div>
                </div>
                
              </div>
            </div>
          </form>
          <div className="search_container">
            <CgSearch/>
            <input className="customer_search_input" 
              placeholder="Search Products.."
              onChange={this.formChange}
              value={this.search}
            />
          </div>
          <div className="table_container">
            <table className="customer_outstandings_table">
              <thead>
                <tr>
                  <th style={{maxWidth:"40px"}} align="center">#</th>
                  <th>Products</th>
                  <th align="center">Qty</th>
                  <th align="center">Unit</th>
                  <th align="center">Amount</th>
                </tr>
              </thead>
              <tbody>
              <tr style={{display:this.state.showing_products.length?"none":"table-row"}}>
                <td colSpan="5" align="center">No result!</td>
              </tr>
              {this.state.showing_products && this.state.showing_products.map((cust,index) => (
                <tr key={index} >
                  <td valign="top" align="center">{index+1}</td>
                  <td valign="top" style={{padding:"5px 0px"}}>
                    <div style={{fontWeight:"500",paddingLeft:"5px"}}>{cust.name}</div>
                   
                  </td>
                  <td valign="top" style={{padding:"5px 5px", textAlign:"right"}}>
                    <div style={{fontWeight:"500",paddingLeft:"5px"}}>{cust.qty}</div>
                   
                  </td>
                  <td valign="top" style={{padding:"5px 5px", textAlign:"center"}}>
                    <div style={{fontWeight:"500",paddingLeft:"5px"}}>{cust.unit}</div>
                   
                  </td>
                  <td valign="top" style={{padding:"5px 5px", textAlign:"right"}}>
                    <div style={{fontWeight:"500",paddingLeft:"5px"}}>{cust.grand_total}</div>
                   
                  </td>
                </tr>
              ))}
               <tr>
                <td colSpan="4" align="center"><b>Total Amount</b></td>
                <td colSpan="1" align="center">{this.state.grand_total} <i className="fas fa-rupee-sign"></i></td>

              </tr>
              </tbody>
            </table>
          </div>
        </div>

      )}
    </MyContext.Consumer>
  )}
}

export default SampleRequestReport;
SampleRequestReport.contextType = MyContext;
