import React, { Component } from "react";
import {Link} from "react-router-dom";

import './Schedules.scss';
import MyContext from '../MyContext.jsx';
import DatePicker from 'react-date-picker';
import subDays from "date-fns/subDays";


class Schedules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      today_schedules : [],
      visited_schedules : [],
      not_visited_schedules : [],
      upcoming_schedules : [],
      schedule_id:'',
      deviation:'',
      deviation_remarks:'',
      scheduleDate:new Date(),
      isClicked:false
    }
    this.formChange = this.formChange.bind(this);
    this.deviationFormSubmit = this.deviationFormSubmit.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.datePickerRef = React.createRef();


  }

  componentDidMount(){  
    this.context.setLocation({
      latitude : '',
      longitude : ''
    });  
    var obj_this = this;
    const url = `${process.env.REACT_APP_SSS}/api/getExeSchedules`;
    this.context.loading(1);
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid
      },
      type : "POST"
    }).done(function(return_data){
      // console.log(return_data);
      if(return_data.status == "success"){
        const today_schedules = return_data.schedule_dealers.filter((item) => item.date == new Date().toLocaleDateString('en-CA'));
        const visited_schedules = today_schedules.filter((item) => item.check_inout == "OUT");
        const not_visited_schedules = today_schedules.filter((item) => item.check_inout != "OUT");

        // const upcoming_schedules = return_data.schedule_dealers.filter((item) => item.date > new Date().toLocaleDateString('en-CA'));
        obj_this.setState({
          today_schedules: today_schedules,
          // upcoming_schedules: upcoming_schedules,
          visited_schedules: visited_schedules,
          not_visited_schedules: not_visited_schedules,
        });
        obj_this.context.loading(0);
        // console.log(obj_this.state)
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      alert("Network error");
    });

    const url2 = `${process.env.REACT_APP_SSS}/api/getUpcomingSchedules`;
    this.context.loading(1);
    $.ajax({
      url : url2,
      data : {
        sid : this.context.user_details.sid
      },
      type : "POST"

    }).done(function(return_data){
      // console.log(return_data);
        if(return_data.status == "success"){
          const upcoming_schedules=return_data.upcoming_schedules
          obj_this.setState({
            upcoming_schedules: upcoming_schedules,
          });
        }
        else{ obj_this.context.loading(0);
          obj_this.props.history.replace("/");
        }
      }).fail(function(){
        alert("Network error");
      });

    // $(".schedules_page_container").on("click",".delete_schedule_button",function(){
    //   if(!confirm("Are you sure you want to delete")){
    //     return;
    //   }
    //   const schedule_id = $(this).attr("schedule_id");
    //   const url = `${process.env.REACT_APP_SSS}/api/deleteExeSchedule`;
    //   obj_this.context.loading(1);
    //   $.ajax({
    //     url : url,
    //     data : {
    //       sid : obj_this.context.user_details.sid,
    //       schedule_id : schedule_id
    //     },
    //     type : "POST"
    //   }).done(function(return_data){
    //       // console.log(return_data);
    //     if(return_data.status == "success"){
    //       const today_schedules = return_data.schedule_dealers.filter((item) => item.date == new Date().toLocaleDateString('en-CA'));
    //       const upcoming_schedules = return_data.schedule_dealers.filter((item) => item.date > new Date().toLocaleDateString('en-CA'));
    //       obj_this.setState({
    //         today_schedules: today_schedules,
    //         upcoming_schedules: upcoming_schedules,
    //       });
    //       alert("Schedule deleted")
    //       obj_this.context.loading(0);
    //     }else{
    //       obj_this.context.loading(0);
    //       obj_this.props.history.replace("/");
    //     }
    //   }).fail(function(){
    //     alert("Network error");
    //   });

    // });


    $(".list_blocks_container").on("click",".check_in_button",function(){
      if(confirm("Confirm check in")){
        const schedule_id = $(this).attr("schedule_id");
        obj_this.props.history.push('schedules/details/'+schedule_id);
      }
    });


    $(".list_blocks_container").on("click",".single_click_check_in",function(){
        const schedule_id = $(this).attr("schedule_id");
        const url = `${process.env.REACT_APP_SSS}/api/quickCheckIn`;
        $.ajax({
          url : url,
          data : {
            sid : obj_this.context.user_details.sid,
            schedule_id : schedule_id
          },
          type : "POST"
        }).done(function(return_data){
          if(return_data.status == "success"){
            const url = `${process.env.REACT_APP_SSS}/api/getExeSchedulePerDay`;
            obj_this.context.loading(1);
            $.ajax({
              url : url,
              data : {
                sid : obj_this.context.user_details.sid,
                date: obj_this.state.scheduleDate.toLocaleDateString('en-CA')
              },
              type : "POST"
            }).done(function(return_data){
              if(return_data.status == "success"){
                const today_schedules = return_data.schedule_dealers.filter((item) => item.date == new Date(obj_this.state.scheduleDate).toLocaleDateString('en-CA'));
                const visited_schedules = today_schedules.filter((item) => item.check_inout == "OUT");
                const not_visited_schedules = today_schedules.filter((item) => item.check_inout != "OUT");
        
                // const upcoming_schedules = return_data.schedule_dealers.filter((item) => item.date > new Date().toLocaleDateString('en-CA'));
                obj_this.setState({
                  today_schedules: today_schedules,
                  // upcoming_schedules: upcoming_schedules,
                  visited_schedules: visited_schedules,
                  not_visited_schedules: not_visited_schedules,
                });
                obj_this.context.loading(0);
                // console.log(obj_this.state)
              }else{
                obj_this.context.loading(0);
                obj_this.props.history.replace("/");
              }
            }).fail(function(){
              obj_this.context.loading(0);
              alert("Network error");
            });
            // window.location.reload(true);
            obj_this.props.history.replace('schedules');
          }else{
            obj_this.context.loading(0);
            obj_this.props.history.replace("/");
          }
        }).fail(function(){
          obj_this.context.loading(0);
          alert("Network error");
        });

    });
    $(".close_button").on("click",function(){
      $(".deviation_remarks_form_ghost").css("display","none");
      obj_this.setState({
        deviation:'',
        deviation_remarks : ""
      });
    });

    $(".schedules_page_container").on("click",".deviation_from_schedule",function(e){
      e.stopPropagation();
      if(confirm("Mark Deviation ?")){
        obj_this.setState({
          schedule_id : $(this).attr("schedule_id")
        });
        const schedule_id = $(this).attr("schedule_id");
        $(".deviation_remarks_form_ghost").css("display","block");
      }
    });  
    ui.swipeMenu(".schedules_container");
  }

  formChange(e){
    e.preventDefault();
    this.setState({
      [e.target.name] : e.target.value
    });
  }

  deviationFormSubmit(e){
    e.preventDefault()
    console.log(this.state);
    var obj_this=this;
    const url=`${process.env.REACT_APP_SSS}/api/submitDeviationRemarks`;
    this.context.loading(1);
    const devitation='Y';
    const deviation_remarks=obj_this.state.deviation_remarks;
    $(".deviation_remarks_form_ghost").css("display","none");
    obj_this.setState({
      schedule_id:'',
      deviation : "",
      deviation_remarks : ""
    });

    $.ajax({
      url : url,
      data : {
        sid : obj_this.context.user_details.sid,
        schedule_id : obj_this.state.schedule_id,
        devitation: devitation,
        deviation_remarks: deviation_remarks,
      },
      type : "POST"

    }).done(function(return_data){
      console.log(return_data);
        if(return_data.status == "success"){
          // obj_this.setState({
          //   deviation_remarks: deviation_remarks,
          // });
          alert("Deviation Submitted");
          const url2 = `${process.env.REACT_APP_SSS}/api/getUpcomingSchedulesPerDay`;
          obj_this.context.loading(1);
          $.ajax({
            url : url2,
            data : {
              sid : obj_this.context.user_details.sid,
              date  : obj_this.state.scheduleDate.toLocaleDateString('en-CA'),
            },
            type : "POST"

          }).done(function(return_data){
            // console.log(return_data);
              if(return_data.status == "success"){
                const upcoming_schedules=return_data.upcoming_schedules
                obj_this.setState({
                  upcoming_schedules: upcoming_schedules,
                });
              }
              else{ obj_this.context.loading(0);
                obj_this.props.history.replace("/");
              }
            }).fail(function(){
              alert("Network error");
            });
          obj_this.context.loading(0);

        }
        else{ 
          alert("Deviation not Submitted")
          obj_this.context.loading(0);
          obj_this.props.history.replace("/");
        }
      }).fail(function(){
        alert("Network error");
      });
  }
  handleChange(date) {
    this.setState({
      scheduleDate: date
    });
    var obj_this=this;
    const url = `${process.env.REACT_APP_SSS}/api/getExeSchedulePerDay`;
    this.context.loading(1);
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid,
        date: date.toLocaleDateString('en-CA')
      },
      type : "POST"
    }).done(function(return_data){
      // console.log(return_data);
      if(return_data.status == "success"){
        const today_schedules = return_data.schedule_dealers.filter((item) => item.date == new Date(date).toLocaleDateString('en-CA'));
        const visited_schedules = today_schedules.filter((item) => item.check_inout == "OUT");
        const not_visited_schedules = today_schedules.filter((item) => item.check_inout != "OUT");

        // const upcoming_schedules = return_data.schedule_dealers.filter((item) => item.date > new Date().toLocaleDateString('en-CA'));
        obj_this.setState({
          today_schedules: today_schedules,
          // upcoming_schedules: upcoming_schedules,
          visited_schedules: visited_schedules,
          not_visited_schedules: not_visited_schedules,
        });
        obj_this.context.loading(0);
        // console.log(obj_this.state)
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      obj_this.context.loading(0);
      alert("Network error");
    });

    const url2 = `${process.env.REACT_APP_SSS}/api/getUpcomingSchedulesPerDay`;
    this.context.loading(1);
    $.ajax({
      url : url2,
      data : {
        sid : this.context.user_details.sid,
        date  : date.toLocaleDateString('en-CA'),
      },
      type : "POST"

    }).done(function(return_data){
      // console.log(return_data);
        if(return_data.status == "success"){
          const upcoming_schedules=return_data.upcoming_schedules
          obj_this.setState({
            upcoming_schedules: upcoming_schedules,
          });
        }
        else{ obj_this.context.loading(0);
          obj_this.props.history.replace("/");
        }
      }).fail(function(){
        alert("Network error");
      });

  }



  render(){
  return (
    <MyContext.Consumer>
      {context => (
        <div className="schedules_page_container noselect">
          <div className="header_spacer"></div>
          <div className="schedules_container uijs swipemenu">
            <div className="page_header">
              <div className="page_title">Schedules</div>
              {/* <button  onClick={() => this.datePickerRef.current.openCalendar(this.datePickerRef)}>openDate</button> */}
              <DatePicker
                onChange={this.handleChange}
                value={this.state.scheduleDate}
                isClearable={false}
                ref={this.datePickerRef}
                maxDate={new Date()}
                minDate={this.context.user_details.designation=="FSM" || this.context.user_details.designation=="FSM 1"?subDays(new Date(), 2):subDays(new Date(), 7)}
                clearIcon={null} 
                format="dd-MM-y"
              />
              <Link className="new_schedule_button" to="/schedules/new"> 
                <i className="fa fa-plus"></i> New</Link>
            </div>
            <div className="toggles_container">
              <div className="list_1 toggles selected" menu="0">
                <i className="fa fa-calendar-alt order_status_icon"></i>
                <div className="order_status">{new Date(this.state.scheduleDate).toLocaleDateString('en-GB')}</div>
                <div className="order_count">{this.state.today_schedules.length}</div>
              </div>
              <div className="list_2 toggles" menu="1">
                <i className="fa fa-clipboard-check order_status_icon" style={{fontSize: "26px"}}></i>
                <div className="order_status">Visited</div>
                <div className="order_count">{this.state.visited_schedules.length}</div>
              </div>
              <div className="list_3 toggles" menu="2">
                <i className="fas fa-suitcase order_status_icon" style={{fontSize: "26px"}}></i>
                <div className="order_status">Not Visited</div>
                <div className="order_count">{this.state.not_visited_schedules.length}</div>
              </div>
              <div className="list_4 toggles" menu="3">
                <i className="fab fa-hive order_status_icon" style={{fontSize: "26px"}}></i>
                <div className="order_status">Upcoming</div>
                <div className="order_count">{this.state.upcoming_schedules.length}</div>
              </div>
            </div>

            <div className="list_blocks_container">
              <div className="lists_container">
                {this.state.today_schedules.map((item,idx)=>(
                  <div className="single_schedule_container" key={idx}>
                    <div className="scheduled_customer_name_delete_button_container">
                      <div className="scheduled_date_container">
                        <div className="scheduled_month">{new Date(item.date).toLocaleString('default', { month: 'short' })}</div>
                        <div className="scheduled_day">{new Date(item.date).getDate()}</div>
                      </div>
                      <div className="customer_name_check_in_button_container">
                        <div className="scheduled_customer_name"><i className={item.dealerName?'fas fa-warehouse':'fa fa-user-md'}></i> <span>{item.dealerName??item.inf_name}</span></div>
                        <div className="schedules_button_container"
                          style={{display : item.check_inout == "OUT"?"none":"grid"}}
                        >
                          <div className="check_in_button"
                            schedule_id = {item.id}
                          >Check in&nbsp;
                            <i className="fas fa-check"></i>
                          </div>
                          <a className="map_button"
                            style={{visibility:item.customer_latitude?"visible":"hidden"}}
                            href={"geo:0,0?q="+item.customer_latitude+","+item.customer_longitude+"('"+item.dealerName??item.inf_name+"')"}
                          >Map&nbsp;
                            <i className="fas fa-map-marker-alt"></i>
                          </a>
                          <div className="single_click_check_in" schedule_id = {item.id}>
                            <i className=" fa fa-copyright " aria-hidden="false" style={{fontSize:30, color:"#ffe300"}}></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                ))}
              </div>
              <div className="lists_container">
                {this.state.visited_schedules.map((item,idx)=>(
                  <div className="single_schedule_container" key={idx}>
                    <div className="scheduled_customer_name_delete_button_container">
                      <div className="scheduled_date_container">
                        <div className="scheduled_month">{new Date(item.date).toLocaleString('default', { month: 'short' })}</div>
                        <div className="scheduled_day">{new Date(item.date).getDate()}</div>
                      </div>
                      <div className="customer_name_check_in_button_container">
                        <div className="scheduled_customer_name"><i className={item.dealerName?'fas fa-warehouse':'fa fa-user-md'}></i><span>{item.dealerName??item.inf_name}</span></div>
                        <div className="schedules_button_container">
                          <Link className="edit_button"
                            schedule_id = {item.id}
                            to={"/schedules/details/edit/"+item.id}
                          >Edit&nbsp;
                            <i className="fa fa-pencil-alt" style={{fontSize: "14px"}}></i>
                          </Link>
                          <a className="map_button"
                            style={{visibility:item.customer_latitude?"visible":"hidden"}}
                            href={"geo:0,0?q="+item.customer_latitude+","+item.customer_longitude+"('"+item.dealerName??item.inf_name+"')"}
                          >Map&nbsp;
                            <i className="fas fa-map-marker-alt"></i>
                          </a>
                        </div>
                      </div>
                      <div></div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="lists_container">
                {this.state.not_visited_schedules.map((item,idx)=>(
                  <div className="single_schedule_container" key={idx}>
                    <div className="scheduled_customer_name_delete_button_container">
                      <div className="scheduled_date_container">
                        <div className="scheduled_month">{new Date(item.date).toLocaleString('default', { month: 'short' })}</div>
                        <div className="scheduled_day">{new Date(item.date).getDate()}</div>
                      </div>
                      <div className="customer_name_check_in_button_container">
                        <div className="scheduled_customer_name"><i className={item.dealerName?'fas fa-warehouse':'fa fa-user-md'}></i> <span>{item.dealerName??item.inf_name}</span></div>
                        <div className="schedules_button_container">
                          <div className="check_in_button"
                            schedule_id = {item.id}
                          >Check in&nbsp;
                            <i className="fas fa-check"></i>
                          </div>
                          <a className="map_button"
                            style={{visibility:item.customer_latitude?"visible":"hidden"}}
                            href={"geo:0,0?q="+item.customer_latitude+","+item.customer_longitude+"('"+item.dealerName??item.inf_name+"')"}
                          >Map&nbsp;
                            <i className="fas fa-map-marker-alt"></i>
                          </a>
                          <div className="single_click_check_in" schedule_id = {item.id}>
                            <i className=" fa fa-copyright " aria-hidden="false" style={{fontSize:30, color:"#ffe300"}}></i>
                          </div>
                        </div>
                      </div>
                      <div> </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="lists_container">
                {this.state.upcoming_schedules.map((item,idx)=>(
                   <div className="single_schedule_container" key={idx}>
                   <div className="scheduled_customer_name_delete_button_container">
                     <div className="scheduled_date_container">
                       <div className="scheduled_month">{new Date(item.date).toLocaleString('default', { month: 'short' })}</div>
                       <div className="scheduled_day">{new Date(item.date).getDate()}</div>
                     </div>
                     <div className="customer_name_check_in_button_container">
                        <div className="scheduled_customer_name"><i className={item.dealerName?'fas fa-warehouse':'fa fa-map-marker-alt'}></i> {item.dealerName?item.dealerName:item.location}</div>
                        <div className="status_of_schedules">
                          <div className="deviation_from_schedule" schedule_id={item.id} style={{display:item.status=="APR"&&item.deviation=="N"?"block":"none"}}>DEVIATION</div>
                          <div className="deviation_marked_schedule" schedule_id={item.id} style={{display:item.status=="APR"&&item.deviation=="Y"?"block":"none"}}>DEVIATION <i className="fa fa-check" aria-hidden="true"/></div>
                          <div className="schedule_requested" style={{display:item.status=="REQ"?"block":"none"}}><i className="fa fa-clock" aria-hidden="true"></i> REQUESTED</div>
                          <div className="schedule_approved" style={{display:item.status=="APR"?"block":"none"}}> <i className="fa fa-check" aria-hidden="true"></i>APPROVED</div>
                          <div className="schedule_rejected" style={{display:item.status=="CNL"||item.status=="APR-CNL"?"block":"none"}}> <i className="fa fa-times" aria-hidden="true"></i>REJECTED</div>
                        </div>
                      </div>
                      
                     <div></div>
                   </div>
                 </div>
                ))}
              </div>

              <div className="deviation_remarks_form_ghost">
                <form className="deviation_form" onSubmit={this.deviationFormSubmit} method="POST">
                  <i className="close_button fas fa-times"></i>
                  <div className="input_label">Remarks</div>
                  <textarea className="custom_input"
                    name="deviation_remarks"
                    value={this.state.deviation_remarks}
                    onChange={this.formChange}
                    required={this.state.deviation=='Y'}
                  />
                  <button className="confirm_deviation_button">MARK DEVIATION</button>
                </form>
              </div>

            </div>

          </div>
          <div className="bottom_navbar_spacer"></div>

        </div>
      )}
    </MyContext.Consumer>
  )}
}

export default Schedules;
Schedules.contextType = MyContext;
