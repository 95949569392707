import React, { Component } from "react";
// import { render } from "react-dom";
import {Link} from "react-router-dom";
import DatePicker from 'react-date-picker';
import Select from "react-select";

import './NewOrder.scss';
import MyContext from '../MyContext.jsx';
import {epochToDate} from '../../assets/js/handy.mjs';

class NewOrder extends Component {
  constructor(props) {
    super(props);
    var today = new Date();
    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    this.state = {
      samplerequest_id : '',
      order_date : today,
      remarks : '',
      products : [],
      rows: [{
        product : {},
        specifications : '',
        qty : '',
        unit_price : '',
        units : [],
        unit: {},
        total : '',
      }],
      total : ''
    }
    this.formChange = this.formChange.bind(this);
  }

  componentDidMount(){   
    $(".react-date-picker__inputGroup input").attr("readonly","true");
    const {pathname} = this.props.location;
    if(pathname === "/samplerequest/new") {
      const url = `${process.env.REACT_APP_SSS}/api/getCustomers`;
      this.context.loading(1);
      var obj_this = this;
      $.ajax({
        url : url,
        data : {
          sid : this.context.user_details.sid
        },
        type : "POST"
      }).done(function(return_data){
        console.log(return_data);
        if(return_data.status == "success"){
          // const products = return_data.products.filter((item) => item.units.length);
          obj_this.setState({
            products : return_data.products,
            rows : [{
              product : {},
              specifications : '',
              unit_price : '',
              qty : '',
              units : [],
              unit: {},
              total : '',
            }]
          },() => {
            $(".new_sample_requests_container").css("visibility","visible");
          });
          obj_this.context.loading(0);
        }else{
          obj_this.context.loading(0);
          obj_this.props.history.replace("/");
        }
      }).fail(function(){
        alert("Network error");
      });
    }else{
      const samplerequest_id = this.props.match.params.sid;
      const url = `${process.env.REACT_APP_SSS}/api/editSampleRequest`;
      this.context.loading(1);
      var obj_this = this;
      $.ajax({
        url : url,
        data : {
          sid : this.context.user_details.sid,
          samplerequest_id : samplerequest_id
        },
        type : "POST"
      }).done(function(return_data){
        console.log(return_data);
        // return;
        if(return_data.status == "success"){
          var state_object = return_data.state_object;
          state_object.order_date = epochToDate(state_object.order_date);
          state_object.products = state_object.products.filter((item) => item.units.length);

          obj_this.setState(state_object,() => {
            $(".minimize_button").trigger("click");
            $(".new_sample_requests_container").css("visibility","visible");
          });
          obj_this.context.loading(0);
        }else{
          obj_this.context.loading(0);
          obj_this.props.history.replace("/");
        }
      }).fail(function(){
        alert("Network error");
      });
    }

    var overflowTimeout = setTimeout(()=>{});
    $(".products_container").on("click",".maximize_button",function(){
      var elem = $(this).parents(".single_product_container");
      elem.find(".qty_unit_price_container").css("display","none");
      elem.heightAuto().find(".minimize_button").css("display","block");
      $(this).css("display","none");
      overflowTimeout = setTimeout(function(){
        elem.css("overflow","");
      },1000);
    });

    $(".products_container").on("click",".minimize_button",function(){
      var elem = $(this).parents(".single_product_container");
      elem.find(".qty_unit_price_container").css("display","grid");
      clearTimeout(overflowTimeout);
      var original_height = elem.height();
      elem.height(original_height);
      setTimeout(function(){
      elem.css({
        "height" : "46px",
        "overflow" : "hidden"
        }).find(".maximize_button").css("display","block");
      },1);
      $(this).css("display","none");
    });
  }


  formChange(e){
    this.setState({
      [e.target.name] : e.target.value
    });
  }
  onOrderDateChange(value){
    // console.log(value);
    this.setState({order_date : value});
  }


  productChange(option,idx){
    // console.log(option);
    $(".single_product_container").css('background-color','');
    const rows = [...this.state.rows];
    const row = {...rows[idx]}
    row.specifications = '';
    row.unit_price = '';
    row.qty = '';
    row.unit = {};
    row.product = {};
    var obj_this = this;
    obj_this.context.loading(1);
    const url = `${process.env.REACT_APP_SSS}/api/getProductUnits`;
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid,
        product_id : option.value,
      },
      type : "POST"
    }).done(function(return_data){
      if(return_data.status == "success"){
          console.log(return_data);
          row.units = return_data.units;
          rows[idx] = row;
          row.product = option;
        
        obj_this.setState({
          rows
        });
        obj_this.context.loading(0)
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      alert("Network error");
    });
  }

  unitChange(option,idx){
    // console.log(option);
    $(".single_product_container").css('background-color','');
    // if(!option.total){
    //   alert("Unit price is not added");
    //   return;
    // }
    const rows = [...this.state.rows];
    const row = {...rows[idx]};
    row.unit = option;
    row.unit_price = option.total;
    rows[idx] = row;
    this.setState({
      rows
    },() => {
      this.find_total(idx);
    });

  }

  find_total(idx){
    const rows = [...this.state.rows];
    const row = {...rows[idx]};
    const qty =row.qty;
    const unit_price = row.unit_price;
    if(!unit_price){
      return;
    }
    var total = qty*unit_price;
    row.total = parseFloat(total).toFixed(2);
    // console.log(total);
    rows[idx] = row;
    this.setState({
      rows
    });
    var total = 0;
    for(let i=0;i<rows.length;i++){
      if(rows[i]['total']){
        total += parseFloat(rows[i]['total']);
      }
    }
    this.setState({
      total : parseFloat(total).toFixed(2)
    });
  }

  handleChange = idx => e => {
    $(".single_product_container").css('background-color','');
    const { name, value } = e.target;
    const rows = [...this.state.rows];
    const row = {...rows[idx]};
    row[name] = value;
    rows[idx] = row;
    var obj_this = this;
    this.setState({
      rows
    },() =>{
      if(e.target.name == "qty" || e.target.name == "unit_price" ){
        obj_this.find_total(idx)
      }
    });    
  };

  handleAddRow = () => {
    $(".single_product_container").css('background-color','');
    if(!this.validateProducts(this.state.rows)){
      return;
    }
    // console.log(this.state.rows);
    $(".single_product_container:not(:last-of-type) .minimize_button").trigger("click");
    const item = {
      product : {},
      specifications: "",
      unit_price : '',
      qty: "",
      unit : {}
    };
    this.setState({
      rows: [...this.state.rows, item]
    });
  };

  handleRemoveSpecificRow = (idx) => () => {
    // console.log(idx);
    // return;
    $(".single_product_container").css('background-color','');
    const rows = [...this.state.rows];
    rows.splice(idx, 1);
    this.setState({ 
      rows 
    });
    var total = 0;
    for(let i=0;i<rows.length;i++){
      if(rows[i]['total']){
        total += parseFloat(rows[i]['total']);
      }
    }
    this.setState({
      total : parseFloat(total).toFixed(2)
    });

  }

  formSubmit(e){
    e.preventDefault();
    // console.log(this.state);
    // return;
    // if(!this.state.customer.value){
    //    this.refs.focus();
    //   return;
    // }
    const samplerequest = this.state;
    // delete samplerequest.locations;
    // delete samplerequest.customers;
    console.log(this.state);

    //products validation
    if(!this.validateProducts(samplerequest.rows)){
      return;
    }
    
    //product spec unique checking
    if(samplerequest.rows.length>1){
      var exist = 0;
      for(var i=0;i<samplerequest.rows.length;i++){
        var product = samplerequest.rows[i]['product']['value'];
        var specification = samplerequest.rows[i]['specifications']?samplerequest.rows[i]['specifications'].toUpperCase():"";
        for(var j=0;j<samplerequest.rows.length;j++){
          if(i != j){
            var spec = samplerequest.rows[j]['specifications']?samplerequest.rows[j]['specifications'].toUpperCase():"";
            if(samplerequest.rows[j]['product']['value'] == product && spec == specification){
              // $(".single_product_container").find(".minimize_button").trigger("click");
              $(".single_product_container[index="+i+"], .single_product_container[index="+j+"]").find(".maximize_button").trigger("click");
              $(".single_product_container[index="+i+"], .single_product_container[index="+j+"]").css('background-color','#ff000063');
              exist = 1;
              // break;
            }
          }
        }
      }
      if(exist){
        alert("Products with similar specification exists.");
        return false;
      }
    }
    samplerequest.o_date = samplerequest.order_date.toLocaleDateString('en-CA');
    samplerequest.products={}

    const url = `${process.env.REACT_APP_SSS}/api/saveSampleRequestOrder`;
    this.context.loading(1);
    var obj_this = this;
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid,
        samplerequest : samplerequest
      },
      type : "POST"
    }).done(function(return_data){
      // console.log(return_data);
      if(return_data.status == "insert"){
        alert("Sample Request Created");
      }else if(return_data.status == "update"){
        alert("Sample Request successfully updated");
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
      obj_this.props.history.replace('/samplerequests');
      obj_this.context.loading(0);
    }).fail(function(){
      alert("Network error");
    });
  }


  validateProducts(products){
    if(products.length == 1){
      if(!products[0].product.label){
        alert("Choose product");
        $(".single_product_container[index=0]").find(".maximize_button").trigger("click");
        $(".single_product_container[index=0]").find(".product").focus();
        return false;
      }
      if(!products[0].qty){
        alert("Quantity required");
        $(".single_product_container[index=0]").find(".maximize_button").trigger("click");
        $(".single_product_container[index=0]").find(".product_qty").focus();
        return false;
      }
      if(!products[0].unit_price){
        alert("Unit price required");
        $(".single_product_container[index=0]").find(".maximize_button").trigger("click");
        $(".single_product_container[index=0]").find(".unit_price").focus();
        return false;
      }
      if(!products[0].unit.label){
        alert("Choose unit");
        $(".single_product_container[index=0]").find(".maximize_button").trigger("click");
        $(".single_product_container[index=0]").find(".unit").focus();
        return false;
      }
    }else{
      for(var i=0;i<products.length;i++){
        if(products[i].product.label){
          if(!products[i].unit.label){
            alert("Choose unit");
            $(".single_product_container[index="+i+"]").find(".maximize_button").trigger("click");
            $(".single_product_container[index="+i+"]").find(".unit").focus();
            return false;
          }
          if(!products[i].qty){
            alert("Quantity required");
            $(".single_product_container[index="+i+"]").find(".maximize_button").trigger("click");
            $(".single_product_container[index="+i+"]").find(".product_qty").focus();
            return false;
          }
          if(!products[i].unit_price){
            alert("Unit price required");
            $(".single_product_container[index="+i+"]").find(".maximize_button").trigger("click");
            $(".single_product_container[index="+i+"]").find(".unit_price").focus();
            return false;
          }

        }
      }
    }
    return true;
  }

  render(){
  return (
    <MyContext.Consumer>
      {context => (
        <div className="new_sample_requests_container">
          <div className="page_header">
            <Link className="back_button" to="/samplerequests"><i className="fas fa-arrow-left"></i></Link>
            <div className="page_title">{this.state.samplerequest_id?"Edit":"New"} Sample Request</div>
          </div> 
          <form className="sales_order_form"
            onSubmit={e => this.formSubmit(e)}
          >
            <div className="customer_details_container blocks">
              <div className="input_label">Request Order Date</div>
              <DatePicker
                className="input custom_input"
                onChange={(value) => this.onOrderDateChange(value)}
                value={this.state.order_date}
                required
                format="dd-MM-y"
                minDate = {this.state.samplerequest_id?"":new Date()}
              />
              <div className="input_label">Remarks</div>
              <textarea className="input remarks custom_input" 
                name="remarks"
                value={this.state.remarks}
                onChange={this.formChange}
                autoComplete="off"
              />
            </div>
            <div className="products_container blocks">
              <div className="subtitle" 
                style={{display:"inline-block"}}
              >
                <i className="fa fa-shopping-cart"></i> 
                 Products
              </div>
              {this.state.rows.map((item, idx) => (
                <div className="single_product_container" key={idx} index={idx}>
                  <div className="product_label_and_delete_button">
                    <div className="maximize_button">
                      <i className="fa fa-plus"></i>
                    </div>
                    <div className="minimize_button">
                      <i className="fa fa-minus"></i>
                    </div>
                    <div className="product_label">
                        {this.state.rows[idx].product.label}
                    </div>
                    <i className="delete_product_button fa fa-trash-alt" 
                      type="button"
                      style={{visibility:this.state.rows.length >1?"visible":"hidden"}}
                      onClick={this.handleRemoveSpecificRow(idx)}
                    ></i>
                  </div>
                  <div className="qty_unit_price_container">
                    <span
                       style={{visibility:this.state.rows[idx].unit.label?"visible":"hidden"}}
                    >Qty : {this.state.rows[idx].qty} {this.state.rows[idx].unit.label}</span>
                    <span
                       style={{visibility:this.state.rows[idx].unit_price?"visible":"hidden"}}
                    >Unit Price : {this.state.rows[idx].unit_price}</span>
                    <div 
                      style={{visibility:this.state.rows[idx].total?"visible":"hidden"}}
                      className="product_total"
                    >Price: Rs.
                      {/*<span>{parseFloat(this.state.rows[idx].qty*this.state.rows[idx].unit_price).toFixed(2)}</span>*/}
                      <span>{this.state.rows[idx].total}</span>
                    </div>
                  </div>
                  <div className="input_label">Product</div>
                  <Select 
                    options={this.state.products}  
                    value ={this.state.rows[idx].product}
                    className="product" 
                    onChange={e => this.productChange(e,idx)}
                    required
                  />

                  <div className="input_grid">
                    {/* <div>
                      <div className="input_label">Specifications</div>
                      <input className="input specifications custom_input" 
                        type="text"
                        name="specifications"
                        value={this.state.rows[idx].specifications}
                        onChange={this.handleChange(idx)}
                        autoComplete="off"
                      />
                    </div> */}
                    <div>
                      <div className="input_label">Unit</div>
                      <Select 
                        options={this.state.rows[idx].units} 
                        value ={this.state.rows[idx].unit}
                        placeholder="" 
                        className="unit" 
                        onChange={e => this.unitChange(e,idx)}
                        required
                      />
                    </div>
                  </div> 
                  
                  <div className="input_grid">
                    <div>
                      <div className="input_label">Quantity</div>
                      <input className="product_qty custom_input"
                        type="number"
                        name="qty"
                        step="0.001"
                        min="0"
                        value={this.state.rows[idx].qty}
                        onChange={this.handleChange(idx)}
                        autoComplete="off"
                      />
                    </div>
                    <div>
                    <div className="input_label">Unit Price</div>
                      <input className="unit_price input custom_input"
                        name="unit_price"
                        value={this.state.rows[idx].unit_price}
                        onChange={this.handleChange(idx)}
                        min="0"
                        step="0.01"
                        autoComplete="off"
                        disabled
                      />
                    </div>
                 </div>
              </div>
              ))}
              <div className="price_and_add_button_container">
                <div className="total"
                  style={{visibility:this.state.total?"visible":"hidden"}}
                >Total ({this.state.rows.length}) Rs.
                  <b> {this.state.total}</b>
                  </div>
                <button className="add_product_button"
                  type="button"
                  style={{visibility:this.state.rows[this.state.rows.length-1].product.label && this.state.rows[this.state.rows.length-1].unit.label?"visible":"hidden"}}
                  onClick={this.handleAddRow}
                >
                  <i className="fa fa-plus"></i> Product
                </button>
              </div>
              {/* <div className="price_and_add_button_container"> 
                <div className="total"
                    style={{visibility:this.state.total?"visible":"hidden"}}
                  >Grand Total Rs.
                    <b> {this.state.total}</b>
                </div>
              </div> */}
            </div>
            <button type="submit" className="submit_button"><i className="far fa-save"></i> Save</button>
          </form>           
        </div>
      )}
    </MyContext.Consumer>
  )}
}

export default NewOrder;
NewOrder.contextType = MyContext;
