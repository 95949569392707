import {Component} from "react";
import './TravelAllowance.scss';
import {Link} from "react-router-dom";
import MyContext from '../MyContext.jsx';

class TravelAllowance extends Component{
  constructor(props){
    super(props);
    this.state = {
      allowances : [],
    }
  }
  componentDidMount(){
    this.context.loading(1);
    var obj_this = this;
    $.ajax({
      url : process.env.REACT_APP_SSS + "/api/getTravelAllowances",
      data : {
        sid : this.context.user_details.sid
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      if (return_data.status == "success"){
        obj_this.setState({allowances : return_data.travel_allowances});
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.push("/");
      }
    }).fail(function(){
      alert("Network error");
    });
  }

  render(){
    return(
      <div className="travel_allowance_page_container">
         <div className="header_spacer"></div>
          <div className="travel_allowances_container">
            <div className="page_header">
              <div className="page_title">Travel allowances</div>
              <Link className="new_travel_allowance_button" to="/travel/allowance/new"> 
                <i className="fa fa-plus"></i> New</Link>
            </div>
            <div className="current_month">{new Date().toLocaleString('default', { month: 'long' })} { new Date().getFullYear()}</div>

            <div className="list_blocks_container">
              <div className="lists_container">
                {this.state.allowances.map((item,idx)=>(
                  <div className="single_collection_container" key={idx}>
                    <div className="collection_customer_name_delete_button_container">
                      <div className="collection_date_container">
                        <div className="collection_no">{idx+1}</div>
                      </div>
                      <div>
                        <div className="collection_customer_name"><i className="fa fa-calendar-alt"></i>{new Date(item.ta_date).toLocaleDateString('en-GB')}</div>
                        <div className="collection_details_status_button_container">
                          <div>
                            <div className="col_date">{new Date(item.req_date).toLocaleDateString('en-GB')}</div>
                            <div className="amount">INR {item.tot_cost}</div>
                          </div>
                          <div className="collection_status" style={{display:item.ta_status != "DRAFT"?"block":"none"}}>{item.ta_status}</div>
                          <Link className="edit_button"
                            to={"/travel/allowance/edit/"+item.ta_id}
                            style={{display:item.ta_status == "DRAFT"?"block":"none"}}
                          >Edit&nbsp;
                            <i className="fa fa-pencil-alt" style={{fontSize: "14px"}}></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                ))}
              </div>
            </div>

          </div>
          <div className="bottom_navbar_spacer"></div>

      </div>
    )
  }
}

export default TravelAllowance;
TravelAllowance.contextType = MyContext;