import React, { Component } from "react";
import {Link} from "react-router-dom";

import './JointVisits.scss';
import MyContext from '../MyContext.jsx';
import DatePicker from 'react-date-picker';
import Select from 'react-select';
import subDays from "date-fns/subDays";


class JointVisits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      today_schedules : [],
      visited_schedules : [],
      not_visited_schedules : [],
      upcoming_schedules : [],
      schedule_id:'',
      scheduleDate:new Date(),
      executives  : [],
      executive : '',
    }
    this.formChange = this.formChange.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.datePickerRef = React.createRef();


  }

  componentDidMount(){  
     
    var obj_this = this;
    const url = `${process.env.REACT_APP_SSS}/api/jointVisit/getExeSchedules`;
    obj_this.context.loading(1);
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid,
        date: new Date(this.state.scheduleDate).toLocaleDateString('en-CA')
      },
      type : "POST"
    }).done(function(return_data){
      // console.log(return_data);
      if(return_data.status == "success"){
        const today_schedules = return_data.schedule_dealers.filter((item) => item.date == new Date(obj_this.state.scheduleDate).toLocaleDateString('en-CA'));
        const visited_schedules = today_schedules.filter((item) => item.joint_checkin == 'CLO'||item.joint_checkin=='APR');
        const not_visited_schedules = today_schedules.filter((item) => item.joint_checkin == null );
        
        obj_this.setState({
          today_schedules: today_schedules,
          visited_schedules : visited_schedules,
          not_visited_schedules : not_visited_schedules
        
        });

        
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      alert("Network error");
    });

    const url2 = `${process.env.REACT_APP_SSS}/api/jointVisit/getUpcomingSchedules`;
    obj_this.context.loading(1);
    $.ajax({
      url : url2,
      data : {
        sid : this.context.user_details.sid,
        date: new Date(this.state.scheduleDate).toLocaleDateString('en-CA')
      },
      type : "POST"

    }).done(function(return_data){
      // console.log(return_data);
        if(return_data.status == "success"){
          const upcoming_schedules=return_data.upcoming_schedules
          obj_this.setState({
            upcoming_schedules: upcoming_schedules,
          });
        }
        else{ obj_this.context.loading(0);
          obj_this.props.history.replace("/");
        }
      }).fail(function(){
        alert("Network error");
        obj_this.context.loading(0);
      });

      var obj_this = this;
      const url3 = `${process.env.REACT_APP_SSS}/api/Reports/getExecutives`;
      obj_this.context.loading(1);
      $.ajax({
        url : url3,
        data : {
          sid : this.context.user_details.sid
        },
        type : "POST"
      }).done(function(return_data){
        console.log(return_data);
        if(return_data.status == "success"){
          obj_this.setState({
           executives : return_data.executives,
          });
          
        }else{
          obj_this.context.loading(0);
          obj_this.props.history.replace("/");
        }
      }).fail(function(){
          obj_this.context.loading(0);
          alert("Network error");
      });
      obj_this.context.loading(0);


    $(".list_blocks_container").on("click",".check_in_button",function(){
      if(confirm("Confirm check in")){
        const schedule_id = $(this).attr("schedule_id");
        obj_this.props.history.push('jointvisit/details/'+schedule_id);
      }
    });


    $(".list_blocks_container").on("click",".single_click_check_in",function(){
        obj_this.context.loading(1);
        const schedule_id = $(this).attr("schedule_id");
        const url = `${process.env.REACT_APP_SSS}/api/jointVisit/quickCheckIn`;
        
        $.ajax({
          url : url,
          data : {
            sid : obj_this.context.user_details.sid,
            schedule_id : schedule_id
          },
          type : "POST"
        }).done(function(return_data){
          if(return_data.status == "success"){
            obj_this.afterDateExeChange();
            // obj_this.context.loading(0);
            // window.location.reload(true);
            obj_this.props.history.replace('jointvisit');
          }else if(return_data.status == "duplicate"){
            alert("Already Checked In");
            obj_this.context.loading(0);
          }else{
            obj_this.context.loading(0);
            obj_this.props.history.replace("/");
          }
        }).fail(function(){
          obj_this.context.loading(0);
          alert("Network error");
        });

    });
   
    ui.swipeMenu(".schedules_container");
  }

  formChange(e){
    e.preventDefault();
    this.setState({
      [e.target.name] : e.target.value
    });
  }


  handleChange(date) {
    this.setState({
      scheduleDate: date
    }, () => {
      this.afterDateExeChange();
  });
  }
  executiveChange(option){
    this.setState({
      executive:option
    },()=>{
      this.afterDateExeChange();
    })
  }
  
  afterDateExeChange(){
    var obj_this = this;
    const url = `${process.env.REACT_APP_SSS}/api/jointVisit/getExeSchedules`;
    obj_this.context.loading(1);
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid,
        date: new Date(this.state.scheduleDate).toLocaleDateString('en-CA')??null,
        executive_id  : this.state.executive.value??null
      },
      type : "POST"
    }).done(function(return_data){
      // console.log(return_data);
      if(return_data.status == "success"){
        const today_schedules = return_data.schedule_dealers.filter((item) => item.date == new Date(obj_this.state.scheduleDate).toLocaleDateString('en-CA'));
        const visited_schedules = today_schedules.filter((item) => item.joint_checkin == 'CLO'||item.joint_checkin=='APR');
        const not_visited_schedules = today_schedules.filter((item) => item.joint_checkin == null );
        
        obj_this.setState({
          today_schedules: today_schedules,
          visited_schedules : visited_schedules,
          not_visited_schedules : not_visited_schedules
        
        });
     
       
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      obj_this.context.loading(0);
      alert("Network error");
    });

    const url2 = `${process.env.REACT_APP_SSS}/api/jointVisit/getUpcomingSchedules`;
    obj_this.context.loading(1);
    $.ajax({
      url : url2,
      data : {
        sid : this.context.user_details.sid,
        date: new Date(this.state.scheduleDate).toLocaleDateString('en-CA'),
        executive_id  : this.state.executive.value
      },
      type : "POST"

    }).done(function(return_data){
      // console.log(return_data);
        if(return_data.status == "success"){
          const upcoming_schedules=return_data.upcoming_schedules
          obj_this.setState({
            upcoming_schedules: upcoming_schedules,
          });
        }
        else{ obj_this.context.loading(0);
          obj_this.props.history.replace("/");
        }
      }).fail(function(){
        alert("Network error");
      });
      obj_this.context.loading(0);
    
  }



  render(){
  return (
    <MyContext.Consumer>
      {context => (
        <div className="joint_visit_page_container noselect">
          <div className="header_spacer"></div>
          <div className="schedules_container uijs swipemenu">
            <div className="page_header">
              <div className="page_title">Joint Visit </div>
              {/* <button  onClick={() => this.datePickerRef.current.openCalendar(this.datePickerRef)}>openDate</button> */}
              <Select 
              options = {this.state.executives}
              value = {this.state.executive}
              onChange={value=>this.executiveChange(value)}
              />
              <DatePicker
                onChange={this.handleChange}
                value={this.state.scheduleDate}
                isClearable={false}
                ref={this.datePickerRef}
                clearIcon={null} 
                maxDate={new Date()}
                minDate={this.context.user_details.designation=="FSM" || this.context.user_details.designation=="FSM 1"?subDays(new Date(), 2):subDays(new Date(), 7)}
                format="dd-MM-y"
              />
              {/* <Link className="new_schedule_button" to="/schedules/new"> 
                <i className="fa fa-plus"></i> New</Link> */}
            </div>
            <div className="toggles_container">
              <div className="list_1 toggles selected" menu="0">
                <i className="fa fa-calendar-alt order_status_icon"></i>
                <div className="order_status">{new Date(this.state.scheduleDate).toLocaleDateString('en-GB')}</div>
                <div className="order_count">{this.state.today_schedules.length}</div>
              </div>
              <div className="list_2 toggles" menu="1">
                <i className="fa fa-clipboard-check order_status_icon" style={{fontSize: "26px"}}></i>
                <div className="order_status">Visited</div>
                <div className="order_count">{this.state.visited_schedules.length}</div>
              </div>
              <div className="list_3 toggles" menu="2">
                <i className="fas fa-suitcase order_status_icon" style={{fontSize: "26px"}}></i>
                <div className="order_status">Not Visited</div>
                <div className="order_count">{this.state.not_visited_schedules.length}</div>
              </div>
              <div className="list_4 toggles" menu="3">
                <i className="fab fa-hive order_status_icon" style={{fontSize: "26px"}}></i>
                <div className="order_status">Upcoming</div>
                <div className="order_count">{this.state.upcoming_schedules.length}</div>
              </div>
            </div>

            <div className="list_blocks_container">
              <div className="lists_container">
                {this.state.today_schedules.map((item,idx)=>(
                  <div className="single_schedule_container" key={idx}>
                    <div className="scheduled_customer_name_delete_button_container">
                      <div className="scheduled_date_container">
                        <div className="scheduled_month">{new Date(item.date).toLocaleString('default', { month: 'short' })}</div>
                        <div className="scheduled_day">{new Date(item.date).getDate()}</div>
                      </div>
                      <div className="customer_name_check_in_button_container">
                        <div className="scheduled_customer_name"><i className={item.dealerName?'fas fa-warehouse':'fa fa-user-md'}></i> <span>{item.dealerName??item.inf_name}</span></div>
                        <div className="schedules_button_container"
                          style={{display : item.joint_checkin=="CLO"||item.joint_checkin=="APR"?"none":"grid"}}
                        >
                          <div className="check_in_button"
                            schedule_id = {item.id}
                          >Check in&nbsp;
                            <i className="fas fa-check"></i>
                          </div>
                          <a className="map_button"
                            style={{visibility:item.customer_latitude?"visible":"hidden"}}
                            href={"geo:0,0?q="+item.customer_latitude+","+item.customer_longitude+"('"+item.dealerName??item.inf_name+"')"}
                          >Map&nbsp;
                            <i className="fas fa-map-marker-alt"></i>
                          </a>
                          <div className="single_click_check_in" schedule_id = {item.id}>
                            <i className=" fa fa-copyright " aria-hidden="false" style={{fontSize:30, color:"#ffe300"}}></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                ))}
              </div>
              <div className="lists_container">
                {this.state.visited_schedules.map((item,idx)=>(
                  <div className="single_schedule_container" key={idx}>
                    <div className="scheduled_customer_name_delete_button_container">
                      <div className="scheduled_date_container">
                        <div className="scheduled_month">{new Date(item.date).toLocaleString('default', { month: 'short' })}</div>
                        <div className="scheduled_day">{new Date(item.date).getDate()}</div>
                      </div>
                      <div className="customer_name_check_in_button_container">
                        <div className="scheduled_customer_name"><i className={item.dealerName?'fas fa-warehouse':'fa fa-user-md'}></i><span>{item.dealerName??item.inf_name}</span></div>
                        <div className="schedules_button_container">
                          <Link className="edit_button"
                            schedule_id = {item.id}
                            to={"/jointvisit/details/edit/"+item.id}
                          >Edit&nbsp;
                            <i className="fa fa-pencil-alt" style={{fontSize: "14px"}}></i>
                          </Link>
                         
                        </div>
                      </div>
                      <div></div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="lists_container">
                {this.state.not_visited_schedules.map((item,idx)=>(
                  <div className="single_schedule_container" key={idx}>
                    <div className="scheduled_customer_name_delete_button_container">
                      <div className="scheduled_date_container">
                        <div className="scheduled_month">{new Date(item.date).toLocaleString('default', { month: 'short' })}</div>
                        <div className="scheduled_day">{new Date(item.date).getDate()}</div>
                      </div>
                      <div className="customer_name_check_in_button_container">
                        <div className="scheduled_customer_name"><i className={item.dealerName?'fas fa-warehouse':'fa fa-user-md'}></i> <span>{item.dealerName??item.inf_name}</span></div>
                        <div className="schedules_button_container">
                          <div className="check_in_button"
                            schedule_id = {item.id}
                          >Check in&nbsp;
                            <i className="fas fa-check"></i>
                          </div>
                          <a className="map_button"
                            style={{visibility:item.customer_latitude?"visible":"hidden"}}
                            href={"geo:0,0?q="+item.customer_latitude+","+item.customer_longitude+"('"+item.dealerName??item.inf_name+"')"}
                          >Map&nbsp;
                            <i className="fas fa-map-marker-alt"></i>
                          </a>
                          <div className="single_click_check_in" schedule_id = {item.id}>
                            <i className=" fa fa-copyright " aria-hidden="false" style={{fontSize:30, color:"#ffe300"}}></i>
                          </div>
                        </div>
                      </div>
                      <div> </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="lists_container">
                {this.state.upcoming_schedules.map((item,idx)=>(
                   <div className="single_schedule_container" key={idx}>
                   <div className="scheduled_customer_name_delete_button_container">
                     <div className="scheduled_date_container">
                       <div className="scheduled_month">{new Date(item.date).toLocaleString('default', { month: 'short' })}</div>
                       <div className="scheduled_day">{new Date(item.date).getDate()}</div>
                     </div>
                     <div className="customer_name_check_in_button_container">
                        <div className="scheduled_customer_name"><i className={item.dealerName?'fas fa-warehouse':'fa fa-map-marker-alt'}></i> {item.dealerName?item.dealerName:item.location}</div>
                        <div className="status_of_schedules">
                          <div className="deviation_from_schedule" schedule_id={item.id} style={{display:item.status=="APR"&&item.deviation=="N"?"none":"none"}}>NO DEVIATION</div>
                          <div className="deviation_marked_schedule" schedule_id={item.id} style={{display:item.status=="APR"&&item.deviation=="Y"?"block":"none"}}>DEVIATION <i className="fa fa-check" aria-hidden="true"/></div>
                          <div className="schedule_requested" style={{display:item.status=="REQ"?"block":"none"}}><i className="fa fa-clock" aria-hidden="true"></i> REQUESTED</div>
                          <div className="schedule_approved" style={{display:item.status=="APR"?"block":"none"}}> <i className="fa fa-check" aria-hidden="true"></i>APPROVED</div>
                          <div className="schedule_rejected" style={{display:item.status=="CNL"||item.status=="APR-CNL"?"block":"none"}}> <i className="fa fa-times" aria-hidden="true"></i>REJECTED</div>
                        </div>
                      </div>
                      
                     <div></div>
                   </div>
                 </div>
                ))}
              </div>


            </div>

          </div>
          <div className="bottom_navbar_spacer"></div>

        </div>
      )}
    </MyContext.Consumer>
  )}
}

export default JointVisits;
JointVisits.contextType = MyContext;
