import React , {Component} from "react";
import MyContext from '../MyContext.jsx';
import {Link} from "react-router-dom";
import { CgSearch } from 'react-icons/cg';
import Select from "react-select";
import InfiniteScroll from 'react-infinite-scroll-component';


import './Outstanding.scss';
import Loader from "../Loading/Loader.jsx";

class Outstanding extends Component{
  constructor(props){
    super(props);
    // this.districtSelect = React.createRef();
    this.exeSelect = React.createRef();
    this.state = {
      districts  : [],
      district : "",
      cost_centers : [],
      showing_cost_centers : [],
      executive:'',
      executives:[],
      permission:false
    }
    this.districtChange = this.districtChange.bind(this);
    this.formChange = this.formChange.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
    this.ageingReportTotal = this.ageingReportTotal.bind(this);
    this.getDesignations = this.getDesignations.bind(this);
  }
  componentDidMount(){
    var obj_this = this;
    const url = `${process.env.REACT_APP_SSS}/api/getOutstandingDistricts`;
    this.context.loading(1);
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid
      },
      type : "POST"
    }).done(function(return_data){
      // console.log(return_data);
      if(return_data.status == "success"){
        obj_this.setState({
         districts: return_data.districts,
        //  executives :  return_data.executives

        });
        obj_this.getDesignations();
        // obj_this.context.loading(0);
      }else{
        // obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      // obj_this.context.loading(0);
      alert("Network error");
    });
    this.ageingReportTotal();
    
    //invoice search permission from web(62-INVOICE FILTER IN OUTSTANDING)
    var permissions= this.context.user_details.permissions;
    // console.log(permissions);
    var permission=permissions.includes(62); 
    // console.log(permission);
    obj_this.setState({
      permission: permission,
     });

     $(".customer_outstanding_page_container").on("click",".single_so_container",function(){
      if($(this).outerHeight() == "60"){
        $(this).heightAuto();
        $(this).find(".arrow_down").css("transform","rotate(180deg)");
      }else{
        $(this).css("height","60px");
        $(this).find(".arrow_down").css("transform","rotate(0deg)");
      }
    });
    ui.swipeMenu(".customer_outstanding_page_container");


  }

  ageingReportTotal(){
    var obj_this = this;
    const url2 = `${process.env.REACT_APP_SSS}/api/ageingReportTotal`;
    this.context.loading(1);
    $.ajax({
      url : url2,
      data : {
        sid : this.context.user_details.sid,
        district_id : this.state.district?.value,
        exe_staff_id  : this.state.executive?.value
      },
      type : "POST"
    }).done(function(return_data){
      if(return_data.status == "success"){
        var cost_centers = return_data.cost_centers.filter((item) => ( item.dealers.length));
        cost_centers = cost_centers.map((costCenter) => ({
          ...costCenter,
          dealers: costCenter.dealers.filter((customer) => customer.bills.length > 0),
        }));
      
        obj_this.setState({
         cost_centers : cost_centers,
         showing_cost_centers : cost_centers,
         outstanding  : return_data.outstanding,
         due_exceed : return_data.due_exceed,
         showing_outstanding  : return_data.outstanding,
         showing_due_exceed : return_data.due_exceed
        });
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      obj_this.context.loading(0);
      alert("Network error");
    });
  }
  getDesignations(){
    var obj_this = this;
    const url = `${process.env.REACT_APP_SSS}/api/getDesignations`;
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      if(return_data.status == "success"){
        obj_this.setState({
         designations : return_data.designations,
        });
      }else{
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
        alert("Network error");
    });
  }

  districtChange(option){
    this.setState({
      district : option
    });
  }
  executiveChange(option){
    this.setState({
      executive : option
    });
  }
  designationChange(option){
    this.setState({
      designation : option,
      executive : ''
    },()=>{
      this.getExecutives();
    });
  }
  getExecutives(){
    var obj_this = this;
    if(!obj_this.state.designation?.label)
      return;

    const url = `${process.env.REACT_APP_SSS}/api/getDesignationWiseExecutives`;
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid,
        designation_id : obj_this.state.designation.value
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      if(return_data.status == "success"){
        obj_this.setState({
         executives : return_data.executives,
        });
      }else{
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
        alert("Network error");
    });
  }

  formChange(e){
    var showing_cost_centers =[];
    let grandSumOfPendingTotal = this.state.outstanding;
    let grandSumOfCostCenterOverdue = this.state.due_exceed;
    if(e.target.name =="customer"){
      if(e.target.value){
        showing_cost_centers = this.state.cost_centers.map((costCenter) => ({
          ...costCenter,
          dealers: costCenter.dealers.filter((customer) =>
            customer.name.toLowerCase().includes(e.target.value.toLowerCase())
          ),
        }));
      }
      else{
        showing_cost_centers = this.state.cost_centers;
      }
    }else if(e.target.name =="invoice"){
      var bills = [];
      if (e.target.value) {
        grandSumOfPendingTotal = 0;
        grandSumOfCostCenterOverdue = 0;

        showing_cost_centers = this.state.cost_centers.map((costCenter) => {
          let sumOfPendingTotal = 0;
          let sumOfCostCenterOverdue = 0;

          const dealersWithFilteredBills = costCenter.dealers
            .map((customer) => {
              const filteredBills = customer.bills.filter((bill) =>
                bill.bill_no.toLowerCase().includes(e.target.value.toLowerCase())
              );
              const pendingTotal = filteredBills.reduce(
                (total, bill) => total + parseFloat(bill.receivable),
                0
              );
              sumOfPendingTotal += pendingTotal;
              grandSumOfPendingTotal += pendingTotal;

              const exceedDueTotal = filteredBills.reduce(
                (total, bill) => total + parseFloat(bill.exceed),0
              );
              sumOfCostCenterOverdue += exceedDueTotal;
              grandSumOfCostCenterOverdue += exceedDueTotal;

              return {
                ...customer,
                bills: filteredBills,
                pending_total: parseFloat(pendingTotal).toFixed(0),
                exceed_due_total: parseFloat(exceedDueTotal).toFixed(0),
              };
            })
            .filter((customer) => customer.bills.length > 0);
          return {
            ...costCenter,
            dealers: dealersWithFilteredBills,
            sum_of_cost_center_outstanding: parseFloat(sumOfPendingTotal).toFixed(0),
            sum_of_cost_center_overdue: parseFloat(sumOfCostCenterOverdue).toFixed(0),
          };
        }).filter((costCenter) => costCenter.dealers.length > 0);
      }else if(e.target.value==''){
        // this.ageingReportTotal();
        showing_cost_centers = this.state.cost_centers;
      }
   
    }
    this.setState({
      showing_cost_centers : showing_cost_centers,
      showing_outstanding : parseFloat(grandSumOfPendingTotal).toFixed(0),
      showing_due_exceed : parseFloat(grandSumOfCostCenterOverdue).toFixed(0)
    });
  }

  formSubmit(e){
    e.preventDefault();
    $(".customer_outstandings_table tr").css("backgroud-color","");
    if(this.state.designation?.value && !this.state.executive?.value){
      this.exeSelect.current.focus();
      return
    }
    this.ageingReportTotal();
  }

  render(){
   const rupee =  '₹';
    return(
      <MyContext.Consumer>
        {context => (
        <div className="customer_outstanding_page_container uijs swipemenu">
          <div className="page_header">
            <Link className="back_button" to="/dashboard"><i className="fas fa-arrow-left"></i></Link>
            <div className="page_title">Customer Outstandings</div>
          </div> 
         
          <form className="executive_district_form" onSubmit={this.formSubmit} 
          style={{display:this.context.user_details.designation=='ASM'?"block":this.context.user_details.designation=='RSM'?"block":this.context.user_details.designation=='ADMIN'?"block":"none"}}
          >
            <div className="blocks">
              <div className="input_grid">
                <div>
                  <div className="input_label">District</div>
                  <Select 
                    options={this.state.districts}  
                    value ={this.state.district}
                    className="district" 
                    // ref={this.districtSelect}
                    onChange={e => this.districtChange(e)}
                    isClearable={true}
                    required
                  />
                  <div className="input_label">Designation</div>
                  <Select 
                    options={this.state.designations}  
                    value ={this.state.designation}
                    className="executive" 
                    // ref={this.exeSelect}
                    onChange={e => this.designationChange(e)}
                    isClearable={true}
                    required
                  />
                  <div className="input_label">Executive</div>
                  <Select 
                    options={this.state.executives}  
                    value ={this.state.executive}
                    className="executive" 
                    ref={this.exeSelect}  
                    onChange={e => this.executiveChange(e)}
                    required
                  />
                </div>
                <div className="submit_button_container">
                  <button className="submit_button">Submit</button>
                </div>
                </div>
                
            </div>
          </form>

          <div className="toggles_container">
            <div className="list_3 toggles" menu="0">
              <i className="fa fa-credit-card order_status_icon"></i>
              <div className="order_status">Cost Center O/S</div>
              {/* <div className="order_count">{this.state.so_confirmed_salesorders.length}</div> */}
            </div>
            <div className="list_4 toggles" menu="1">
              <i className="fa fa-list-alt order_status_icon"></i>
              <div className="order_status">Detailed O/S</div>
              {/* <div className="order_count">{this.state.placed_salesorders.length}</div> */}
            </div>
          </div>
          <div className="list_blocks_container">
              <div className="lists_container">
              <table className="cost_center_outstandings_table">
                    <thead>
                      <tr>
                        <th style={{maxWidth:"40px"}} align="center">#</th>
                        <th align="center">Cost Center</th>
                        <th align="center">Outstanding</th>
                        <th align="center">Overdue</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr style={{"display":this.state.showing_cost_centers.length?"none":"table-row"}}>
                      <td colSpan="2" align="center">No result!</td>
                    </tr>
                    {this.state.showing_cost_centers && this.state.showing_cost_centers.map((costCenter, costCenterIndex) => (
                      <tr key={costCenterIndex} >
                        
                        <td valign="top" align="center">{costCenterIndex+1}</td>
                        <td valign="top" style={{padding:"5px 0px"}}>
                          <span style={{fontWeight:"500",paddingLeft:"5px"}}>{costCenter.district}</span>
                      
                        </td>

                        <td  align="right">
                        {costCenter.sum_of_cost_center_outstanding}  <span style={{marginLeft:"2px", marginRight:"5px"}}><i className="fas fa-rupee-sign"></i></span>
                        </td>
                        <td  align="right">
                        {costCenter.sum_of_cost_center_overdue}  <span style={{marginLeft:"2px"}}><i className="fas fa-rupee-sign"></i></span>
                        </td>
                      </tr>    
                    ))}
                    <tr className="finalTotal">
                      <td colSpan={2}  align="center"> <b>Total</b>
                      </td>
                      <td align="right">
                        {this.state.outstanding} <span style={{marginLeft:"1px"}}><i className="fas fa-rupee-sign"></i></span> 
                      </td>
                      <td align="right">
                        {this.state.due_exceed} <span style={{marginLeft:"1px"}}><i className="fas fa-rupee-sign"></i></span> 
                      </td>
                    </tr>
                    </tbody>
                  
                  </table>

              </div>
              <div className="lists_container">
                <div className="search_block" >
                  <div className="search_container">
                    <CgSearch/>
                    <input className="customer_search_input" 
                      placeholder="Search customer.."
                      onChange={this.formChange}
                      name='customer'
                    />
                  </div>
                  <div className="search_container" style={{display:this.state.permission==true?"grid":"none"}}>
                    <CgSearch/>
                    <input className="customer_search_input" 
                      placeholder="Search invoice.."
                      onChange={this.formChange}
                      name='invoice'
                    />
                  </div>
                </div>
                <div className="table_container">
                <InfiniteScroll
                    dataLength={1}
                    refreshFunction={this.ageingReportTotal}
                    pullDownToRefresh
                    pullDownToRefreshThreshold={200}
                    pullDownToRefreshContent={
                      <h3 style={{ textAlign: 'center' , top : '50px'}}>&#8595; Pull down to refresh</h3>
                    }
                    releaseToRefreshContent={
                      <Loader />
                    }
                    >
                  <table className="customer_outstandings_table">
                    <thead>
                      <tr>
                        <th style={{maxWidth:"40px"}} align="center">#</th>
                        <th>Cost Center</th>

                      </tr>
                    </thead>
                    <tbody>
                    <tr style={{"display":this.state.showing_cost_centers.length?"none":"table-row"}}>
                      <td colSpan="4" align="center">No result!</td>
                    </tr>
                    {this.state.showing_cost_centers && this.state.showing_cost_centers.map((costCenter, costCenterIndex) => (
                      <tr key={costCenterIndex} >
                        
                        <td valign="top" align="center">{costCenterIndex+1}</td>
                        <td valign="top" style={{padding:"5px 0px"}}>
                          <span style={{fontWeight:"500",paddingLeft:"5px"}}>{costCenter.district}</span>
                      
                          <tr style={{"display": "inline"}}>
                          <div className="bills_table_container" style={{"display":costCenter.dealers.length?"block":"none"}}>
                            <table className="bills_table">
                              <tr>
                                <th style={{minWidth:'30px', textAlign:'center'}}>#</th>
                                <th style={{minWidth:'100%', textAlign:'center'}}>Customer</th>
                              </tr>


                            { costCenter.dealers.map((customer, customerIndex) => (
                              
                              <React.Fragment key={customerIndex}> 
                                <tr>
                                  <td style={{ textAlign: 'center' }}>
                                    {customerIndex + 1}
                                  </td>
                                  <td style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <span>{customer.name}</span>
                                    {customer.credit_note > 0 && (
                                      <span style={{ color: 'green', fontWeight: 'bold', textAlign: 'right' }}>
                                        {customer.credit_note} {rupee}
                                      </span>
                                    )}
                                  </td>
                                </tr>

                                  <tr style={{display: customer.bills.length>0 ?"table-row":"none"}}>
                                    <td></td>
                                    <td>
                                      <table className="bill_row_cell" style={{display: customer.bills.length>0 ?"table":"none"}}>
                                        <tr>
                                          <th style={{minWidth:'50px', textAlign:'center'}}>Date</th>
                                          <th style={{minWidth:'100px', textAlign:'center'}}>Invoice No</th>
                                          <th style={{minWidth:'60px', textAlign:'center'}}>Amount</th>
                                          <th style={{minWidth:'60px',textAlign:'center'}}>Due Exceed</th>
                                        </tr>
                                      
                                    { customer.bills.map((bill, billIndex) => (
                                        <tr  key={billIndex} style={{backgroundColor:bill.due_days!='+0'  ?"#ffa8b3":""}}>
                                          <td>{new Date(bill.bill_date).toLocaleDateString('en-GB')}</td>
                                          <td>{bill.bill_no}</td>
                                          <td align="right" style={{textAlign:"right"}}>
                                            <span>{Math.round(bill.bill_amount)}</span>
                                            <span style={{marginLeft:"3px"}}><i className="fas fa-rupee-sign"></i></span>
                                          </td>
                                          <td style={{border:"none",visibility:bill.exceed>0?"visible":"hidden",textAlign:"right"}} align="right">
                                            <span >{Math.round(bill.exceed)}</span> 
                                            <span style={{marginLeft:"3px"}}><i className="fas fa-rupee-sign"></i></span>
                                            <span>({bill.due_days} Days)</span>
                                          </td>
                                        </tr>
                                    ))}
                                    <tr>
                                      <th colSpan={2} style={{textAlign:'center'}}>
                                       <b>Total</b> 
                                      </th>
                                      
                                      <td style={{textAlign:'right'}}>
                                        {customer.pending_total}  <span style={{marginLeft:"2px", marginRight:"5px"}}><i className="fas fa-rupee-sign"></i></span>
                                      </td>
                                      <td style={{textAlign:'right'}}>
                                       {customer.exceed_due_total}  <span style={{marginLeft:"2px"}}><i className="fas fa-rupee-sign"></i></span>
                                      </td>
                                    </tr>
                                    </table>
                                    </td>
                                  </tr>
                                </React.Fragment>
                                  )) 
                                }
                          
                                <tr className="dealerTotal">
                                
                                  <td colSpan={2}>
                                  {costCenter.district} - 
                                  Total O/S : {costCenter.sum_of_cost_center_outstanding}  <span style={{marginLeft:"2px", marginRight:"5px"}}><i className="fas fa-rupee-sign"></i></span>

                                  Total Overdue : {costCenter.sum_of_cost_center_overdue}  <span style={{marginLeft:"2px"}}><i className="fas fa-rupee-sign"></i></span>
                                  </td>
                                </tr>
                            
                              <tfoot>
                              
                              </tfoot>
                              
                            </table>
                          </div>
                        </tr>
                        </td>
                      </tr>
                        
                    ))}
                    <tr className="finalTotal">
                      <td>

                      </td>
                      <td>
                        <span> 
                              <span>Outstanding: {this.state.showing_outstanding}  <span style={{marginLeft:"1px"}}><i className="fas fa-rupee-sign"></i></span> </span>
                        
                              <span style={{marginLeft:"8px"}}>Due Exceeded: {this.state.showing_due_exceed}  <span style={{marginLeft:"1px"}}><i className="fas fa-rupee-sign"></i></span></span>
                        </span>
                      </td>
                    </tr>
                    </tbody>
                  
                  </table>
                </InfiniteScroll>
                </div>
            </div>
            
          </div>
        </div>

      )}
    </MyContext.Consumer>
  )}
}

export default Outstanding;
Outstanding.contextType = MyContext;
