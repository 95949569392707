import React, { Component } from "react";
// import { render } from "react-dom";
import {Link} from "react-router-dom";
import DatePicker from 'react-date-picker';
import Select from "react-select";

import './NewOrder.scss';
import MyContext from '../MyContext.jsx';
import {epochToDate} from '../../assets/js/handy.mjs';

class NewOrder extends Component {
  constructor(props) {
    super(props);
    this.locationSelect = React.createRef();

    var today = new Date();
    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    this.state = {
      salesorder_id : '',
      customers : [],
      customer : {},
      order_date : today,
      delivery_date : tomorrow,
      delivery_types : [],
      delivery_type : 256,
      payment_types : [],
      payment_type : 132,
      remarks : '',
      billing_address : '',
      billing_contact_no : '',
      delivery_address : '',
      clone_address : false,
      delivery_contact_no : '',
      locations : [],
      delivery_location : {},
      products : [],
      rows: [{
        product : {},
        qty : '',
        unit_price : '',
        units : [],
        unit: {},
        total : '',
        productTax : '',
        productTotal : '',
        rate_types : [],
        rate_type: '',

      }],
      total_tax : '',
      taxable_total : '',
      grand_total : '',
      item_qty : 0,
    }
    this.formChange = this.formChange.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.clearAddress = this.clearAddress.bind(this);
    this.paymentTypeChange = this.paymentTypeChange.bind(this);
    this.deliveryTypeChange = this.deliveryTypeChange.bind(this);
    this.goBackButton = this.goBackButton.bind(this);

  }

  componentDidMount(){   
    $(".react-date-picker__inputGroup input").attr("readonly","true");
    const {pathname} = this.props.location;
    if(pathname === "/salesorders/new") {
      const url = `${process.env.REACT_APP_SSS}/api/salesorders/getCustomers`;
      this.context.loading(1);
      let customer_id;
      const { location } = this.props;
      if (location.state && location.state.customer_id) {
        this.setState({ customer_id: location.state.customer_id });
        customer_id = location.state.customer_id;
      }
      let option = null;
      var obj_this = this;
      $.ajax({
        url : url,
        data : {
          sid : this.context.user_details.sid
        },
        type : "POST"
      }).done(function(return_data){
        console.log(return_data);
        if(return_data.status == "success"){
          // const products = return_data.products.filter((item) => item.units.length);
          obj_this.setState({
            customers : return_data.customers,
            payment_types : return_data.payment_types,
            delivery_types : return_data.delivery_types,
            locations : return_data.locations,
            products : return_data.products,
            rows : [{
              product : {},
              unit_price : '',
              qty : '',
              units : [],
              unit: {},
              total : '',
            }]
          },() => {
            $(".new_salesorder_container").css("visibility","visible");
          });
          if(customer_id && return_data.customers){
            option = return_data.customers.find(item => item.value === customer_id);
            obj_this.setState({
              customer : option,
            })
            obj_this.customerChange(option);
          }
          obj_this.context.loading(0);
        }else{
          obj_this.context.loading(0);
          obj_this.props.history.replace("/");
        }
      }).fail(function(){
        alert("Network error");
      });
    }else{
      const salesorder_id = this.props.match.params.so_id;
      const url = `${process.env.REACT_APP_SSS}/api/salesorders/editSalesorder`;
      this.context.loading(1);
      var obj_this = this;
      $.ajax({
        url : url,
        data : {
          sid : this.context.user_details.sid,
          salesorder_id : salesorder_id
        },
        type : "POST"
      }).done(function(return_data){
        console.log(return_data);
        // return;
        if(return_data.status == "success"){
          var state_object = return_data.state_object;
          state_object.order_date = epochToDate(state_object.order_date);
          state_object.delivery_date = epochToDate(state_object.delivery_date);
          state_object.products = state_object.products;
          state_object.clone_address = false;
          console.log(state_object)
          obj_this.setState(state_object,() => {
            $(".minimize_button").trigger("click");
            $(".new_salesorder_container").css("visibility","visible");
          });

          obj_this.context.loading(0);
          console.log(obj_this.state);

        }else{
          obj_this.context.loading(0);
          obj_this.props.history.replace("/");
        }
      }).fail(function(){
        alert("Network error");
      });
    }

    var overflowTimeout = setTimeout(()=>{});
    $(".products_container").on("click",".maximize_button",function(){
      var elem = $(this).parents(".single_product_container");
      elem.find(".qty_unit_price_container").css("display","none");
      elem.heightAuto().find(".minimize_button").css("display","block");
      $(this).css("display","none");
      overflowTimeout = setTimeout(function(){
        elem.css("overflow","");
      },1000);
    });

    $(".products_container").on("click",".minimize_button",function(){
      var elem = $(this).parents(".single_product_container");
      elem.find(".qty_unit_price_container").css("display","grid");
      clearTimeout(overflowTimeout);
      var original_height = elem.height();
      elem.height(original_height);
      setTimeout(function(){
      elem.css({
        "height" : "46px",
        "overflow" : "hidden"
        }).find(".maximize_button").css("display","block");
      },1);
      $(this).css("display","none");
    });
  }

  customerChange(option){
    // console.log(option);
    var obj_this = this;
    obj_this.context.loading(1);
    const url = `${process.env.REACT_APP_SSS}/api/salesorders/getCustomerDetails`;
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid,
        customer_id : option.value,
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      if(return_data.status == "success"){
        obj_this.setState({
          customer : option,
          billing_address : return_data.customer.address,
          billing_contact_no : return_data.customer.tel_o??'',
          delivery_address : return_data.customer.address,
          delivery_contact_no : return_data.customer.tel_o??'',
          delivery_location : return_data.delivery_location
        }); 
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
     }).fail(function(){
      alert("Network error");
      obj_this.context.loading(0);
      obj_this.props.history.replace("/");
    });
  }

  paymentTypeChange(e){
    this.setState({
      payment_type : e.target.value
    }); 
  }

  deliveryTypeChange(e){
    this.setState({
      delivery_type : e.target.value
    }); 
  }

  onCheckboxChange(){
    if(this.state.clone_address){
      this.setState({
        delivery_contact_no : this.state.billing_contact_no,
        delivery_address : this.state.billing_address,
      });
    }
    this.setState({
      clone_address : !this.state.clone_address
    });
  }
  clearAddress(){
    this.setState({
      delivery_contact_no : '',
      delivery_address : '',
      clone_address : false
    })
  }
  
  formChange(e){
    this.setState({
      [e.target.name] : e.target.value
    });
  }
  onOrderDateChange(value){
    // console.log(value);
    if(value == null)
      return
    this.setState({order_date : value});
  }

  onDeliveryDateChange(value){
    // alert(value);
    if(value == null)
      return
    this.setState({delivery_date : value});
  }

  locationChange(option){
    this.setState({delivery_location : option})
  }

  productChange(option,idx){
    // console.log(option);
    if(!this.state.customer?.value){
      alert("Please select Customer first!");
      return;
    }
    $(".single_product_container").css('background-color','');
    const rows = [...this.state.rows];
    // var item = rows.filter(item => item.product.label == option.label);
    // if(item.length){
    //   alert(item[0].product.label+" already added.");
    //   // rows.splice(-1,1);
    //   // this.setState({
    //   //     rows
    //   //   });
    //   return;
    // }

    const row = {...rows[idx]}
    row.unit_price = '';
    row.qty = '';
    row.unit = {};
    row.product = {};
    var obj_this = this;
    obj_this.context.loading(1);
    const url = `${process.env.REACT_APP_SSS}/api/salesorders/getProductUnits`;
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid,
        product_id : option.value,
        customer_id : this.state.customer?.value
      },
      type : "POST"
    }).done(function(return_data){
      if(return_data.status == "success"){
          console.log(return_data);
          if(return_data.unit_price && return_data.unit_price.total ==null){
            alert('Please set Unit Price in Product Master');
            obj_this.context.loading(0)
            return;
          }
          if(!return_data.tax_not_set){
            alert('Please set Tax in Product Master');
            obj_this.context.loading(0)
            return;
          }
          row.units = return_data.units;
          row.rate_types = return_data.rate_types;
          row.rate_type = return_data.rate_type;
          row.unit = return_data.unit;
          row.unit_price = return_data.unit[row.rate_type.label];
          row.cgst_per = return_data.cgst_per;
          row.sgst_per = return_data.sgst_per;
          row.igst_per = return_data.igst_per;
          
          rows[idx] = row;
          row.product = option;
        
        obj_this.setState({
          rows
        });
        obj_this.context.loading(0)
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
     }).fail(function(){
      alert("Network error");
      obj_this.context.loading(0);
      obj_this.props.history.replace("/");
    });
  }
  rateTypeChange(option,idx){
    const rows = [...this.state.rows];
    const row = {...rows[idx]};
    console.log(row)
    // return

    row.rate_type = option;
    row.unit_price = row.unit[option.label];

    rows[idx] = row;
    console.log(row)

    this.setState({
      rows
    },() => {
      this.find_total(idx);
    });

  }
  unitChange(option,idx){
    // console.log(option);
    $(".single_product_container").css('background-color','');
    if(!option.PTS){
      alert("Unit price not added");
      return;
    }
    const rows = [...this.state.rows];
    const row = {...rows[idx]};
    row.unit = option;
    const rate_type = row.rate_type??'PTS'
    console.log(rate_type)
    row.unit_price = option[rate_type.label];
    rows[idx] = row;
    this.setState({
      rows
    },() => {
      this.find_total(idx);
    });

  }

  find_total(idx){
    const rows = [...this.state.rows];
    const row = {...rows[idx]};
    const qty = row.qty;

    row.total = Math.round(row.unit_price * qty * 100) / 100;

    row.cgst = row.total * row.cgst_per / 100;
    row.sgst = row.total * row.sgst_per / 100;
    row.igst = row.total * row.igst_per / 100;

    row.productTax = Math.round((row.cgst + row.sgst + row.igst) * 100) / 100;

    row.productTotal = Math.round((row.total + row.productTax) * 100) / 100;

    rows[idx] = row;
    this.setState({
      rows
    });
    var grand_total = 0;
    var taxable_total = 0;
    var total_tax = 0;

    for(let i=0;i<rows.length;i++){
      if(rows[i]['productTotal'] && rows[i]['productTax'] && rows[i]['total']){
        taxable_total += parseFloat(rows[i]['total']);
        total_tax += parseFloat(rows[i]['productTax']);
        grand_total += parseFloat(rows[i]['productTotal']);
      }
    }
    this.setState({
      taxable_total : parseFloat(taxable_total).toFixed(2),
      total_tax : parseFloat(total_tax).toFixed(2),
      grand_total : parseFloat(grand_total).toFixed(2),
      item_qty : rows.length
    });
  }

  handleChange = idx => e => {
    $(".single_product_container").css('background-color','');
    const { name, value } = e.target;
    const rows = [...this.state.rows];
    const row = {...rows[idx]};
    // if(e.target.name == "qty"){
      // if(parseFloat(value)> 999){
      //   alert("Enter a value less than 1000")
      //   return;
      // }
    // }
    row[name] = value;
    rows[idx] = row;
    var obj_this = this;
    this.setState({
      rows
    },() =>{
      if(e.target.name == "qty" || e.target.name == "rate_type" ){
        obj_this.find_total(idx)
      }
    });    
  };

  handleAddRow = () => {
    $(".single_product_container").css('background-color','');
    if(!this.validateProducts(this.state.rows)){
      return;
    }
    // console.log(this.state.rows);
    $(".single_product_container:not(:last-of-type) .minimize_button").trigger("click");
    const item = {
      product : {},
      unit_price : '',
      qty: "",
      unit : {}
    };
    this.setState({
      rows: [...this.state.rows, item]
    });
  };

  handleRemoveSpecificRow = (idx) => () => {
    // console.log(idx);
    // return;
    $(".single_product_container").css('background-color','');
    const rows = [...this.state.rows];
    rows.splice(idx, 1);
    this.setState({
      rows
    },() =>{
        this.find_total()
    });    


  }

  formSubmit(e){
    e.preventDefault();
    // console.log(this.state);
    // return;
    const { history, location } = this.props;

    if(!this.state.customer.value){
       this.refs.focus();
      return;
    }
    const salesorder = { ...this.state }; 
   
    console.log(this.state);
    if(!salesorder.delivery_location.value){
      this.locationSelect.current.focus();
     return;
   }
    //products validation
    if(!this.validateProducts(salesorder.rows)){
      return;
    }

    salesorder.o_date = salesorder.order_date.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
    salesorder.d_date = salesorder.delivery_date.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
    if(salesorder.clone_address){
      salesorder.delivery_address = salesorder.billing_address;
      salesorder.delivery_contact_no = salesorder.billing_contact_no;
    }
    // salesorder.schedule_id = location.state?.schedule_id??'';
    delete salesorder.locations;
    delete salesorder.customers;
    delete salesorder.products;
    delete salesorder.delivery_types;
    delete salesorder.payment_types;
        
    if (salesorder.rows.length) {
      const propertiesToKeep = new Set(['unit_price','value']);
    
      salesorder.rows.forEach(row => {
        delete row.rate_types;
        delete row.units;
        delete row.products;
    
        if (row.unit) {
          Object.keys(row.unit).forEach(key => {
            if (!propertiesToKeep.has(key)) {
              delete row.unit[key];
            }
          });
        }
      });
    }
    
    const url = `${process.env.REACT_APP_SSS}/api/salesorders/saveSalesorder`;
    this.context.loading(1);
    var obj_this = this;
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid,
        salesorder : salesorder
      },
      type : "POST"
    }).done(function(return_data){
      if(return_data.status == "insert"){
        alert("Salesorder successfully inserted");
      }else if(return_data.status == "update"){
        alert("Salesorder successfully updated");
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
      history.goBack();
      // obj_this.props.history.replace('/salesorders');
      obj_this.context.loading(0);
     }).fail(function(){
      alert("Network error");
      obj_this.context.loading(0);
      obj_this.props.history.replace("/");
    });
  }


  validateProducts(products) {
    let productLabels = new Set();
    if(products.length > 45){
      alert('Product limit exceeded')
      return
    }

    for (let i = 0; i < products.length; i++) {
        const product = products[i];
        const container = $(".single_product_container[index=" + i + "]");

        if (!product.product.label) {
            alert("Choose product");
            container.find(".maximize_button").trigger("click");
            container.find(".product").focus();
            return false;
        }

        if (productLabels.has(product.product.label)) {
            alert("Duplicate product found: " + product.product.label);
            container.find(".maximize_button").trigger("click");
            container.find(".product").focus();
            return false;
        }
        productLabels.add(product.product.label);

        if (!product.qty) {
            alert("Quantity required");
            container.find(".maximize_button").trigger("click");
            container.find(".product_qty").focus();
            return false;
        }

        if (!product.unit_price) {
            alert("Selling price required");
            container.find(".maximize_button").trigger("click");
            container.find(".unit_price").focus();
            return false;
        }

        if (!product.unit.label) {
            alert("Choose unit");
            container.find(".maximize_button").trigger("click");
            container.find(".unit").focus();
            return false;
        }
    }

    return true;
}



  goBackButton(e){
    e.preventDefault();

    const { history, location } = this.props;
    history.goBack();

  }

  render(){
  return (
    <MyContext.Consumer>
      {context => (
        <div className="new_salesorder_container">
          <div className="page_header">
            {/* <Link className="back_button" to="/salesorders"><i className="fas fa-arrow-left"></i></Link> */}
            <span className="back_button" onClick={e=>this.goBackButton(e)}><i className="fas fa-arrow-left"></i></span>
            <div className="page_title">{this.state.salesorder_id?"Edit":"New"} Salesorder</div>
          </div> 
          <form className="sales_order_form"
            onSubmit={e => this.formSubmit(e)}
          >
            <div className="customer_details_container blocks">
              <div className="input_label">Customer</div>
              <Select 
                options={this.state.customers}  
                value ={this.state.customer}
                className="customer" 
                ref={r => {
                  this.refs = r;
                }}
                onChange={e => this.customerChange(e)}
                required
                isDisabled = {this.state.salesorder_id?true:""}
              />
              <div className="input_label">Order Date</div>
              <DatePicker
                className="input custom_input"
                onChange={(value) => this.onOrderDateChange(value)}
                value={this.state.order_date}
                required
                format="dd-MM-y"
                minDate = {this.state.salesorder_id?"":new Date()}
                clearIcon={true}
              />
              <div className="input_label">Delivery Date</div>
              <DatePicker
                className="input custom_input"
                onChange={(value) => this.onDeliveryDateChange(value)}
                value={this.state.delivery_date}
                format="dd-MM-y"
                minDate = {this.state.salesorder_id?"":new Date()}
                clearIcon={true}
              />
              <div className="input_radio_container">
                <span>Payment Type : </span>
                <div className="radio_buttons">
                  {this.state.payment_types.map((item) => (
                    <div className="label_radio_container" key={item.id}>
                      <input type="radio" 
                        name="payment_type" 
                        value={item.id}
                        checked={this.state.payment_type == item.id} 
                        onChange={e => this.paymentTypeChange(e)}
                      />
                      <div className="radio_label">&nbsp;{item.name}</div>&nbsp;&nbsp;&nbsp;
                    </div>
                  ))}
                </div>
              </div>
              <div className="input_radio_container">
                <span>Delivery Type : </span>
                <div className="radio_buttons">
                  {this.state.delivery_types.map((item) => (
                    <div className="label_radio_container" key={item.id}>
                      <input type="radio" 
                        name="delivery_type" 
                        value={item.id}
                        checked={this.state.delivery_type == item.id} 
                        onChange={e => this.deliveryTypeChange(e)}
                      />
                      <div className="radio_label">&nbsp;{item.name}</div>&nbsp;&nbsp;&nbsp;
                    </div>
                  ))}
                </div>
              </div>
              <div className="input_label">Remarks</div>
              <textarea className="input remarks custom_input" 
                name="remarks"
                value={this.state.remarks}
                onChange={this.formChange}
                autoComplete="off"
              />
            </div>
            <div className="billing_details_container blocks">
              <div className="subtitle">Billing</div>
              <div className="input_label">Address</div>
              <textarea className="input billing_address custom_input" 
                name="billing_address"
                value={this.state.billing_address}
                onChange={this.formChange}
                required
                autoComplete="off"
              />
              <div className="input_label">Contact No</div>
              <input className="billing_contact_no input custom_input"
                type="tel"
                name="billing_contact_no"
                value={this.state.billing_contact_no}
                onChange={this.formChange}
                pattern="^[0]?[1-9][0-9]{9}$"
                required
                autoComplete="off"
              />
            </div>
            <div className="delivery_details_container blocks">
              <div className="delivery_title_container">
                <div className="subtitle">Delivery</div>
                <label className="clone_address_label">
                  <input
                    className="clone_address"
                    type="checkbox"
                    name="clone_address"
                    checked={this.state.clone_address}
                    onChange={this.onCheckboxChange}
                  />
                  <span className="same_as_label">Same as billing</span>
                </label>
                <i className="delete_product_button fas fa-trash" 
                    type="button"
                    onClick={this.clearAddress}
                  ></i>
              </div>
              <div className="input_label">Address</div>
              <textarea className="input delivery_address custom_input" 
                name={this.state.clone_address?"billing_address":"delivery_address"}
                value={this.state.clone_address?this.state.billing_address : this.state.delivery_address }
                onChange={this.formChange}
                required
                autoComplete="off"
              />
              <div className="input_label">Contact No</div>
              <input className="delivery_contact_no input custom_input"
                type="tel"
                name={this.state.clone_address?"billing_contact_no":"delivery_contact_no"}
                value={this.state.clone_address?this.state.billing_contact_no :this.state.delivery_contact_no}
                onChange={this.formChange}
                pattern="^[0]?[1-9][0-9]{9}$"
                required
                autoComplete="off"
              />
              <div className="input_label">Location</div>
              <Select 
                options={this.state.locations}  
                value ={this.state.delivery_location}
                className="location" 
                ref={this.locationSelect}
                onChange={e => this.locationChange(e)}
                required
              />
            </div>
            <div className="products_container blocks">
              <div className="subtitle" 
                style={{display:"inline-block"}}
              >
                <i className="fa fa-shopping-cart"></i> 
                 Products
              </div>
              {this.state.rows.map((item, idx) => (
                <div className="single_product_container" key={idx} index={idx}>
                  <div className="product_label_and_delete_button">
                    <div className="maximize_button">
                      <i className="fa fa-plus"></i>
                    </div>
                    <div className="minimize_button">
                      <i className="fa fa-minus"></i>
                    </div>
                    <div className="product_label">
                        {this.state.rows[idx].product.label}
                    </div>
                    <i className="delete_product_button fa fa-trash-alt" 
                      type="button"
                      style={{visibility:this.state.rows.length >1?"visible":"hidden"}}
                      onClick={this.handleRemoveSpecificRow(idx)}
                    ></i>
                  </div>
                  <div className="qty_unit_price_container">
                    <span
                       style={{visibility:this.state.rows[idx].unit.label?"visible":"hidden"}}
                    >Qty : {this.state.rows[idx].qty} {this.state.rows[idx].unit.label}</span>
                    <span
                       style={{visibility:this.state.rows[idx].unit_price?"visible":"hidden"}}
                    >Unit Price : {this.state.rows[idx].unit_price}</span>
                    <div 
                      style={{visibility:this.state.rows[idx].total?"visible":"hidden"}}
                      className="product_total"
                    >Price: Rs.
                      {/*<span>{parseFloat(this.state.rows[idx].qty*this.state.rows[idx].unit_price).toFixed(2)}</span>*/}
                      <span>{this.state.rows[idx].total}</span>
                    </div>
                  </div>
                  <div className="input_label">Product</div>
                  <Select 
                    options={this.state.products}  
                    value ={this.state.rows[idx].product}
                    className="product" 
                    onChange={e => this.productChange(e,idx)}
                    required
                  />

                  <div className="input_grid">
                    <div>
                      <div className="input_label">Rate Types</div>
                      <Select
                        options={this.state.rows[idx].rate_types}
                        value={this.state.rows[idx].rate_type}
                        placeholder=""
                        className="unit"
                        onChange={e => this.rateTypeChange(e, idx)}
                        required
                      />
                    </div>
                    <div>
                      <div className="input_label">Unit</div>
                      <Select
                        options={this.state.rows[idx].units}
                        value={this.state.rows[idx].unit}
                        placeholder=""
                        className="unit"
                        onChange={e => this.unitChange(e, idx)}
                        required
                      />
                    </div>
                  </div> 
                  
                  <div className="input_grid">
                    <div>
                      <div className="input_label">Quantity</div>
                      <input className="product_qty custom_input"
                        type="number"
                        name="qty"
                        step="0.001"
                        min="0"
                        // max="999"
                        value={this.state.rows[idx].qty}
                        onChange={this.handleChange(idx)}
                        autoComplete="off"
                      />
                    </div>
                    <div>
                    <div className="input_label ">Unit Price </div>
                    <input className="unit_price input custom_input"
                      type="number"
                      name="unit_price"
                      value={this.state.rows[idx].unit_price}
                      onChange={this.handleChange(idx)}
                      min="0"
                      step="0.01"
                      autoComplete="off"
                      disabled
                    />
                    </div>
                 </div>
                
              </div>
              ))}
              <div className="price_and_add_button_container">
                <button className="add_product_button"
                  type="button"
                  // style={{visibility:this.state.rows[this.state.rows.length-1].product.label && this.state.rows[this.state.rows.length-1].unit.label?"visible":"hidden"}}
                  onClick={this.handleAddRow}
                >
                  <i className="fa fa-plus"></i> Product
                </button>
              </div>
              <div>
                <div className="grand_total"
                  style={{ visibility: this.state.item_qty ? "visible" : "hidden" }}
                >
                  <b> {this.state.item_qty} Items</b>
                </div>
                <div className="grand_total"
                  style={{visibility:this.state.taxable_total?"visible":"hidden"}}
                >Total Rs.
                  <b> {this.state.taxable_total} <i className="fas fa-rupee-sign"></i></b>
                  </div>
                  <div className="grand_total"
                  style={{visibility:this.state.total_tax?"visible":"hidden"}}
                >Tax Rs.
                  <b> {this.state.total_tax} <i className="fas fa-rupee-sign"></i></b>
                  </div>
                  <div className="grand_total"
                  style={{visibility:this.state.grand_total?"visible":"hidden"}}
                >Grand Total Rs.
                  <b> {this.state.grand_total} <i className="fas fa-rupee-sign"></i></b>
                  </div>
              </div>
            </div>
            <button type="submit" className="submit_button"><i className="far fa-save"></i> Save</button>
          </form>           
        </div>
      )}
    </MyContext.Consumer>
  )}
}

export default NewOrder;
NewOrder.contextType = MyContext;
