import React from 'react';
import './DashboardTile.scss';

const DivisionTile = ({ heading, content, onClick }) => {
  return (
    <div className="dashboard-tile" onClick={onClick}>
      <span className='title_dashboard'>{heading}</span>
      {/* <p>{content}</p> */}
    </div>
  );
};

export default DivisionTile;