
import {Link} from "react-router-dom";

import './Dashboard.scss';

import { CgList, CgUserAdd } from 'react-icons/cg';
import { GiSandsOfTime } from 'react-icons/gi';
import { FaMoneyCheckAlt } from 'react-icons/fa';

import React, { useContext } from 'react';
import MyContext from '../MyContext.jsx';




const Dashboard = () => {
  const userDetails = useContext(MyContext);

  return (
    <div className="dashboard_page_container">
      <div className="menu_container">
      {/* onClick={e => e.preventDefault()} */}
        {/* <Link to="/outstandings" className="single_menu_container" style={{background:"linear-gradient(315deg, #fb6bd0 0%, #c76cef 74%)"}}>
          <div className="menu_icon_container">
            <CgList size='2.8em' className="menu_icon"/>
          </div>
          <div className="menu_label">Outstandings</div>
        </Link> */}
        <Link to="/outstandings" className="single_menu_container">
          <img className="menu_icon_container"  src={window.project_path+"/dashboard/Outstanding.png"} height="100px;" />
        </Link>


        {/* <Link to="/postdatecheque" className="single_menu_container" style={{background:"rgba(0, 0, 0, 0) linear-gradient(315deg, rgb(89, 47, 77) 0%, rgb(114, 0, 164) 74%) repeat scroll 0% 0%"}}>
          <div className="menu_icon_container">
            <i className="fa fa-money-check" style={{fontSize:"36px"}}></i>
            
          </div>
          <div className="menu_label">Post Dated Cheque</div>
        </Link> */}
        <Link to="/postdatecheque" className="single_menu_container">
          <img className="menu_icon_container"  src={window.project_path+"/dashboard/PostdatedCheck.png"} height="100px;" />
        </Link>

        {/* <div style={{display:userDetails.user_details.designation=='ASM'?"block":userDetails.user_details.designation=='RSM'?"block":"none"}}>
        <Link to="/reports" className="single_menu_container"
         style={{background:"rgba(0, 0, 0, 0) linear-gradient(315deg, rgb(44, 15, 244) 0%, rgb(17, 131, 157) 74%) repeat scroll 0% 0%"}}>
        
          <div className="menu_icon_container">
            <i className="fa fa-file" style={{fontSize:"36px"}}></i>
          </div>
          <div className="menu_label">Reports</div>
        </Link>
        </div> */}
       

        {/* <Link to="/targets" className="single_menu_container"
        style={{background:"rgba(0, 0, 0, 0) linear-gradient(315deg, rgb(16, 151, 232) 0%, rgb(115, 18, 255) 74%) repeat scroll 0% 0%"}}>
          <div className="menu_icon_container">
            <i className="fa fa-bullseye" style={{fontSize:"36px"}}></i>
          </div>
          <div className="menu_label">Targets</div>
        </Link> */}
        <Link to="/targets" className="single_menu_container">
          <img className="menu_icon_container"  src={window.project_path+"/dashboard/Target.png"} height="100px;" />
        </Link>

        {/* <Link to="/collections" className="single_menu_container" 
        style={{background:"linear-gradient(315deg, #fd8df1 0%, #ff7e95 74%)"}}>

          <div className="menu_icon_container">
            <i className="far fa-credit-card" style={{fontSize:"36px"}}></i>
          </div>
          <div className="menu_label">Collections</div>
        </Link> */}

        {/* <Link to="/samplerequests" 
          className="single_menu_container" style={{background:"rgba(0, 0, 0, 0) linear-gradient(315deg, rgb(140, 168, 90) 0%, rgb(71, 119, 1) 74%) repeat scroll 0% 0%"}}>
        
          <div className="menu_icon_container">
            <i className="fas fa-paper-plane" style={{fontSize:"36px"}}></i>
          </div>
          <div className="menu_label">Sample Request</div>
        </Link> */}

        {/* <Link to="/myvisitfrequency" className="single_menu_container" 
         style={{background:"rgba(0, 0, 0, 0) linear-gradient(315deg, rgb(44, 15, 244) 0%, rgb(9, 207, 253) 74%) repeat scroll 0% 0%"}}>
        
          <div className="menu_icon_container">
            <i className="fas fa-users" style={{fontSize:"36px"}}></i>
          </div>
          <div className="menu_label">My Visit<br></br> Report</div>
        </Link> */}
        {/* <div style={{display:userDetails.user_details.es_approval?"block":"none"}}>
        <Link to="/jointvisit" className="single_menu_container" 
            style={{background:"rgba(0, 0, 0, 0) linear-gradient(315deg, rgb(0, 0, 0) 0%, rgb(89, 229, 144) 74%) repeat scroll 0% 0%"}}>
            <div className="menu_icon_container">
              <i className="far fa-comment-alt menu_icon" style={{fontSize:"34px"}}></i>
            </div>
            <div className="menu_label">Joint Visit</div>
        </Link>
        </div> */}
        {/* <Link to="/doctorwisesale" className="single_menu_container"  */}
        {/* <div className="single_menu_container" style={{background:"linear-gradient(315deg, #5742ec 0%, #6dd5ed 74%)"}}>
        
          <div className="menu_icon_container">
            <i className="fa fa-user-md" style={{fontSize:"36px"}}></i>
          </div>
          <div className="menu_label">Doctor Wise Sale</div>
        </div> */}
        {/* </Link> */}
        {/* <Link to="/primarysecondary" className="single_menu_container"  */}
        {/* <div className="single_menu_container" style={{background:"linear-gradient(315deg, #5742ec 0%, #6dd5ed 74%)"}}>
        
          <div className="menu_icon_container">
            <i className="fas fa-undo-alt" style={{fontSize:"36px"}}></i>
          </div>
          <div className="menu_label">Primary<br></br>/Secondary</div>
        </div> */}
        {/* </Link> */}

         {/* <Link to="/secondarysale" className="single_menu_container"
         style={{background:"rgba(0, 0, 0, 0) linear-gradient(315deg, rgb(44, 15, 244) 0%, rgb(17, 131, 157) 74%) repeat scroll 0% 0%"}}>
        
          <div className="menu_icon_container">
            <i className="fa fa-shopping-cart" style={{fontSize:"36px"}}></i>
          </div>
          <div className="menu_label">Secondary Sale</div>
        </Link> */}
        <Link to="/secondarysale" className="single_menu_container">
          <img className="menu_icon_container"  src={window.project_path+"/dashboard/SecondarySale.png"} height="100px;" />
        </Link>

        <Link to="/samplerequests" className="single_menu_container">
          <img className="menu_icon_container"  src={window.project_path+"/dashboard/SampleReq.png"} height="100px;" />
        </Link>

        <div style={{display:userDetails.user_details.designation=='ASM'?"block":userDetails.user_details.designation=='RSM'?"block":userDetails.user_details.designation=='ADMIN'?"block":"none"}}>
          {/* <Link to="/srreport" 
            className="single_menu_container" style={{background:"rgba(0, 0, 0, 0) linear-gradient(315deg, rgb(140, 168, 90) 0%, rgb(71, 119, 1) 74%) repeat scroll 0% 0%"}}>
            <div className="menu_icon_container">
              <i className="fas fa-paper-plane" style={{fontSize:"36px"}}></i>
            </div>
            <div className="menu_label">Sample Request Report</div>
          </Link> */}
          <Link to="/srreport" className="single_menu_container">
            <img className="menu_icon_container"  src={window.project_path+"/dashboard/SampleRequest.png"} height="100px;" />
          </Link>
        </div>


        <Link to="/creditnotesreport" className="single_menu_container">
          <img className="menu_icon_container"  src={window.project_path+"/dashboard/CreditNotes.png"} height="100px;" />
        </Link>


        <Link to="/salesreturnreport" className="single_menu_container">
          <img className="menu_icon_container"  src={window.project_path+"/dashboard/SalesReturn.png"} height="100px;" />
        </Link>

        <Link to="/distributorsalesreport" className="single_menu_container">
          <img className="menu_icon_container"  src={window.project_path+"/dashboard/DistributorSales.png"} height="100px;" />
        </Link>

        <Link to="/rate/calculator" className="single_menu_container">
          <img className="menu_icon_container" src={window.project_path+"/dashboard/RateCalculator.png"} height="100px;" />
        </Link>

        <Link to="/catalogue/attachment" className="single_menu_container">
          <img className="menu_icon_container"  src={window.project_path+"/dashboard/CatalogueAttachment.png"} height="100px;" />
        </Link>

        
         {/* <Link to="/employee/attendance/leave" className="single_menu_container"
        style={{background:"linear-gradient(315deg, #0f1312 0%, #e39be2 74%)"}}>
        
          <div className="menu_icon_container">
            <i className="far fa-calendar-alt" style={{fontSize:"36px"}}></i>
          </div>
          <div className="menu_label">Attendance/<br/> Leave</div>
        </Link> */}

        {/* <Link to="/travel/allowance" className="single_menu_container" style={{background:"linear-gradient(315deg, #00e4a6 0%, #86e559 74%)"}}>
          <div className="menu_icon_container">
            <FaMoneyCheckAlt size='2.8em' className="menu_icon"/>
          </div>
          <div className="menu_label">Allowances</div>
        </Link> */}
        {/* <div style={{display:userDetails.user_details.ta_approval?"block":"none"}}>
          <Link to="/travel/allowance/approval" className="single_menu_container" style={{background:"rgba(0, 0, 0, 0) linear-gradient(315deg, rgb(29, 242, 184) 0%, rgb(5, 98, 28) 74%) repeat scroll 0% 0%"}} >
            <div className="menu_icon_container">
              <FaMoneyCheckAlt size='2.8em' className="menu_icon"/>
            </div>
            <div className="menu_label">Allowance Approval</div>
          </Link>
        </div> */}
        
        {/* <div style={{display:userDetails.user_details.lr_approval?"block":"none"}}>
          <Link to="/employee/leave/approval" className="single_menu_container"
          style={{background:"rgba(0, 0, 0, 0) linear-gradient(315deg, rgb(123, 122, 109) 0%, rgb(255, 110, 4) 74%) repeat scroll 0% 0%"}}>
            <div className="menu_icon_container">
              <i className="fa fa-calendar-plus" style={{fontSize:"36px"}}></i>
            </div>
            <div className="menu_label">Leave <br></br>Approval</div>
          </Link>
        </div> */}
        {/* <div style={{display:userDetails.user_details.es_approval?"block":"none"}}>
          <Link to="/schedule/approval" className="single_menu_container"
          style={{background:"rgba(0, 0, 0, 0) linear-gradient(315deg, rgb(7, 28, 8) 0%, rgb(78, 121, 56) 74%) repeat scroll 0% 0%"}}>
          
            <div className="menu_icon_container">
              <i className="fa fa-calendar-check" style={{fontSize:"36px"}}></i>
            </div>
            <div className="menu_label">Schedule Approval</div>
          </Link>
        </div> */}
       
        </div>

       

      {/* <div className="marketing_title">Marketing</div>
        <div className="menu_container">
          <Link to="/marketing/brand" className="single_menu_container" 
            style={{background:"linear-gradient(315deg, #2193b0 0%, #6dd5ed 74%)"}}>
            <div className="menu_icon_container">
              <i className="fab fa-artstation menu_icon" style={{fontSize:"34px"}}></i>
            </div>
            <div className="menu_label">New Brand</div>
          </Link>

          <Link to="/marketing/product" className="single_menu_container" 
            style={{background:"linear-gradient(315deg, rgba(238, 39, 95, 0.86) 0%, rgb(240, 55, 55) 74%)"}}>
            <div className="menu_icon_container">
              <i className="fas fa-suitcase menu_icon" style={{fontSize:"34px"}}></i>
            </div>
            <div className="menu_label">New Product</div>
          </Link>


          <Link to="/marketing/dealer" className="single_menu_container" 
            style={{background:"linear-gradient(315deg, rgb(238, 83, 22) 0%, rgb(229, 201, 89) 74%)"}}>
            <div className="menu_icon_container">
              <i className="fas fa-suitcase menu_icon" style={{fontSize:"34px"}}></i>
            </div>
            <div className="menu_label">Dealer</div>
          </Link>

          <Link to="/marketing/influencer" className="single_menu_container" 
            style={{background:"linear-gradient(315deg, rgb(228, 0, 96) 0%, rgb(229, 89, 161) 74%)"}}>
            <div className="menu_icon_container">
              <i className="fas fa-user-shield menu_icon" style={{fontSize:"34px"}}></i>
            </div>
            <div className="menu_label">Influencer</div>
          </Link>

           <Link to="/marketing/influencer/feedback" className="single_menu_container" 
            style={{background:"linear-gradient(315deg, rgb(0, 228, 185) 0%, rgb(89, 229, 144) 74%)"}}>
            <div className="menu_icon_container">
              <i className="far fa-comment-alt menu_icon" style={{fontSize:"34px"}}></i>
            </div>
            <div className="menu_label">Influencer <br/>Feedback</div>
          </Link>

           <Link to="/marketing/analysis" className="single_menu_container" 
            style={{background:"linear-gradient(315deg, rgb(85, 153, 215) 0%, rgb(94, 67, 191) 74%)"}}>
            <div className="menu_icon_container">
               <i className="fas fa-chart-area menu_icon"  style={{fontSize:"34px"}}></i>
            </div>
            <div className="menu_label">Marketing <br/>Analysis</div>
          </Link>
        </div> */ }
    </div>
  )
}

export default Dashboard;

