import React, { Component } from "react";
import {Link} from "react-router-dom";
import DatePicker from 'react-date-picker';
import Select from "react-select";

import { useHistory } from 'react-router'

import './NewSchedule.scss';
import MyContext from '../MyContext.jsx';
import {epochToDate} from '../../assets/js/handy.mjs';

class NewSchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customers : [],
      customer : {},
      schedule_type : 'DISTRIBUTORS',
      scheduled_date : new Date(),
      schedules : [],
      locations : [],
      location  : {},
      schedule_requests:[]
    }
  }

  componentDidMount(){   
    $(".react-date-picker__inputGroup input").attr("readonly","true");
    this.getScheduleCustomers();   
    this.getScheduleRequests();
  }

  customerTypeChange(e) {
    this.getScheduleCustomers(e.target.value);
    this.handleRemoveAllSchedule();
    this.setState({
      schedule_type: e.target.value
    });
  }

  getScheduleCustomers(c_type){
    const schedule_type = c_type??"DISTRIBUTORS";
    if(schedule_type=='DISTRIBUTORS'){
      const url = `${process.env.REACT_APP_SSS}/api/getScheduleCustomers`;
      this.context.loading(1);
      var obj_this = this;
      $.ajax({
        url : url,
        data : {
          sid : this.context.user_details.sid,
          schedule_type : schedule_type
        },
        type : "POST"
      }).done(function(return_data){
        console.log(return_data);
        if(return_data.status == "success"){
          obj_this.setState({
            customers : return_data.customers,
          });
          obj_this.context.loading(0);
        }else{
          obj_this.context.loading(0);
          obj_this.props.history.replace("/");
        }
      }).fail(function(){
        alert("Network error");
      });
    }
    else{
      const url = `${process.env.REACT_APP_SSS}/api/getZoneMappedLocation`;
      this.context.loading(1);
      var obj_this = this;
      $.ajax({
        url : url,
        data : {
          sid : this.context.user_details.sid,
          schedule_type : schedule_type
        },
        type : "POST"
      }).done(function(return_data){
        console.log(return_data);
        if(return_data.status == "success"){
          obj_this.setState({
            locations : return_data.locations,
          });
          obj_this.context.loading(0);
        }else{
          obj_this.context.loading(0);
          obj_this.props.history.replace("/");
        }
      }).fail(function(){
        alert("Network error");
      });

    }
  }

  getScheduleRequests(){
    const url2 = `${process.env.REACT_APP_SSS}/api/getScheduleRequests`;
    this.context.loading(1);
    var obj_this = this;
    $.ajax({
      url : url2,
      data : {
        sid : this.context.user_details.sid,
        // schedule_type : schedule_type
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      if(return_data.status == "success"){
        obj_this.setState({
          schedule_requests : return_data.schedule_requests,
        });
        obj_this.context.loading(0);
        console.log(obj_this.state.schedule_requests)
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      alert("Network error");
    });
  }

  customerChange(option){
    // console.log(option);
    this.setState({
      customer : option
    }); 
  }
  locationChange(option){
    // console.log(option);
    this.setState({
      location : option
    }); 
  }

  onDateChange(value){
    // console.log(value);
    this.setState({scheduled_date : value});
  }

  handleAddSchedule = () => {
    // console.log(this.state.rows);
    if(!this.state.scheduled_date){
      alert('Date required');
      return;
    }
    const schedule = {
      customer : this.state.customer,
      location : this.state.location,
      schedule_type : this.state.schedule_type,
      scheduled_date : this.state.scheduled_date.toLocaleDateString('en-CA'),
    };

    const schedules = this.state.schedules;
    if(schedule.customer.label){
      this.setState({
        schedules: [...this.state.schedules, schedule],
        customer : {},
        location: {},
        scheduled_date : new Date()
      });  
    }
    if(schedule.location.label){
      this.setState({
        schedules: [...this.state.schedules, schedule],
        customer : {},
        location: {},
        scheduled_date : new Date()
      });  
    }
  };

  handleRemoveSchedule = (idx) => () => {
    // console.log(idx);
    const schedules = [...this.state.schedules];
    schedules.splice(idx, 1);
    this.setState({ 
      schedules 
    });

  }

  handleRemoveAllSchedule=()=>{
    const schedules = [...this.state.schedules];
    schedules.splice(0, schedules.length);
    this.setState({
      schedules
    });
  }

  formSubmit(e){
    e.preventDefault();
    // console.log(this.state);
    // return;
    const schedules = this.state.schedules;
    if(!this.state.schedules.length){
      return
    }
    const url = `${process.env.REACT_APP_SSS}/api/saveNewSchedules`;
    this.context.loading(1);
    var obj_this = this;
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid,
        schedules : schedules
      },
      type : "POST"
    }).done(function(return_data){
      // console.log(return_data);
      if(return_data.status == "success"){
        obj_this.setState({ 
          schedules : []
        });
        alert("Schedules added");
        // obj_this.props.history.replace('/schedules');
        obj_this.getScheduleRequests();
        obj_this.context.loading(0);
        obj_this.props.history.replace('/schedules/new');
      }else if(return_data.status == "already"){
        obj_this.setState({ 
          schedules : []
        });
        alert("Schedule already added");
        obj_this.context.loading(0);
      }
      else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      alert("Network error");
    });
  }

  render(){
  return (
    <MyContext.Consumer>
      {context => (
        <div className="new_schedule_container">
          <div className="page_header">
            <Link className="back_button" to="/schedules"><i className="fas fa-arrow-left"></i></Link>
            <div className="page_title">New Schedules</div>
          </div> 
          <form className="schedule_form"
            onSubmit={e => this.formSubmit(e)}
          >
            <div className="customer_details_container blocks">
              <div className="customer_type_radio_buttons">
                <div className="label_radio_container">
                  <input type="radio" 
                    name="schedule_type" 
                    value="DISTRIBUTORS"  
                    checked={this.state.schedule_type === 'DISTRIBUTORS'}
                    onChange={e => this.customerTypeChange(e)}
                  />
                  <div>Distributor</div>
                </div>
                <div className="label_radio_container">
                  <input type="radio" 
                    name="schedule_type" 
                    value="DOCTORS"
                    checked={this.state.schedule_type === 'DOCTORS'} 
                    onChange={e => this.customerTypeChange(e)}
                  />
                  <div>Doctor</div>
                </div>
              </div>
              <div style={{display:this.state.schedule_type==='DISTRIBUTORS'?"block":"none"}}>
                <div className="input_label">Customer</div>
                <Select
                  options={this.state.customers}  
                  value ={this.state.customer}
                  className="customer" 
                  ref={r => {
                    this.refs = r;
                  }}
                  onChange={e => this.customerChange(e)}
                  // isSearchable={false}
                  required={this.state.schedule_type==='DISTRIBUTORS'}
                />
              </div>
              <div style={{display:this.state.schedule_type==='DOCTORS'?"block":"none"}}>
                <div className="input_label">Location</div>
                <Select
                  options={this.state.locations}  
                  value ={this.state.location}
                  className="customer" 
                  ref={r => {
                    this.refs = r;
                  }}
                  onChange={e => this.locationChange(e)}
                  required={this.state.schedule_type==='DOCTORS'}
                  // isSearchable={false}
                />
              </div>
              <div className="input_label">Date</div>
              <DatePicker
                className="input custom_input"
                onChange={(value) => this.onDateChange(value)}
                value={this.state.scheduled_date}
                minDate = {new Date()}
                required
                format="dd-MM-y"
              />
              <div className="add_button_container">
                <button className="add_schedule_button"
                  type="button"
                  style={{visibility:this.state.customer.label||this.state.location.label?"visible":"hidden"}}
                  onClick={this.handleAddSchedule}
                > 
                  <i className="fa fa-plus"></i> Add
                </button>
              </div>
            </div>
            <div className="schedules_table_container blocks"
              style={{display:this.state.schedules.length?"block":"none"}}
            >
              <table>
                <thead>
                  <tr>
                    <th>Sl No.</th>
                    <th align="left" style={{display:this.state.schedule_type=='DISTRIBUTORS'?'block':'none'}}>Customer</th>
                    <th align="left" style={{display:this.state.schedule_type=='DOCTORS'?'block':'none'}}>Location</th>
                    <th align="left">Date</th>
                    <th>Remove</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.schedules.map((item,idx) => (
                    <tr key={idx}>
                      <td align="center">{idx+1}</td>
                      <td style={{display:this.state.schedule_type=='DISTRIBUTORS'?'block':'none'}}>{item.customer.label}</td>
                      <td style={{display:this.state.schedule_type=='DOCTORS'?'block':'none'}}>{item.location.label}</td>
                      <td>{new Date(item.scheduled_date).toLocaleDateString('en-GB')}</td>
                      <td align="center">
                        <i className="remove_schedule_button fas fa-times"
                          onClick={this.handleRemoveSchedule(idx)}
                        ></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <button type="submit" 
            className="submit_button"
            style={{display:this.state.schedules.length?"block":"none"}}
            >
              <i className="far fa-save"></i> Save
            </button>
          </form>    
          <div className="list_blocks_container">
            <div className="lists_container">
                  {this.state.schedule_requests.map((item,idx)=>(
                    <div className="single_schedule_container" key={idx}>
                      <div className="scheduled_customer_name_delete_button_container">
                        <div className="scheduled_date_container">
                          <div className="scheduled_month">{new Date(item.date).toLocaleString('default', { month: 'short' })}</div>
                          <div className="scheduled_day">{new Date(item.date).getDate()}</div>
                        </div>
                        <div className="customer_name_check_in_button_container">
                          <div className="scheduled_customer_name"><i className={item.dealer?'fa fa-user':'fa fa-map-marker-alt'}></i> {item.dealer?item.dealer:item.location}</div>
                          {/* <div className="schedules_button_container"
                          ><a className="map_button"
                              style={{visibility:item.customer_latitude?"visible":"hidden"}}
                              href={"geo:0,0?q="+item.customer_latitude+","+item.customer_longitude+"('"+item.name+"')"}
                            >Map&nbsp;
                              <i className="fas fa-map-marker-alt"></i>
                            </a>
                          </div> */}
                          <div className="schedule_requested" style={{display:item.status=="REQ"?"block":"none"}}><i className="fa fa-clock" aria-hidden="true"></i> REQUESTED</div>
                          <div className="schedule_approved" style={{display:item.status=="APR"?"block":"none"}}> <i className="fa fa-check" aria-hidden="true"></i>APPROVED</div>
                          <div className="schedule_rejected" style={{display:item.status=="CNL"||item.status=="APR-CNL"?"block":"none"}}> <i className="fa fa-times" aria-hidden="true"></i>REJECTED</div>
                        </div>
                        <div></div>
                      </div>
                    </div>

                  ))}
                </div>
          </div>

        </div>
       
        
      )}
    </MyContext.Consumer>
  )}
}

export default NewSchedule;
NewSchedule.contextType = MyContext;
