import React, { Component } from "react";
import {Link} from "react-router-dom";
import { FiChevronDown } from 'react-icons/fi';

import './Orders.scss';
import MyContext from '../MyContext.jsx';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from "../Loading/Loader";


class SalesOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      req_salesorders : [],
      pend_salesorders : [],
      so_confirmed_salesorders : [],
      placed_salesorders : [],
    }
    this.fetchData = this.fetchData.bind(this);

  }
  fetchData= ()=>{
    var obj_this = this;
    const url = `${process.env.REACT_APP_SSS}/api/getDistrictWiseExeSalesorders`;
    this.context.loading(1);
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      // return;
      if(return_data.status == "success"){ 
        obj_this.setState(return_data.salesorders);
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/dashboard");
      }
    }).fail(function(){
      obj_this.context.loading(0);
      alert("Network error");
    });
  }

  componentDidMount(){    
    this.fetchData();
    var obj_this = this;

    $(".sales_orders_container").on("click",".so_approve_button",function(e){
      e.stopPropagation();
      if(!confirm("Do you want to confirm this order")){
        return;
      }

      const salesorder_id = $(this).attr("salesorder_id");

      const url = `${process.env.REACT_APP_SSS}/api/salesorders/salesorderApproval`;
      obj_this.context.loading(1);
      $.ajax({
        url : url,
        data : {
          sid : obj_this.context.user_details.sid,
          salesorder_id : salesorder_id
        },
        type : "POST"
      }).done(function(return_data){
          console.log(return_data);
        if(return_data.status == "success"){
          obj_this.setState(return_data.salesorders,()=>{
            $(".sales_orders_container .single_so_container").css("height","60px").find(".arrow_down").css("transform","rotate(0deg)");
          });
          obj_this.fetchData();
          obj_this.context.loading(0);
          alert("Salesorder verified.")
        }else{
          obj_this.context.loading(0);
          obj_this.props.history.replace("/");
        }
      }).fail(function(){
        alert("Network error");
      });

    });

    $(".sales_orders_container").on("click",".so_edit_button",function(e){
       e.stopPropagation();
       const salesorder_id = $(this).attr("salesorder_id");
       obj_this.props.history.push("/salesorders/edit/"+salesorder_id);
    });

   
    $(".sales_orders_container").on("click",".single_so_container",function(){
      if($(this).outerHeight() == "60"){
        $(this).heightAuto();
        $(this).find(".arrow_down").css("transform","rotate(180deg)");
      }else{
        $(this).css("height","60px");
        $(this).find(".arrow_down").css("transform","rotate(0deg)");
      }
    });
    ui.swipeMenu(".sales_orders_container");
  }


  render(){
  return (
    <MyContext.Consumer>
      {context => (
        <div className="sales_orders_container noselect">
          <div className="header_spacer"></div>
          <div className="sales_orders_container uijs swipemenu">
            <div className="page_header">
              <div className="page_title">Salesorders List</div>
              <Link className="new_sales_order_button" 
                to="/salesorders/new"
                // style={{visibility:this.context.user_details.place_order?"visible":"hidden"}}
              > <i className="fa fa-plus plus_icon"></i> New</Link>
            </div>

            <div className="toggles_container">
            <div className="list_1 toggles" menu="0">
                <i className="fa fa-clipboard-list order_status_icon"></i>
                <div className="order_status">Requested</div>
                <div className="order_count">{this.state.req_salesorders.length}</div>
              </div>
              <div className="list_2 toggles" menu="1">
                <i className="fa fa-hourglass-start order_status_icon"></i>
                <div className="order_status">Pending</div>
                <div className="order_count">{this.state.pend_salesorders.length}</div>
              </div>
              <div className="list_3 toggles" menu="2">
                <i className="fa fa-box order_status_icon"></i>
                <div className="order_status">SO Confirmed</div>
                <div className="order_count">{this.state.so_confirmed_salesorders.length}</div>
              </div>
              <div className="list_4 toggles" menu="3">
                <i className="fa fa-check-circle order_status_icon"></i>
                <div className="order_status">Placed</div>
                <div className="order_count">{this.state.placed_salesorders.length}</div>
              </div>
            </div>

            <div className="list_blocks_container">
            <div className="lists_container">
            <InfiniteScroll
                    dataLength={1}
                    refreshFunction={this.fetchData}
                    pullDownToRefresh
                    pullDownToRefreshThreshold={300}
                    pullDownToRefreshContent={
                      <h3 style={{ textAlign: 'center' , top : '50px'}}>&#8595; Pull down to refresh</h3>
                    }
                    releaseToRefreshContent={
                      <Loader />
                    }
                    >
                {this.state.req_salesorders.map((item,idx)=>(
                  <div className="single_so_container" key={idx}>
                    <div className="so_details_container">
                      <div>
                        <div className="so_customer_name"><i className="fa fa-user"></i> {item.name}</div>
                        <div className="so_order_date">Order date : {item.order_date}</div>
                        <div className="so_delivery_date">Delivery date : {item.delivery_date}</div>
                      </div>
                      <div className="so_approve_button_so_no"
                        style={{visibility:item.id?"visible":"hidden"}}
                      >
                         <i className="fas fa-hashtag"></i>{item.so_no}
                      </div>
                      <div></div>
                      
                      <div className="so_products_button">
                        <FiChevronDown className="arrow_down" />
                      </div>
                    </div>
                    <table className="so_products_container">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Product</th>
                          <th>Qty</th>
                          <th>Unit</th>
                          <th>Unit Price</th>
                          <th align="center">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                      {item.products.map((product,index) => (
                        <tr key={index}>
                          <td valign="top">{index+1}</td>
                          <td valign="top">{product.name}</td>
                          <td valign="top">{product.qty}</td>
                          <td valign="top">{product.unit}</td>
                          <td valign="top" align="right">{product.unit_price}</td>
                          <td align="right" valign="top">{product.total}</td>
                        </tr>
                      ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th colSpan="5" align="right">Total :</th>
                          <th align="right">{parseFloat(item.products.reduce((total, product) => total + parseFloat(product.total), 0)).toFixed(2)}</th>
                        </tr>
                        <tr style={{display:item.tax?"table-row":"none"}}>
                          <th colSpan="5" align="right">Tax : </th>
                          <th align="right" >{item.tax}</th>
                        </tr>
                        <tr style={{display:item.grand_total?"table-row":"none",fontSize : "12px",color : "#ea8000"}}>
                          <th colSpan="5" align="right">Grand Total (Rs) :</th>
                          <th align="right" >{item.grand_total}</th>
                        </tr>
                      </tfoot>
                    </table>
                    <div className="so_executive_name"
                        style={{display:item.staff_name && this.context.user_details.staff_id != item.marketing_exe_id?"block":"none",margin:"12px 0px"}}>
                         <i className="fas fa-user"></i> {item.staff_name}
                         
                    </div>

                    <div className="so_activity_container">
                        
                        <div className="so_approve_button"
                          salesorder_id = {item.id}
                          style={{display:this.context.user_details.staff_id == item.marketing_exe_id ?"block":"none"}}
                        >
                          <i className="fas fa-check"></i>
                        </div>
                        <div className="so_edit_button" 
                          salesorder_id={item.id}
                          style={{display:this.context.user_details.staff_id == item.marketing_exe_id?"block":"none"}}
                        >
                          <i className="fa fa-pencil-alt"></i>
                        </div>          
                       
                      </div>

                  </div>
                ))}
              </InfiniteScroll>

              </div>
              <div className="lists_container">
                {this.state.pend_salesorders.map((item,idx)=>(
                  <div className="single_so_container" key={idx}>
                    <div className="so_details_container">
                      <div>
                        <div className="so_customer_name"><i className="fa fa-user"></i> {item.name}</div>
                        <div className="so_order_date">Order date : {item.order_date}</div>
                        <div className="so_delivery_date">Delivery date : {item.delivery_date}</div>
                      </div>
                      <div className="so_approve_button_so_no"
                        style={{visibility:item.id?"visible":"hidden"}}
                      >
                         <i className="fas fa-hashtag"></i>{item.so_no}
                      </div>
                      <div></div>
                      
                      <div className="so_products_button">
                        <FiChevronDown className="arrow_down" />
                      </div>
                    </div>
                    <table className="so_products_container">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Product</th>
                          <th>Qty</th>
                          <th>Unit</th>
                          <th>Unit Price</th>
                          <th align="center">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                      {item.products.map((product,index) => (
                        <tr key={index}>
                          <td valign="top">{index+1}</td>
                          <td valign="top">{product.name}</td>
                          <td valign="top">{product.qty}</td>
                          <td valign="top">{product.unit}</td>
                          <td valign="top" align="right">{product.unit_price}</td>
                          <td align="right" valign="top">{product.total}</td>
                        </tr>
                      ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th colSpan="5" align="right">Total :</th>
                          <th align="right">{parseFloat(item.products.reduce((total, product) => total + parseFloat(product.total), 0)).toFixed(2)}</th>
                        </tr>
                        <tr style={{display:item.tax?"table-row":"none"}}>
                          <th colSpan="5" align="right">Tax : </th>
                          <th align="right" >{item.tax}</th>
                        </tr>
                        <tr style={{display:item.grand_total?"table-row":"none",fontSize : "12px",color : "#ea8000"}}>
                          <th colSpan="5" align="right">Grand Total (Rs) :</th>
                          <th align="right" >{item.grand_total}</th>
                        </tr>
                      </tfoot>
                    </table>

                  </div>
                ))}
              </div>
              <div className="lists_container">
                {this.state.so_confirmed_salesorders.map((item,idx)=>(
                  <div className="single_so_container" key={idx}>
                    <div className="so_details_container">
                      <div>
                        <div className="so_customer_name"><i className="fa fa-user"></i> {item.name}</div>
                        <div className="so_order_date">Order date : {item.order_date}</div>
                        <div className="so_delivery_date">Delivery date : {item.delivery_date}</div>
                      </div>
                      <div className="so_approve_button_so_no"
                        style={{visibility:item.id?"visible":"hidden"}}
                      >
                         <i className="fas fa-hashtag"></i>{item.so_no}
                      </div>
                      <div></div>
                      
                      <div className="so_products_button">
                        <FiChevronDown className="arrow_down" />
                      </div>
                    </div>
                    <table className="so_products_container">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Product</th>
                          <th>Qty</th>
                          <th>Unit</th>
                          <th>Unit Price</th>
                          <th align="center">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                      {item.products.map((product,index) => (
                        <tr key={index}>
                          <td valign="top">{index+1}</td>
                          <td valign="top">{product.name}</td>
                          <td valign="top">{product.qty}</td>
                          <td valign="top">{product.unit}</td>
                          <td valign="top" align="right">{product.unit_price}</td>
                          <td align="right" valign="top">{product.total}</td>
                        </tr>
                      ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th colSpan="5" align="right">Total :</th>
                          <th align="right">{parseFloat(item.products.reduce((total, product) => total + parseFloat(product.total), 0)).toFixed(2)}</th>
                        </tr>
                        <tr style={{display:item.tax?"table-row":"none"}}>
                          <th colSpan="5" align="right">Tax : </th>
                          <th align="right" >{item.tax}</th>
                        </tr>
                        <tr style={{display:item.grand_total?"table-row":"none",fontSize : "12px",color : "#ea8000"}}>
                          <th colSpan="5" align="right">Grand Total (Rs) :</th>
                          <th align="right" >{item.grand_total}</th>
                        </tr>
                      </tfoot>
                    </table>

                  </div>
                ))}
              </div>
              <div className="lists_container">
                {this.state.placed_salesorders.map((item,idx)=>(
                  <div className="single_so_container" key={idx}>
                    <div className="so_details_container">
                      <div>
                        <div className="so_customer_name"><i className="fa fa-user"></i> {item.name}</div>
                        <div className="so_order_date">Order date : {item.order_date}</div>
                        <div className="so_delivery_date">Delivery date : {item.delivery_date}</div>
                      </div>
                      <div className="so_approve_button_so_no"
                        style={{visibility:item.id?"visible":"hidden"}}
                      >
                         <i className="fas fa-hashtag"></i>{item.so_no}
                         <div>
                          <span style={{visibility:item.trip_details?"visible":"hidden"}}>Courier  : {item.trip_details?item.trip_details['courier_name']:''}</span><br></br>
                          <span style={{visibility:item.trip_details?"visible":"hidden"}}>Courier No.: {item.trip_details?item.trip_details['courier_no']:''}</span>
                         </div>

                      </div>
                      
                      
                      <div className="so_products_button">
                        <FiChevronDown className="arrow_down" />
                      </div>
                    </div>
                    <table className="so_products_container">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Product</th>
                          <th>Qty</th>
                          <th>Unit</th>
                          <th>Unit Price</th>
                          <th align="center">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                      {item.products.map((product,index) => (
                        <tr key={index}>
                          <td valign="top">{index+1}</td>
                          <td valign="top">{product.name}</td>
                          <td valign="top">{product.qty}</td>
                          <td valign="top">{product.unit}</td>
                          <td valign="top" align="right">{product.unit_price}</td>
                          <td align="right" valign="top">{product.total}</td>
                        </tr>
                      ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th colSpan="5" align="right">Total :</th>
                          <th align="right">{parseFloat(item.products.reduce((total, product) => total + parseFloat(product.total), 0)).toFixed(2)}</th>
                        </tr>
                        <tr style={{display:item.tax?"table-row":"none"}}>
                          <th colSpan="5" align="right">Tax : </th>
                          <th align="right">{item.tax}</th>
                        </tr>
                        <tr style={{display:item.grand_total?"table-row":"none",fontSize : "12px",color : "#ea8000"}}>
                          <th colSpan="5" align="right">Grand Total (Rs) :</th>
                          <th align="right" >{item.grand_total}</th>
                        </tr>
                      </tfoot>
                    </table>

                  </div>
                ))}
              </div>

            </div>
          </div>
          <div className="bottom_navbar_spacer"></div>

        </div>
      )}
    </MyContext.Consumer>
  )}
}

export default SalesOrder;
SalesOrder.contextType = MyContext;