import React, { Component } from "react";
import {Link} from "react-router-dom";
import Select from "react-select";
import axios from 'axios';
import DatePicker from 'react-date-picker';



import './LeaveApproval.scss';
import MyContext from '../MyContext.jsx';

var customer_XHR={'abort' : ()=>{}};



class LeaveApproval extends Component {

    constructor(props) {
      super(props);
      this.state = {
        leavelist:[{
          leave_id:'',
          from_date:'',
          to_date:'',
          no_of_days:'',
          reason:'',
          leave_type:'',
          status:'',
        }],
        executives  : {},
        executive   : '',
      }
    }

    componentDidMount(){  
      var obj_this = this;
      const url = `${process.env.REACT_APP_SSS}/api/getLeaveRequestDetails`;
      const data = {
        sid : this.context.user_details.sid
      };  
      var result='';
      $.ajax({
        url : url,
        data : data,
        type : "POST"
      }).done(function(return_data){
        // console.log(return_data);
        if (return_data.status == "success"){
          obj_this.setState({
            leavelist : return_data.leave,
            executives  : return_data.executives,
          });
          obj_this.context.loading(0);
        }else{
          obj_this.context.loading(0);
          obj_this.props.history.push("/");
        }
      }).fail(function(){
        obj_this.context.loading(0);
        alert("Network error");
      });
      $(".leave_approval_container").on("click",".leave_approve_button",function(e){
        e.stopPropagation();
        if(!confirm("Do you want to approve this leave?")){
          return;
        }
        const leave_id = $(this).attr("leave_req_id");
        obj_this.setState({
          leave_id : $(this).attr("leave_req_id")
        });
        const url = `${process.env.REACT_APP_SSS}/api/leaveRequestApproval`;
        obj_this.context.loading(1);
        $.ajax({
          url : url,
          data : {
            sid : obj_this.context.user_details.sid,
            leave_id : leave_id,
          },
          type : "POST"
        }).done(function(return_data){
            // console.log(return_data);
          if(return_data.status == "success"){
            //obj_this.setState({leavelist : return_data.leave});
            obj_this.afterExecutiveChange();
            obj_this.context.loading(0);
            alert("Leave Approved.")
          }else{
            obj_this.context.loading(0);
            obj_this.props.history.replace("/");
          }
        }).fail(function(){
          obj_this.context.loading(0);
          alert("Network error");
        });
    });
  
    $(".leave_approval_container").on("click",".leave_cancel_button",function(e){
      e.stopPropagation();
      if(!confirm("Do you want to reject this leave?")){
        return;
      }
      const leave_id = $(this).attr("leave_req_id");
      obj_this.setState({
        leave_id : $(this).attr("leave_req_id")
      });
      const url = `${process.env.REACT_APP_SSS}/api/leaveRequestReject`;
      obj_this.context.loading(1);
      $.ajax({
        url : url,
        data : {
          sid : obj_this.context.user_details.sid,
          leave_id : leave_id,
        },
        type : "POST"
      }).done(function(return_data){
          // console.log(return_data);
        if(return_data.status == "success"){
          //obj_this.setState({leavelist : return_data.leave});
          obj_this.afterExecutiveChange();
          obj_this.context.loading(0);
          alert("Leave Rejected.")
        }else{
          obj_this.context.loading(0);
          obj_this.props.history.replace("/");
        }
      }).fail(function(){
        obj_this.context.loading(0);
        alert("Network error");
      });
  });
  $(".leave_approval_container").on("click",".leave_approve_all_button",function(e){
    e.stopPropagation();
    if(!confirm("Do you want to approve all leave requests?")){
      return;
    }
    let leave_ids=null;
    let requested_leaves = obj_this.state.leavelist.filter((item) => item.status == "REQ");
    leave_ids=requested_leaves.map(item=>item.leave_id);
    const url = `${process.env.REACT_APP_SSS}/api/approveAll/allLeaveRequestApproval`;
    obj_this.context.loading(1);
    $.ajax({
      url : url,
      data : {
        sid : obj_this.context.user_details.sid,
        leave_ids : leave_ids,
      },
      type : "POST"
    }).done(function(return_data){
        // console.log(return_data);
      if(return_data.status == "success"){
        //obj_this.setState({leavelist : return_data.leave});
        obj_this.afterExecutiveChange();
        obj_this.context.loading(0);
        alert("Leave Requests Approved.")
      }else if(return_data.status == "fail"){
        obj_this.context.loading(0);
        alert("No Leave Requests to Approve");
      }
      else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      obj_this.context.loading(0);
      alert("Network error");
    });
  });
  $(".leave_approval_container").on("click",".leave_cancel_all_button",function(e){
    e.stopPropagation();
    if(!confirm("Do you want to reject all leave requests?")){
      return;
    }
    let leave_ids=null;
    let requested_leaves = obj_this.state.leavelist.filter((item) => item.status == "REQ");
    leave_ids=requested_leaves.map(item=>item.leave_id);
    const url = `${process.env.REACT_APP_SSS}/api/rejectAll/allLeaveRequestCancel`;
    obj_this.context.loading(1);
    $.ajax({
      url : url,
      data : {
        sid : obj_this.context.user_details.sid,
        leave_ids : leave_ids,
      },
      type : "POST"
    }).done(function(return_data){
        // console.log(return_data);
      if(return_data.status == "success"){
        //obj_this.setState({leavelist : return_data.leave});
        obj_this.afterExecutiveChange();
        obj_this.context.loading(0);
        alert("Leave Requests Cancelled.")
      }else if(return_data.status == "fail"){
        obj_this.context.loading(0);
        alert("No Leave Requests to Cancel");
      }
      else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      obj_this.context.loading(0);
      alert("Network error");
    });
  });


    }

    executiveChange(value){
      this.setState({
        executive : value
      }, () => {
        this.afterExecutiveChange();
    });
    }
    
    afterExecutiveChange(){
      var obj_this = this;
        const url = `${process.env.REACT_APP_SSS}/api/getLeaveRequestDetails`;
        this.context.loading(1);
        $.ajax({
          url : url,
          data : {
            sid : this.context.user_details.sid,
            executive_id  : obj_this.state.executive.value
          },
          type : "POST"
        }).done(function(return_data){
          //console.log(return_data);
          if(return_data.status == "success"){ 
            
            obj_this.setState({
              leavelist : return_data.leave,
              executives  : return_data.executives,
            });
    
            obj_this.context.loading(0);
            //console.log(obj_this.state);
          }else{
            obj_this.context.loading(0);
            obj_this.props.history.replace("/");
          }
        }).fail(function(){
          obj_this.context.loading(0);
          alert("Network error");
        });
    
    
    }

  
  render(){

  return (

        <div className="leave_approval_container">
         <div className="header_spacer"></div>
          <div className="travel_allowances_container">
            <div className="page_header">
              <div className="page_title">Leave Approval</div>
              <Select 
              options = {this.state.executives}
              value = {this.state.executive}
              onChange={value=>this.executiveChange(value)}
              />
            </div>
            <div className="current_month">{new Date().toLocaleString('default', { month: 'long' })} { new Date().getFullYear()}</div>
            
            <div className="list_blocks_container">
                <div className="lists_container">
                <div className="approve_all_cancel_all_leave">
                    <div className="leave_approve_all_button"
                      >
                        <i className="fas fa-check"><span>APPROVE ALL</span></i>
                    </div>
                    <div className="leave_cancel_all_button"
                      >
                        <i className="fa fa-times-circle"><span>REJECT ALL</span></i>
                    </div>
                </div>

                {this.state.leavelist.map((item,idx)=>(
                    <div className="single_collection_container" key={idx}>
                        <div className="collection_customer_name_delete_button_container">
                            <div className="executive_name">{item.staffname}</div>
                            <div className="leave2_details_status_button">
                                <div className="collection_customer_name"># Days : {item.no_of_days}</div>
                                <div className="leave_status_requested" style={{display:item.status=="REQ"?"block":"none"}}><i className="fa fa-clock" aria-hidden="true"></i> REQUESTED</div>
                                <div className="leave_status_approved" style={{display:item.status=="APR"?"block":"none"}}> <i className="fa fa-check" aria-hidden="true"></i>APPROVED</div>
                                <div className="leave_status_rejected" style={{display:item.status=="CNL"?"block":"none"}}> <i className="fa fa-times" aria-hidden="true"></i>REJECTED</div>
                            </div>
                            <div className="leave_details_status_button_container">
                                <div className="leave_date"><i className="fa fa-calendar-alt"></i> {new Date(item.from_date).toLocaleDateString('en-GB')}</div>
                                <div className="divider">---</div>
                                <div className="leave_date"><i className="fa fa-calendar-alt"></i> {new Date(item.to_date).toLocaleDateString('en-GB')}</div>
                            </div>
                            <div className="leave_type">Leave Type : {item.leave_type}</div>
                            <div className="leave2_reason">Reason : {item.reason}</div>
                        </div>
                        <div className="approve_cancel_leave">
                            <div className="leave_approve_button"
                                    leave_req_id = {item.leave_id}
                                    style={{display:item.status=="REQ"?"block":"none"}}
                                >
                                <span className="">APPROVE</span>
                            </div>
                            <div className="leave_cancel_button"
                                    leave_req_id = {item.leave_id}
                                    style={{display:item.status=="REQ"?"block":"none"}}
                                >
                                {/* <i className="fa fa-times-circle"></i> */}
                                <span className="">REJECT</span>
                            </div>
                        </div>
                    </div>

                ))}
                </div>
            </div>             
         </div>
        </div>
      )}
   
}

export default LeaveApproval;
LeaveApproval.contextType = MyContext;
