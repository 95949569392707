
import React, { Component } from "react";
import './InDev.scss';

class DevTools extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  onclick(){
    alert(window.location.pathname);
  }
   
render(){

  return (
      <div className="in_dev">
        {/* <img src={window.project_path+"/images/3gear.gif"} className="gear" /><br /> */}
        Will be availiable soon...
      </div>
  )}
}

export default DevTools;
