import React , {Component} from "react";
import MyContext from '../MyContext.jsx';
import {Link} from "react-router-dom";
import Select from "react-select";
import DatePicker from 'react-date-picker';

import './DistributorSalesReport.scss';

class DistributorSalesReport extends Component{
  constructor(props){
    super(props);
    const { begin, end } = this.calculateFinancialYear();

    this.state = {
      customers    :   [],
      customer :   {},
      districts    :   [],
      district :   {},
      cost_centers : [],
      from_date : begin,
      to_date : end,
    }
    this.customerChange = this.customerChange.bind(this);
    this.districtChange = this.districtChange.bind(this);
    this.formSubmit = this.formSubmit.bind(this);


  }
  componentDidMount(){
    var obj_this = this;
    const url = `${process.env.REACT_APP_SSS}/api/Reports/getDistributorSalesSelect`;
    this.context.loading(1);
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      if(return_data.status == "success"){
        obj_this.setState({
        districts : return_data.districts,
         customers : return_data.customers,
        });
        // obj_this.context.loading(0);
      }else{
        // obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
        // obj_this.context.loading(0);
        alert("Network error");
    });
    obj_this.getDistributorSaleReport();
    ui.swipeMenu(".distributor_sales_page_container");

  }
   calculateFinancialYear() {
    const today = new Date();
    const currentMonth = today.getMonth() + 1;
    const currentYear = today.getFullYear();
  
    let begin, end;
  
    if (currentMonth >= 4) {
      begin = new Date(currentYear, 3, 1); 
      end = today; 
    } else {
      begin = new Date(currentYear - 1, 3, 1); 
      end = today; 
    }
  
    return { begin, end };
  }
  customerChange(option){
    this.setState({
      customer : option
    });
  } 
  districtChange(option){
    this.setState({
      district : option
    });
  } 
  getDistributorSaleReport(){
    var obj_this = this;
    const url2 = `${process.env.REACT_APP_SSS}/api/Reports/getDistributorDetails`;
    this.context.loading(1);
    $.ajax({
      url : url2,
      data : {
        sid : this.context.user_details.sid,
        district : this.state.district?.value,
        customer : this.state.customer?.value,
        from_date : this.state.from_date.toLocaleDateString('en-CA'),
        to_date : this.state.to_date.toLocaleDateString('en-CA')
      },
      type : "POST"
    }).done(function(return_data){
      var cost_centers = return_data.cost_centers.filter((item) => ( item.dealers.length));
      if(return_data.status == "success"){
        obj_this.setState({
          cost_centers : cost_centers,
          grand_free_qty : return_data.grand_free_qty,
          grand_tot_sale : return_data.grand_tot_sale,
          grand_ttl_return : return_data.grand_ttl_return,
          grand_net_sale : return_data.grand_net_sale,
        });
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      alert("Network error");
    });
  }
  formSubmit(e){
    e.preventDefault();
    $(".distributor_sales_table tr").css("backgroud-color","");
    var obj_this = this;
    obj_this.getDistributorSaleReport();
  }

  fromDateChange(value){
    this.setState({from_date : value});
  }
  toDateChange(value){
    this.setState({to_date : value});
  }

  render(){
    return(
      <MyContext.Consumer>
        {context => (
        <div className="distributor_sales_page_container uijs swipemenu">
          <div className="page_header">
            <Link className="back_button" to="/dashboard"><i className="fas fa-arrow-left"></i></Link>
            <div className="page_title">Distributor sales Report</div>
          </div> 
          <form className="customer_filter_form" onSubmit={this.formSubmit}>
            <div className="blocks">
            <div className="input_grid">
                <div className="form_input">
                  <div className="from_to_label">
                    <div className="input_label">From Date*</div>
                    <DatePicker
                      className="input custom_input"
                      onChange={(value) => this.fromDateChange(value)}
                      value={this.state.from_date}
                      clearIcon={null}
                      required
                      format="dd-MM-y"
                    />
                    <div className="input_label">To Date*</div>
                    <DatePicker
                      className="input custom_input"
                      onChange={(value) => this.toDateChange(value)}
                      value={this.state.to_date}
                      minDate = {new Date(this.state.from_date)}
                      clearIcon={null}
                      required
                      format="dd-MM-y"
                    />
                  </div>
                 
                  <div className="input_label">District</div>
                  <Select 
                    options={this.state.districts}  
                    value ={this.state.district}
                    className="customer" 
                    onChange={e => this.districtChange(e)}
                  />
                  <div className="input_label">Customer</div>
                  <Select 
                    options={this.state.customers}  
                    value ={this.state.customer}
                    className="customer" 
                    onChange={e => this.customerChange(e)}
                  />
                  <div className="submit_button_container">
                    <button className="submit_button">Submit</button>
                  </div>
                </div>
                
              </div>
            </div>
          </form>
          <div className="toggles_container">
            <div className="list_3 toggles" menu="0">
              <i className="fa fa-credit-card order_status_icon"></i>
              <div className="order_status">Cost Center Distributor Sales</div>
              {/* <div className="order_count">{this.state.so_confirmed_salesorders.length}</div> */}
            </div>
            <div className="list_4 toggles" menu="1">
              <i className="fa fa-list-alt order_status_icon"></i>
              <div className="order_status">Detailed Sales</div>
              {/* <div className="order_count">{this.state.placed_salesorders.length}</div> */}
            </div>
          </div>
          <div className="list_blocks_container">
              <div className="lists_container">
              <table className="cost_center_outstandings_table">
                    <thead>
                      <tr>
                        <th style={{maxWidth:"40px"}} align="center">#</th>
                        <th align="center">Cost Center</th>
                        <th align="center">Free Qty</th>
                        <th align="center">Sale</th>
                        <th align="center">Sale Return</th>
                        <th align="center">Net Sale</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr style={{"display":this.state.cost_centers.length?"none":"table-row"}}>
                      <td colSpan="6" align="center">No result!</td>
                    </tr>
                    {this.state.cost_centers && this.state.cost_centers.map((costCenter, costCenterIndex) => (
                      <tr key={costCenterIndex} >
                        
                        <td valign="top" align="center">{costCenterIndex+1}</td>
                        <td valign="top" style={{padding:"5px 0px"}}>
                          <span style={{fontWeight:"500",paddingLeft:"5px"}}>{costCenter.district}</span>
                      
                        </td>

                        <td  align="right">
                        {costCenter.center_free_qty}  <span style={{marginLeft:"2px", marginRight:"5px"}}></span>
                        </td>
                        <td  align="right">
                        {costCenter.center_tot_sale}  <span style={{marginLeft:"2px"}}><i className="fas fa-rupee-sign"></i></span>
                        </td>
                        <td  align="right">
                        {costCenter.center_ttl_return}  <span style={{marginLeft:"2px"}}><i className="fas fa-rupee-sign"></i></span>
                        </td>
                        <td  align="right">
                        {costCenter.center_net_sale}  <span style={{marginLeft:"2px"}}><i className="fas fa-rupee-sign"></i></span>
                        </td>
                      </tr>    
                    ))}
                    <tr className="finalTotal">
                      <td colSpan={2} style={{ textAlign: 'center' }}>
                        <b>Total</b>
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        {this.state.grand_free_qty}<span style={{marginLeft:"2px"}}><i className="fas fa-rupee-sign"></i></span>
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        {this.state.grand_tot_sale}<span style={{marginLeft:"2px"}}><i className="fas fa-rupee-sign"></i></span>
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        {this.state.grand_ttl_return}<span style={{marginLeft:"2px"}}><i className="fas fa-rupee-sign"></i></span>
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        {this.state.grand_net_sale}<span style={{marginLeft:"2px"}}><i className="fas fa-rupee-sign"></i></span>
                      </td>
                    </tr>
                    </tbody>
                  
                  </table>

              </div>
              <div className="lists_container">
                <div className="table_container">
                  <table className="distributor_sales_table">
                    <thead>
                      <tr>
                        <th style={{maxWidth:"40px"}} align="center">#</th>
                        <th>Cost Center</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr style={{"display":this.state.cost_centers.length?"none":"table-row"}}>
                      <td colSpan="4" align="center">No result!</td>
                    </tr>
                    {this.state.cost_centers && this.state.cost_centers.map((costCenter, costCenterIndex) => (
                          <tr key={costCenterIndex} >
                            
                            <td valign="top" align="center">{costCenterIndex+1}</td>
                            <td valign="top" style={{padding:"5px 0px"}}>
                              <span style={{fontWeight:"500",paddingLeft:"5px"}}>{costCenter.district}</span>
                          
                              <tr style={{"display": "inline"}}>
                              <div className="bills_table_container">
                                <table className="bills_table"  >
                                  <tr>
                                    <th style={{minWidth:'30px', textAlign:'center'}}>#</th>
                                    <th style={{minWidth:'100%', textAlign:'center'}}>Customer</th>
                                  </tr>


                                { costCenter.dealers.map((customer, customerIndex) => (
                                  
                                  <React.Fragment key={customerIndex}> 
                                    <tr>
                                          <td style={{textAlign:'center'}}>
                                            {customerIndex+1}
                                          </td>
                                          <td>
                                            {customer.name}
                                          </td>
                                      </tr>
                                      <tr style={{display: customer.sales ?"table-row":"none"}}>
                                        <td></td>
                                        <td>
                                          <table className="bill_row_cell" style={{display: customer.sales ?"table":"none"}}>
                                          <thead>
                                            <tr>
                                              <th style={{textAlign:"right"}}>Free Qty Total</th>
                                              <th style={{textAlign:"right"}}>Total Sale</th>
                                              <th style={{textAlign:"right"}}>Total Sales Return</th>
                                              <th style={{textAlign:"right"}}>Net Sale</th>

                                            </tr>
                                          </thead>
                                          <tr>
                                              <td style={{textAlign:"right"}}>{customer?.sales?.free?customer?.sales?.free:0}</td>
                                              <td style={{textAlign:"right"}}>{customer?.sales?.tot_sale?customer?.sales?.tot_sale:0}</td>
                                              <td style={{textAlign:"right"}}>{customer?.sales?.tot_return?customer?.sales?.tot_return:0}</td>
                                              <td style={{textAlign:"right"}}>{customer?.sales?.net_sale?customer?.sales?.net_sale:0}</td>
                                          </tr>
                                          </table>
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                      )) 
                                }
                                <tr style={{"display":costCenter.dealers.length?"none":"table-row"}}>
                                  <td colSpan="4" align="center">No Distributor Sales!</td>
                                </tr>
                              
                                <tr className="dealerTotal">
                                
                                  <td colSpan={2}>
                                  {costCenter.district} <br></br>
                                  Total Free Qty : {costCenter.center_free_qty}  <span style={{marginLeft:"2px", marginRight:"5px"}}></span> <br></br>

                                  Total Sale :  {costCenter.center_tot_sale}  <span style={{marginLeft:"2px"}}><i className="fas fa-rupee-sign"></i></span> <br></br>

                                  Total Sale Return : {costCenter.center_ttl_return} <span style={{marginLeft:"2px"}}><i className="fas fa-rupee-sign"></i></span> <br></br>

                                  Total Net Sale :  {costCenter.center_net_sale}  <span style={{marginLeft:"2px"}}><i className="fas fa-rupee-sign"></i></span> <br></br>

                                  </td>
                                </tr>
                                </table>
                              </div>
                            </tr>
                            </td>
                          </tr>
                            
                        ))}

                    {this.state.cost_centers.length?
                    <tr>
                      {/* <td style={{textAlign:"right"}}><b>Total</b></td> */}
                      <td style={{padding:'0px'}} colSpan={2}>
                        <div className="bills_table_container" style={{display:this.state.cost_centers.length?"block":"none"}}>
                          <table className="bills_table">
                            <thead>
                              <tr>
                                <th style={{textAlign:"center"}}>Free Qty Total</th>
                                <th style={{textAlign:"center"}}>Total Sale</th>
                                <th style={{textAlign:"center"}}>Total Sales Return</th>
                                <th style={{textAlign:"center"}}>Net Sale</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td style={{textAlign:"right" ,minWidth:"60px", fontSize: '12px'}}><b>{this.state.grand_free_qty}</b></td>
                                <td style={{textAlign:"right" ,minWidth:"45px", fontSize: '12px'}}><b>{this.state.grand_tot_sale} <i className="fas fa-rupee-sign"></i></b></td>
                                <td style={{textAlign:"right" ,minWidth:"60px", fontSize: '12px'}}><b>{this.state.grand_ttl_return} <i className="fas fa-rupee-sign"></i></b></td>
                                <td style={{textAlign:"right" ,minWidth:"45px", fontSize: '12px'}}><b>{this.state.grand_net_sale} <i className="fas fa-rupee-sign"></i></b></td>
                              </tr>
                            </tbody>
                            <tfoot>
                            
                            </tfoot>
                            
                          </table>
                        </div>
                      </td>
                    </tr>
                    :null}
                    </tbody>
                  
                  </table>
                </div>
            </div>
          </div>
          
        </div>

      )}
    </MyContext.Consumer>
  )}
}

export default DistributorSalesReport;
DistributorSalesReport.contextType = MyContext;
