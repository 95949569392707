import React , {Component} from "react";
import MyContext from '../MyContext.jsx';
import {Link} from "react-router-dom";
import { CgSearch } from 'react-icons/cg';
import Select from "react-select";

// import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";

import './SecondarySale.scss';
import { isThisISOWeek } from "date-fns/esm";

class SecondarySale extends Component{
  constructor(props){
    super(props);
    // this.districtSelect = React.createRef();
    this.state = {
      cost_centers : [],
      parent_dealer:'',
      grand_total:'',
      secondary_sale_sum : '',
      district:'',
      executive:'',
      monthYear:new Date(),
      districts:[],
      executives:[]
    }
    this.districtChange = this.districtChange.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
   
  }
  componentDidMount(){
    var obj_this = this;
    const url = `${process.env.REACT_APP_SSS}/api/getOutstandingDistricts`;
    this.context.loading(1);
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      if(return_data.status == "success"){
        obj_this.setState({
         districts: return_data.districts,
         executives :  return_data.executives
        });
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      alert("Network error");
      obj_this.context.loading(0);

    });
    obj_this.getDesignations();

    obj_this.getSecondarySale();
    ui.swipeMenu(".secondary_sale_page_container");

  }
  
  districtChange(option){
    this.setState({
      district : option
    });
  }
  executiveChange(option){
    this.setState({
      executive : option
    });
  }
  setMonthYear(option){
    this.setState({
      monthYear :option
    })
  }
  getDesignations(){
    var obj_this = this;
    const url = `${process.env.REACT_APP_SSS}/api/getDesignations`;
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      if(return_data.status == "success"){
        obj_this.setState({
         designations : return_data.designations,
        });
      }else{
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
        alert("Network error");
    });
  }
  designationChange(option){
    this.setState({
      designation : option,
      executive : ''
    },()=>{
      this.getExecutives();
    });
  }
  getExecutives(){
    var obj_this = this;
    if(!obj_this.state.designation?.label)
      return;

    const url = `${process.env.REACT_APP_SSS}/api/getDesignationWiseExecutives`;
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid,
        designation_id : obj_this.state.designation.value
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      if(return_data.status == "success"){
        obj_this.setState({
         executives : return_data.executives,
        });
      }else{
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
        alert("Network error");
    });
  }

  getSecondarySale(){
    var obj_this = this;
    // console.log(this.state.monthYear.toLocaleDateString('en-GB'))
    // return this.state;
    const url = `${process.env.REACT_APP_SSS}/api/getSecondarySaleReportTotal`;
    this.context.loading(1);
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid,
        district_id : this.state.district.value,
        exe_staff_id  : this.state.executive.value,
        selected_month  : this.state.monthYear.toLocaleDateString('en-US')  
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      if(return_data.status == "success"){
        // var dealers = return_data.dealers;
        // var cost_centers = return_data.cost_centers;
        var cost_centers = return_data.cost_centers.filter((item) => ( item.dealers.length));
        cost_centers = cost_centers.map((costCenter) => ({
          ...costCenter,
          dealers: costCenter.dealers.filter((customer) => customer.secondary_sale_collection.length > 0),
        }));
        obj_this.setState({
          cost_centers : cost_centers,
          parent_dealer  : return_data.direct_sale_parent,
          secondary_sale_sum : return_data.secondary_sale_sum,
          grand_total  : return_data.total_secondary_sale
         });
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      alert("Network error");
      obj_this.context.loading(0);
    });
  }

  formSubmit(e){
    e.preventDefault();
    $(".customer_outstandings_table tr").css("backgroud-color","");

    var obj_this = this;

    obj_this.getSecondarySale();
    
  }






  render(){
    return(
      <MyContext.Consumer>
        {context => (
        <div className="secondary_sale_page_container uijs swipemenu">
          <div className="page_header">
            <Link className="back_button" to="/dashboard"><i className="fas fa-arrow-left"></i></Link>
            <div className="page_title">Secondary Sale Log</div>
            <Link className="new_sec_sales_order_button" to="/secondarysale/new">
              <i className="fa fa-plus plus_icon"></i> New
            </Link>
          </div> 
          <form className="executive_district_form" onSubmit={this.formSubmit} 
          >
            <div className="blocks">
              <div className="input_grid">
                <div className="dist_exe" style={{display:this.context.user_details.designation=='ASM'?"block":this.context.user_details.designation=='RSM'?"block":this.context.user_details.designation=='ADMIN'?"block":"none"}}>
                  <div className="input_label">District</div>
                  <Select 
                    options={this.state.districts}  
                    value ={this.state.district}
                    className="district" 
                    // ref={this.districtSelect}
                    onChange={e => this.districtChange(e)}
                    required
                  />
                  <div className="input_label">Designation</div>
                  <Select 
                    options={this.state.designations}  
                    value ={this.state.designation}
                    className="executive" 
                    // ref={this.exeSelect}
                    onChange={e => this.designationChange(e)}
                    isClearable={true}
                    required
                  />
                  <div className="input_label">Executive</div>
                  <Select 
                    options={this.state.executives}  
                    value ={this.state.executive}
                    className="executive" 
                    // ref={this.exeSelect}
                    onChange={e => this.executiveChange(e)}
                    required
                  />
                
                </div>
                <div className="input_label">Month</div>
                  <DatePicker
                  selected={this.state.monthYear}
                  // value = {this.monthYear}
                  onChange={(date) => this.setMonthYear(date)}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  />
               
                <div className="submit_button_container">
                  <button className="submit_button">Submit</button>
                </div>
                </div>
                
            </div>
          </form>
          <div className="toggles_container">
            <div className="list_3 toggles" menu="0">
              <i className="fa fa-credit-card order_status_icon"></i>
              <div className="order_status">Cost Center Secondary Sale</div>
              {/* <div className="order_count">{this.state.so_confirmed_salesorders.length}</div> */}
            </div>
            <div className="list_4 toggles" menu="1">
              <i className="fa fa-list-alt order_status_icon"></i>
              <div className="order_status">Detailed  Secondary Sale</div>
              {/* <div className="order_count">{this.state.placed_salesorders.length}</div> */}
            </div>
          </div>
          <div className="list_blocks_container">
            <div className="lists_container">
              <table className="cost_center_outstandings_table">
                <thead>
                  <tr>
                    <th style={{maxWidth:"40px"}} align="center">#</th>
                    <th align="center">Cost Center</th>
                    <th align="center">Direct Sale</th>
                    <th align="center">Secondary Sale</th>
                    <th align="center">Total</th>

                  </tr>
                </thead>
                <tbody>
                <tr style={{"display":this.state.cost_centers.length?"none":"table-row"}}>
                  <td colSpan="2" align="center">No result!</td>
                </tr>
                {this.state.cost_centers && this.state.cost_centers.map((costCenter, costCenterIndex) => (
                  <tr key={costCenterIndex} >

                    <td valign="top" align="center">{costCenterIndex + 1}</td>
                    <td valign="top" style={{ padding: "5px 0px" }}>
                      <span style={{ fontWeight: "500", paddingLeft: "5px" }}>{costCenter.district}</span>
                    </td>

                    <td align="right">
                      {costCenter.direct_sale}  <span style={{ marginLeft: "2px", marginRight: "5px" }}><i className="fas fa-rupee-sign"></i></span>
                    </td>
                    <td align="right">
                      {costCenter.sum_secondary_sale}  <span style={{ marginLeft: "2px" }}><i className="fas fa-rupee-sign"></i></span>
                    </td>
                    <td align="right">
                      {costCenter.sum_secondary_sale + costCenter.direct_sale}  <span style={{ marginLeft: "2px" }}><i className="fas fa-rupee-sign"></i></span>
                    </td>
                  </tr>
                ))}
                <tr className="finalTotal">
                  <td colSpan={2} style={{ textAlign: 'center' }}>
                    <b>Total</b>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <span><b>{this.state.parent_dealer?.direct_sale}</b> <i className="fas fa-rupee-sign"></i></span>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <span><b>{this.state.secondary_sale_sum}</b> <i className="fas fa-rupee-sign"></i></span>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <span><b>{this.state.grand_total}</b> <i className="fas fa-rupee-sign"></i></span>
                  </td>
                </tr>
                
                </tbody>     
              </table>
            </div>
            <div className="lists_container">
              <div className="table_container">
                <table className="customer_outstandings_table">
                  <thead>
                    <tr>
                      <th style={{maxWidth:"40px"}} align="center">#</th>
                      <th>Cost Center</th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr style={{"display":this.state.cost_centers.length?"none":"table-row"}}>
                    <td colSpan="4" align="center">No result!</td>
                  </tr>
                  {this.state.cost_centers && this.state.cost_centers.map((costCenter, costCenterIndex) => (
                    <tr key={costCenterIndex} >
                      
                      <td valign="top" align="center">{costCenterIndex+1}</td>
                      <td valign="top" style={{padding:"5px 0px"}}>
                        <span style={{fontWeight:"500",paddingLeft:"5px"}}>{costCenter.district}</span>
                    
                        <tr style={{"display": "inline"}}>
                        <div className="bills_table_container">
                          <table className="bills_table"  >
                            <tr>
                              <th style={{minWidth:'30px', textAlign:'center'}}>#</th>
                              <th style={{minWidth:'100%', textAlign:'center'}}>Customer</th>
                            </tr>


                          { costCenter.dealers.map((customer, customerIndex) => (
                            
                            <React.Fragment key={customerIndex}> 
                              <tr>
                                    <td style={{textAlign:'center'}}>
                                      {customerIndex+1}
                                    </td>
                                    <td>
                                      {customer.name}
                                    </td>
                                </tr>
                                <tr style={{display: customer.secondary_sale_collection.length>0 ?"table-row":"none"}}>
                                  <td></td>
                                  <td>
                                    <table className="bill_row_cell" style={{display: customer.secondary_sale_collection.length>0 ?"table":"none"}}>
                                    <thead>
                                      <tr>
                                        <th>Date</th>
                                        <th>Amount</th>
                                      </tr>
                                    </thead>
                                    
                                  { customer.secondary_sale_collection.map((bill, billIndex) => (
                                      <tr key={billIndex}>
                                        <td style={{textAlign:'center'}}>{new Date(bill.date).toLocaleDateString('en-GB')}</td>
                                        <td align="right" style={{textAlign:"center"}}>
                                          <span style={{alignSelf:"center"}}>{bill.amount}</span>
                                          <span style={{marginLeft:"3px"}}><i className="fas fa-rupee-sign"></i></span>
                                        </td>
                                      </tr>
                                  ))}
                                </table>
                                  </td>
                                </tr>
                              </React.Fragment>
                                )) 
                          }
                          <tr style={{"display":costCenter.dealers.length?"none":"table-row"}}>
                            <td colSpan="4" align="center">No Secondary Sale!</td>
                          </tr>
                        
                          <tr className="dealerTotal">
                          
                            <td colSpan={2}>
                            {costCenter.district} <br></br>
                            Secondary Sale Total : {costCenter.sum_secondary_sale}  <span style={{marginLeft:"2px", marginRight:"5px"}}><i className="fas fa-rupee-sign"></i></span> <br></br>

                            Direct Sale: {costCenter.direct_sale}  <span style={{marginLeft:"2px"}}><i className="fas fa-rupee-sign"></i></span> <br></br>

                            Total : {costCenter.sum_secondary_sale + costCenter.direct_sale} <span style={{marginLeft:"2px"}}><i className="fas fa-rupee-sign"></i></span>
                            </td>
                          </tr>
                          </table>
                        </div>
                      </tr>
                      </td>
                    </tr>
                      
                  ))}
                  {/* <tr className="finalTotal">
                    <td>

                    </td>
                    <td>
                      <span> 
                            <span>Outstanding: {this.state.outstanding}  <span style={{marginLeft:"1px"}}><i className="fas fa-rupee-sign"></i></span> </span>
                      
                            <span style={{marginLeft:"8px"}}>Due Exceeded: {this.state.due_exceed}  <span style={{marginLeft:"1px"}}><i className="fas fa-rupee-sign"></i></span></span>
                      </span>
                    </td>
                  </tr> */}
                  </tbody>
                </table>
                <table className="customer_direct_sale_table">
                  <tbody>
                  <tr style={{display:this.state.parent_dealer?"grid":'none',backgroundColor:"#ffa8b3"}}>
                    <td style={{fontWeight:"500",paddingLeft:"5px"}}>{this.state.parent_dealer['name']}</td>
                    <td><span>{this.state.parent_dealer['direct_sale']}<i style={{marginLeft:"3px"}} className="fas fa-rupee-sign"></i></span></td>
                  </tr>
                  <tr className="blank_row" style={{height:"10px"}}>
                  </tr>

                  <tr>
                    <td style={{fontWeight:"800"}}>Grand Total </td>
                    <td style={{fontWeight:"800"}}>{this.state.grand_total} <i style={{marginLeft:"3px"}} className="fas fa-rupee-sign"></i></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
         
        </div>

      )}
    </MyContext.Consumer>
  )}
}

export default SecondarySale;
SecondarySale.contextType = MyContext;
