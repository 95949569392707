import { useContext, useEffect, useState } from "react";
import DivisionTile from "./DivisionTile";
import "./DivisionSwitcher.scss";
import MyContext from "./../MyContext";
import { useHistory } from "react-router-dom";

const DivisionSwitcher = () =>{

  const { user_details , setUserDetails, loading} = useContext(MyContext);
  const [data, setData] = useState([]);
  const history = useHistory();


  useEffect(() => {

    if(user_details && user_details.divisions && user_details?.divisions?.length == 1){
      history.replace('/home');
      return
    }
    if (user_details && user_details.divisions && user_details?.divisions?.length > 0) {

      const newData = user_details.divisions.map(division => ({
        title: division.label,
        value: division.value
      }));

      setData(newData);
    }
  }, [user_details]);
  const handleTileClick = (title, value) => {

    const division = {
      "label" :  title,
      "value" :  value
    }

    $.ajax({
      url : process.env.REACT_APP_SSS + "/api/changeDivision",
      data : {
        division : division,
        sid : user_details.sid,
        username : user_details.username,
        password : user_details.password,
      },
      type : "POST"
    }).done(function(return_data){

      if(return_data.status == "success"){
        delete return_data.status;
        return_data.division = division;
        setUserDetails(return_data);
        window.localStorage.setItem("user_details_pharmacopius_emak_app",JSON.stringify(return_data));

        history.replace('/home');
      }else{
        alert("You are not authorized to access");
        loading(0);
      }
    }).fail(function(){
      alert("Network error");
      loading(0);
    });
  };

    return(
        <div className="division_select_container">
          {/* <h3 style={{textAlign:"center"}}>
            Select Division
          </h3> */}
        {
         data.map((item,idx)=>
                <DivisionTile key={idx} heading={item.title} content={item.value} onClick={() => handleTileClick(item.title, item.value)} />
              )
        }
        </div>
    )
}

export default DivisionSwitcher;