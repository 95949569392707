import React , {Component} from "react";
import MyContext from '../MyContext.jsx';
import {Link} from "react-router-dom";
import { CgSearch } from 'react-icons/cg';
import Select from "react-select";
import InfiniteScroll from 'react-infinite-scroll-component';


import './PostDateCheque.scss';
import Loader from "../Loading/Loader.jsx";

class PostDateCheque extends Component{
  constructor(props){
    super(props);
    // this.districtSelect = React.createRef();
    this.state = {
      districts  : [],
      district : "",
      cost_centers : [],
      showing_cost_centers : [],
      executive:'',
      executives:[]
    }
    this.districtChange = this.districtChange.bind(this);
    this.getPostDatedCheque = this.getPostDatedCheque.bind(this);
    this.formChange = this.formChange.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
  }
  componentDidMount(){
    var obj_this = this;
    const url = `${process.env.REACT_APP_SSS}/api/getOutstandingDistricts`;
    this.context.loading(1);
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      if(return_data.status == "success"){
        obj_this.setState({
         districts: return_data.districts,
         executives :  return_data.executives

        });
        // obj_this.context.loading(0);
      }else{
        // obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      alert("Network error");
    });
    this.getPostDatedCheque();

    ui.swipeMenu(".cheque_outstanding_page_container");
    
  }

  getPostDatedCheque(){
    var obj_this = this;
    const url2 = `${process.env.REACT_APP_SSS}/api/postDatedChequeByDistrict`;
    this.context.loading(1);
    $.ajax({
      url : url2,
      data : {
        sid : this.context.user_details.sid,
        district_id : this.state.district.value,
        exe_staff_id  : this.state.executive.value
      },
      type : "POST"
    }).done(function(return_data){
      if(return_data.status == "success"){
        var cost_centers = return_data.cost_centers.filter((item) => ( item.dealers.length));
        cost_centers = cost_centers.map((costCenter) => ({
          ...costCenter,
          dealers: costCenter.dealers.filter((customer) => customer.bills.length > 0),
        }));
        obj_this.setState({
         cost_centers : cost_centers,
         showing_cost_centers : cost_centers,
         outstanding  : return_data.outstanding,
         cheque_amount : return_data.cheque_amount
        });
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      alert("Network error");
    });
  }


  districtChange(option){
    this.setState({
      district : option
    });
  }
  executiveChange(option){
    this.setState({
      executive : option
    });
  }

  formChange(e){
    let showing_cost_centers;
    if(e.target.value){
      showing_cost_centers = this.state.cost_centers.map((costCenter) => ({
        ...costCenter,
        dealers: costCenter.dealers.filter((customer) =>
          customer.name.toLowerCase().includes(e.target.value.toLowerCase())
        ),
      }));
    }
    else{
      showing_cost_centers = this.state.cost_centers;
    }
    this.setState({
      showing_cost_centers : showing_cost_centers
    });
  }

  formSubmit(e){
    e.preventDefault();
    $(".customer_outstandings_table tr").css("backgroud-color","");
    // if(!this.state.district.value){
    //   this.districtSelect.current.focus();
    //   return;
    // }

    this.getPostDatedCheque();
    
  }

  render(){
    return(
      <MyContext.Consumer>
        {context => (
        <div className="cheque_outstanding_page_container uijs swipemenu">
          <div className="page_header">
            <Link className="back_button" to="/dashboard"><i className="fas fa-arrow-left"></i></Link>
            <div className="page_title">Post Dated Cheque</div>
          </div> 
         
          <form className="executive_district_form" onSubmit={this.formSubmit} 
          style={{display:this.context.user_details.designation=='ASM'?"block":this.context.user_details.designation=='RSM'?"block":this.context.user_details.designation=='ADMIN'?"block":"none"}}
          >
            <div className="blocks">
              <div className="input_grid">
                <div>
                  <div className="input_label">District</div>
                  <Select 
                    options={this.state.districts}  
                    value ={this.state.district}
                    className="district" 
                    // ref={this.districtSelect}
                    onChange={e => this.districtChange(e)}
                    required
                  />
                  <div className="input_label">Executive</div>
                  <Select 
                    options={this.state.executives}  
                    value ={this.state.executive}
                    className="executive" 
                    // ref={this.exeSelect}
                    onChange={e => this.executiveChange(e)}
                    required
                  />
                </div>
                <div className="submit_button_container">
                  <button className="submit_button">Submit</button>
                </div>
                </div>
                
            </div>
          </form>
          <div className="toggles_container">
              <div className="list_3 toggles" menu="0">
                <i className="fa fa-credit-card order_status_icon"></i>
                <div className="order_status">Cost Center Post Dated</div>
                {/* <div className="order_count">{this.state.so_confirmed_salesorders.length}</div> */}
              </div>
              <div className="list_4 toggles" menu="1">
                <i className="fa fa-list-alt order_status_icon"></i>
                <div className="order_status">Detailed Post Dated</div>
                {/* <div className="order_count">{this.state.placed_salesorders.length}</div> */}
              </div>
          </div>
          <div className="list_blocks_container">
              <div className="lists_container">
                <table className="cost_center_outstandings_postdated_cheque_table">
                    <thead>
                      <tr>
                        <th style={{maxWidth:"40px"}} align="center">#</th>
                        <th align="center">Cost Center</th>
                        <th align="center">Outstanding</th>
                        <th align="center">Cheque Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr style={{"display":this.state.showing_cost_centers.length?"none":"table-row"}}>
                      <td colSpan="4" align="center">No result!</td>
                    </tr>
                    {this.state.showing_cost_centers && this.state.showing_cost_centers.map((costCenter, costCenterIndex) => (
                      <tr key={costCenterIndex} >
                        
                        <td valign="top" align="center">{costCenterIndex+1}</td>
                        <td valign="top" style={{padding:"5px 0px"}}>
                          <span style={{fontWeight:"500",paddingLeft:"5px"}}>{costCenter.district}</span>
                      
                        </td>

                        <td  align="right">
                        {costCenter.sum_of_cost_center_outstanding}  <span style={{marginLeft:"2px", marginRight:"5px"}}><i className="fas fa-rupee-sign"></i></span>
                        </td>
                        <td  align="right">
                        {costCenter.sum_of_cost_center_cheque_amount}  <span style={{marginLeft:"2px"}}><i className="fas fa-rupee-sign"></i></span>
                        </td>
                      </tr>    
                    ))}
                    
                    <tr className="finalTotal">
                      <td colSpan={2} align="center"> <b>Total</b></td>
                      <td align="right">{this.state.outstanding}<span style={{ marginLeft: "2px" }}><i className="fas fa-rupee-sign"></i></span> </td>
                      <td align="right">{this.state.cheque_amount}<span style={{ marginLeft: "2px" }}><i className="fas fa-rupee-sign"></i></span> </td>
                    </tr>
                    </tbody>              
                </table>
              </div>
              <div className="lists_container">
                <div className="search_container">
                  <CgSearch/>
                  <input className="customer_search_input" 
                    placeholder="Search customer.."
                    onChange={this.formChange}
                  />
                </div>
                <div className="table_container">
                <InfiniteScroll
                    dataLength={1}
                    refreshFunction={this.getPostDatedCheque}
                    pullDownToRefresh
                    pullDownToRefreshThreshold={200}
                    pullDownToRefreshContent={
                      <h3 style={{ textAlign: 'center' , top : '50px'}}>&#8595; Pull down to refresh</h3>
                    }
                    releaseToRefreshContent={
                      <Loader />
                    }
                    >
                      <table className="customer_outstandings_table">
                    <thead>
                      <tr>
                        <th style={{maxWidth:"40px"}} align="center">#</th>
                        <th>Cost Center</th>

                      </tr>
                    </thead>
                    <tbody>
                    <tr style={{"display":this.state.showing_cost_centers.length?"none":"table-row"}}>
                      <td colSpan="2" align="center">No result!</td>
                    </tr>
                    {this.state.showing_cost_centers && this.state.showing_cost_centers.map((costCenter, costCenterIndex) => (
                      <tr key={costCenterIndex} >
                        
                        <td valign="top" align="center">{costCenterIndex+1}</td>
                        <td valign="top" style={{padding:"5px 0px"}}>
                          <span style={{fontWeight:"500",paddingLeft:"5px"}}>{costCenter.district}</span>
                      
                          <tr style={{"display": "inline"}}>
                          <div className="bills_table_container" style={{"display":costCenter.dealers.length?"block":"none"}}>
                            <table className="bills_table">
                              <tr>
                                <th style={{minWidth:'30px', textAlign:'center'}}>#</th>
                                <th style={{minWidth:'100%', textAlign:'center'}}>Customer</th>
                              </tr>


                            { costCenter.dealers.map((customer, customerIndex) => (
                              
                              <React.Fragment> 
                                <tr>
                                      <td style={{textAlign:'center'}}>
                                        {customerIndex+1}
                                      </td>
                                      <td>
                                        {customer.name}
                                      </td>
                                  </tr>
                                  <tr style={{display: customer.bills.length>0 ?"table-row":"none"}}>
                                    <td></td>
                                    <td>
                                      <table className="bill_row_cell" style={{display: customer.bills.length>0 ?"table":"none"}}>
                                      <tr>
                                        <th>Invoice No</th>
                                        <th>Amount</th>
                                        <th>Chq Date</th>
                                        <th>Chq Amount</th>
                                      </tr>
                                      
                                    { customer.bills.map((bill, billIndex) => (
                                        <tr key={billIndex} style={{backgroundColor:bill.due_days!='+0' ?"#ffa8b3":""}}>
                                        {/* <td>{new Date(bill.bill_date).toLocaleDateString('en-GB')}</td> */}
                                        <td>{bill.bill_no}</td>
                                        <td align="right" style={{textAlign:"right"}}>
                                          <span>{Math.round(bill.bill_amount)}</span>
                                          <span style={{marginLeft:"3px"}}><i className="fas fa-rupee-sign"></i></span>
                                        </td>
                                        <td>{bill.cheque_date && bill.cheque_date!="0000-00-00 00:00:00"?new Date(bill.cheque_date).toLocaleDateString('en-GB'):''}</td>
                                        <td align="right" style={{textAlign:"right"}}>
                                          <span>{bill.cheque_amount?Math.round(bill.cheque_amount):''}</span>
                                          <span style={{marginLeft:"3px",display:bill.cheque_amount?"inline":"none"}}><i className="fas fa-rupee-sign"></i></span>
                                        </td>
                                        </tr>
                                    ))}
                                    <tr>
                                      
                                      <td colSpan={4} style={{textAlign:'center'}}>
                                        Total Outstanding : {customer.pending_total}  <span style={{marginLeft:"2px", marginRight:"5px"}}><i className="fas fa-rupee-sign"></i></span>

                                        Total Cheque Amount : {customer.cheque_amount_total}  <span style={{marginLeft:"2px"}}><i className="fas fa-rupee-sign"></i></span>
                                      </td>
                                    </tr>
                                    </table>
                                    </td>
                                  </tr>
                                </React.Fragment>
                                  )) 
                                }
                          
                                <tr className="dealerTotal">
                                
                                <td colSpan={2}>
                                  {costCenter.district} - 
                                    Outstanding : {costCenter.sum_of_cost_center_outstanding}  <span style={{marginLeft:"2px", marginRight:"5px"}}><i className="fas fa-rupee-sign"></i></span>

                                    Cheque Amount : {costCenter.sum_of_cost_center_cheque_amount}  <span style={{marginLeft:"2px"}}><i className="fas fa-rupee-sign"></i></span>
                                  </td>
                                </tr>
                            
                              <tfoot>
                              
                              </tfoot>
                              
                            </table>
                          </div>
                        </tr>
                        </td>
                      </tr>
                        
                    ))}
                    <tr className="finalTotal">
                      <td>

                      </td>
                      <td>
                        <span> 
                              <span>Outstanding: {this.state.outstanding}  <span style={{marginLeft:"1px"}}><i className="fas fa-rupee-sign"></i></span> </span>
                        
                              <span style={{marginLeft:"8px"}}>Cheque Amount: {this.state.cheque_amount}  <span style={{marginLeft:"1px"}}><i className="fas fa-rupee-sign"></i></span></span>
                        </span>
                      </td>
                    </tr>
                    </tbody>
                  
                  </table>
                  </InfiniteScroll>
                </div>
              </div>
          </div>
        </div>

      )}
    </MyContext.Consumer>
  )}
}

export default PostDateCheque;
PostDateCheque.contextType = MyContext;
