import React , {Component} from "react";
import MyContext from '../MyContext.jsx';
import {Link} from "react-router-dom";
import { CgSearch } from 'react-icons/cg';


import './RateCalculator.scss';

class RateCalculator extends Component{
  constructor(props){
    super(props);
    this.state = {
      products:[],
      grandTotal : 0,
    }
    this.formChange = this.formChange.bind(this);
    this.calculateGrandTotal = this.calculateGrandTotal.bind(this);
    this.resetChange = this.resetChange.bind(this);
  }
  componentDidMount(){
    var obj_this = this;

    const url2 = `${process.env.REACT_APP_SSS}/api/getProductRateList`;
    this.context.loading(1);
    $.ajax({
      url : url2,
      data : {
        sid : this.context.user_details.sid,
      },
      type : "POST"
    }).done(function(return_data){
     
      if(return_data.status == "success"){
        var products = return_data.active_products;
        obj_this.setState({
         products : products,
        });
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      obj_this.context.loading(0);
      alert("Network error");
    });
    
  }
  resetChange(){
    var products=this.state.products;
    for (var i = 0; i < products.length; i++) { 
      products[i].price.qty='';
      products[i].price.total=0;
      const grandTotal = this.calculateGrandTotal(products);
    
      this.setState({ products , grandTotal});
    }
  }

  formChange = (e, index) => {
    const { name, value } = e.target;
    const products = [...this.state.products]; 
    const product = {...products[index]}; 
    
    if(value === ''){
      product.price[name] = null
    } else if( Number(value)<0){
      return
    }else{
      product.price[name] =  Number(value);
    }

      
    product.price.total = Math.round((product.price.qty * product.price.pts)*100)/100;
    products[index] = product;
    const grandTotal = this.calculateGrandTotal(products);
    
    this.setState({ products , grandTotal});
  };
  calculateGrandTotal = (products) => {
    let grandTotal = 0;
    products.forEach((product) => {
      grandTotal += product.price.total;
    });
    return Math.round(grandTotal*100)/100;
  };
  
  render(){
    return(
      <MyContext.Consumer>
        {context => (
        <div className="rate_calculator_page_container">
          <div className="page_header">
            <Link className="back_button" to="/dashboard"><i className="fas fa-arrow-left"></i></Link>
            <div className="page_title">Rate Calculator</div>
            <button className="reset_btn" onClick={this.resetChange}><i class="fas fa-redo"></i>&nbsp; Reset</button>
          </div> 
          <div className="table_container">
            <table className="products_price_table">
              <thead>
                <tr>
                  <th style={{maxWidth:"40px"}} align="center">#</th>
                  <th style={{minWidth:"180px"}}>Product</th>
                  <th style={{minWidth:"60px"}} align="center">PTS </th>
                  <th style={{minWidth:"80px"}} align="center">Volume </th>
                  <th style={{minWidth:"80px"}} align="center">Value </th>
                </tr>
              </thead>
              <tbody>
              <tr style={{display:this.state.products.length?"none":"table-row"}}>
                <td colSpan="2" align="center">No Products!</td>
              </tr>
              {this.state.products && this.state.products.map((cust, index) => (
                <tr key={index}>
                  <td valign="top" align="center">{index + 1}</td>
                  <td valign="top">{cust.name}</td>
                  <td align="right">{cust.price.pts}</td>
                  <td >
                    <input
                      type="number"
                      value={cust.price.qty}
                      onChange={(e) => this.formChange(e, index)}
                      name="qty"
                      className="table-input"
                    />
                  </td>
                  <td align="right" >{cust.price.total} <i style={{marginLeft:"3px"}} className="fas fa-rupee-sign"></i></td>
                </tr>
              ))}
              <tr>
                <td colSpan="4" align="right">Grand Total</td>
                <td align="right">{this.state.grandTotal} <i style={{marginLeft:"3px"}} className="fas fa-rupee-sign"></i></td>
              </tr>

              </tbody>
            </table>
          </div>
        </div>

      )}
    </MyContext.Consumer>
  )}
}

export default RateCalculator;
RateCalculator.contextType = MyContext;
