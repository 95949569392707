import React, { Component } from "react";
import MyContext from '../MyContext.jsx';
import Loader from './Loader';

class Loading extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
   
 
render(){
  return (
    <MyContext.Consumer>
      {context => (
        context.show_loading?<Loader />:null
     )}
    </MyContext.Consumer>
  )}
}
 
export default Loading;