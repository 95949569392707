import React , {Component} from "react";
import MyContext from '../MyContext.jsx';
import {Link} from "react-router-dom";
import Select from "react-select";
import DatePicker from 'react-date-picker';

import './SalesReturnReport.scss';

class SalesReturnReport extends Component{
  constructor(props){
    super(props);
    this.typeSelect = React.createRef();
    const { begin, end } = this.calculateFinancialYear();

    this.state = {
      customers    :   [],
      customer :   {},
      districts  : [],
      district : "",
      types    :   [],
      type :   {
          "value": 111,
          "label": "EXPIRY RETURN"
        },
      dealers : [],
      grand_amount:0,
      from_date : begin,
      to_date : end,
    }
    this.customerChange = this.customerChange.bind(this);
    this.districtChange = this.districtChange.bind(this);
    this.typeChange = this.typeChange.bind(this);
    this.formSubmit = this.formSubmit.bind(this);


  }
   calculateFinancialYear() {
    const today = new Date();
    const currentMonth = today.getMonth() + 1;
    const currentYear = today.getFullYear();
  
    let begin, end;
  
    if (currentMonth >= 4) {
      begin = new Date(currentYear, 3, 1); 
      end = today; 
    } else {
      begin = new Date(currentYear - 1, 3, 1); 
      end = today; 
    }
  
    return { begin, end };
  }
  componentDidMount(){
    var obj_this = this;
    const url = `${process.env.REACT_APP_SSS}/api/Reports/getSalesReturnSelect`;
    obj_this.context.loading(1);
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      if(return_data.status == "success"){
        obj_this.setState({
         customers : return_data.customers,
         districts: return_data.districts,
         types: return_data.types
        });
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
        obj_this.context.loading(0);
        alert("Network error");
    });
    obj_this.getSaleReturnForm();
  }

  customerChange(option){
    this.setState({
      customer : option
    });
  } 

  districtChange(option){
    this.setState({
      district : option
    });
  }

  typeChange(option){
    this.setState({
      type : option
    });
  } 
  getSaleReturnForm(){
    if(!this.state.type.value){
      this.typeSelect.current.focus();
      return;
    }

    var obj_this = this;
    const url2 = `${process.env.REACT_APP_SSS}/api/Reports/getSalesReturnDetails`;
    this.context.loading(1);
    $.ajax({
      url : url2,
      data : {
        sid : this.context.user_details.sid,
        customer : this.state.customer.value,
        district_id : this.state.district.value,
        type : this.state.type.value,
        from_date : this.state.from_date.toLocaleDateString('en-CA'),
        to_date : this.state.to_date.toLocaleDateString('en-CA')
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      var dealer =return_data.dealers.filter(dealer => dealer.details.length >0)
      if(return_data.status == "success"){
        obj_this.setState({
          dealers : dealer,
          grand_amount:return_data.grand_amount
        });
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      alert("Network error");
    });
  }

  formSubmit(e){
    e.preventDefault();
    $(".sales_return_table tr").css("backgroud-color","");
    var obj_this = this;
    obj_this.getSaleReturnForm();
    
  }

  fromDateChange(value){
    this.setState({from_date : value});
  }
  toDateChange(value){
    this.setState({to_date : value});
  }

  render(){
    return(
      <MyContext.Consumer>
        {context => (
        <div className="sales_return_page_container">
          <div className="page_header">
            <Link className="back_button" to="/dashboard"><i className="fas fa-arrow-left"></i></Link>
            <div className="page_title">Sales Return Report</div>
          </div> 
          <form className="customer_filter_form" onSubmit={this.formSubmit}>
            <div className="blocks">
            <div className="input_grid">
                <div className="form_input">
                  <div className="from_to_label">
                    <div className="input_label">From Date*</div>
                    <DatePicker
                      className="input custom_input"
                      onChange={(value) => this.fromDateChange(value)}
                      value={this.state.from_date}
                      clearIcon={null}
                      required
                      format="dd-MM-y"
                    />
                    <div className="input_label">To Date*</div>
                    <DatePicker
                      className="input custom_input"
                      onChange={(value) => this.toDateChange(value)}
                      value={this.state.to_date}
                      minDate = {new Date(this.state.from_date)}
                      clearIcon={null}
                      required
                      format="dd-MM-y"
                    />
                  </div>
                  <div className="input_label">Customer</div>
                  <Select 
                    options={this.state.customers}  
                    value ={this.state.customer}
                    className="customer" 
                    onChange={e => this.customerChange(e)}
                  />
                   <div className="input_label">District</div>
                  <Select 
                    options={this.state.districts}  
                    value ={this.state.district}
                    className="customer" 
                    onChange={e => this.districtChange(e)}
                  />
                   <div className="input_label">Type*</div>
                  <Select 
                    options={this.state.types}  
                    value ={this.state.type}
                    className="customer" 
                    ref={this.typeSelect}
                    onChange={e => this.typeChange(e)}
                    required
                  />
                  <div className="submit_button_container">
                    <button className="submit_button">Submit</button>
                  </div>
                </div>
                
              </div>
            </div>
          </form>
          <div className="table_container">
            <table className="sales_return_table">
              <thead>
                <tr>
                  <th style={{maxWidth:"40px"}} align="center">#</th>
                  <th style={{minWidth:"500px"}}>Customer</th>
                </tr>
              </thead>
              <tbody>
              <tr style={{display:this.state.dealers.length?"none":"table-row"}}>
                <td colSpan="2" align="center">No result!</td>
              </tr>
              {this.state.dealers && this.state.dealers.map((dealer,index) => (
                <tr key={index} >
                  <td valign="top" align="center">{index+1}</td>
                  <td valign="top" style={{padding:"5px 0px"}}>
                    <div style={{fontWeight:"500",paddingLeft:"5px"}}>{dealer.name}</div>
                   
                    <div className="details_table_container" style={{display:dealer.details.length?"block":"none"}}>
                      <table className="details_table">
                        <thead>
                          <tr>
                            <th style={{minWidth:"80px", textAlign:"left"}}>Sales Return</th>
                            <th style={{minWidth:"60px", textAlign:"left"}}>Invoice No</th>
                            <th style={{minWidth:"80px", textAlign:"center"}}>Return Date</th>
                            <th style={{minWidth:"60px", textAlign:"left"}}>Product</th>
                            <th style={{minWidth:"60px", textAlign:"left"}}>Batch No</th>
                            <th style={{minWidth:"70px", textAlign:"center"}}>Expiry Date</th>
                            <th style={{minWidth:"60px", textAlign:"right"}}>Amount</th>
                            <th style={{minWidth:"60px", textAlign:"right"}}>Total Qty</th>
                          </tr>
                        </thead>
                        <tbody>
                        {dealer.details && dealer.details.map((detail,idx) => (
                          <tr key={idx} >
                            <td align="left" style={{textAlign:"left"}}>{detail.rtn_no}</td>
                            <td align="left" style={{textAlign:"left"}}>{detail.bill_no}</td>
                            <td align="left" style={{textAlign:"center"}}>{new Date(detail.apr_date).toLocaleDateString('en-GB')}</td>
                            <td align="left" style={{textAlign:"left"}}>{detail.name}</td>
                            <td align="left" style={{textAlign:"left"}}>{detail.batch_no}</td>
                            <td align="left" style={{textAlign:"center"}}>{new Date(detail.item_exp_date).toLocaleDateString('en-GB')}</td>
                            <td align="right" style={{textAlign:"right"}}>{detail.grand_total}</td>
                            <td align="right" style={{textAlign:"right"}}>{detail.approved_qty}</td>

                          </tr>
                          ))}
                           <tr className="cust_total">
                            <td></td>
                            <td colSpan="6" style={{textAlign:"right"}}>
                            <b>Total:</b>
                            </td>
                            <td className='ttl' align="right">
                            <b>{parseFloat(dealer.total).toFixed(2)}</b> <span style={{marginLeft:"2px"}}><i className="fas fa-rupee-sign"></i></span>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                         
                        </tfoot>
                        
                      </table>
                    </div>
                  </td>
                </tr>
              ))}
             <tr className="finalTotal">
                {this.state.grand_amount>0?
                <>
                <td align="right" colSpan="2">
                  <span><b>Grand Total: &nbsp;&nbsp;&nbsp;&nbsp;{parseFloat(this.state.grand_amount).toFixed(2)}</b> <span style={{marginLeft:"2px"}}><i className="fas fa-rupee-sign"></i></span></span>
                </td>
                </>
                :null}
              </tr>
              </tbody>
            </table>
          </div>
          
        </div>

      )}
    </MyContext.Consumer>
  )}
}

export default SalesReturnReport;
SalesReturnReport.contextType = MyContext;
