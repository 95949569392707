
window.ui = new function () {
  this.swipeMenu = function(container){
    // <div class="container uijs swipemenu">
    //  <div class="toggles" menu=0>butoon 1</div>
    //  <div class="toggles" menu=1>button 2</div>
    //  <div class="list_blocks_container noselect">
    //    <div class="lists_container">
    //    </div>
    //    <div class="lists_container">
    //      <ol>
    //        <li>item1</li>
    //        <li>item2</li>
    //      </ol>
    //    </div>
    //  </div>
    // </div>
    $(container + " .list_blocks_container").scrollLeft(0);
    var current_menu_no = 0;
    $(container+" .toggles").on("click",function(){
      // console.log("changing from menu : "+current_menu_no);
      $(container+" .toggles").removeClass("selected");
      $(this).addClass("selected");
      current_menu_no=$(container+" .toggles.selected").attr("menu");
      var new_scroll_pos = $(".lists_container").outerWidth()*($(this).attr("menu"));
      $(container + " .list_blocks_container").stop().animate({"scrollLeft":new_scroll_pos},200,function(){
      });
    });

    var holding = 0;
    var swipe = {
      initial_touch_x : "",
      initial_scroll_pos : "",
      initial_timestamp : "",
      duration : 0,
      distance : 0,
      velocity : 0
    };

    $(container + " .list_blocks_container").on("touchstart mousedown",function(event){
      holding = 1;
      // console.log("pressed");
      swipe.distance = 0;
      if(typeof event.pageX != "undefined")
        swipe.initial_touch_x = event.pageX;
      else
        swipe.initial_touch_x = event.originalEvent.touches[0].pageX;
      swipe.initial_scroll_pos = $(container + " .list_blocks_container").scrollLeft();
      swipe.initial_timestamp = new Date().getTime();
      // console.log(swipe.initial_timestamp);
    }).on("touchmove mousemove",function(event){
      var current_touch_x;
      if(holding){
        if(typeof event.pageX != "undefined")
          current_touch_x = event.pageX;
        else
          current_touch_x = event.originalEvent.touches[0].pageX;
        var move = swipe.initial_touch_x-current_touch_x;
        move = visual_move(move);

        swipe.distance = swipe.initial_touch_x - mouse.x(event);
        $(container + " .list_blocks_container").scrollLeft(move+swipe.initial_scroll_pos);
      }
    }).on("touchend mouseup",function(event){
      holding = 0;
      swipe.duration = new Date().getTime()-swipe.initial_timestamp;
      // console.log("released");
      var ignore = 5;//in % of window width
      ignore = (5*$(window).width())/100;
      var no_sign_distance = swipe.distance>0?swipe.distance:swipe.distance*-1;
      swipe.velocity = no_sign_distance<ignore || swipe.duration<50?0:swipe.distance/swipe.duration;
      swipe.velocity = swipe.velocity>0?swipe.velocity:swipe.velocity*-1;
      // console.log("dist "+swipe.distance);
      // console.log("time "+swipe.duration);
      // console.log("vel "+swipe.velocity);
      // console.log();
      scrollCorrect();
    });
    function visual_move(move) {
      var ignore = 5;//in % of window width
      ignore = (5*$(window).width())/100;
      // console.log("ignore "+ignore);
      var sign = move>0?1:-1;
      var distance = move>0?move:move*-1;
      distance = distance-ignore;
      distance = distance>0?distance:0;
      distance = distance * 1;
      return distance*sign;
    }
    function scrollCorrect(){
      var scrolled_dist = $(container + " .list_blocks_container").scrollLeft();
      var menu_width = $(".lists_container").outerWidth();
      var minus_point = (menu_width*current_menu_no) - (menu_width/2);
      var add_point = (menu_width*current_menu_no) + (menu_width/2);
      var next_menu = current_menu_no;
      if(swipe.velocity>0.4){
        if(swipe.distance>0){
          ++next_menu;
        }else if(current_menu_no != 0){
          --next_menu;
        }
      }else  if(scrolled_dist < minus_point){
        // console.log("back");
        --next_menu;
      }
      else if(scrolled_dist > add_point){
        // console.log("front");
        ++next_menu;
      }

      // console.log((current_menu_no));
      // console.log(scrolled_dist)
      // console.log(next_menu);
      $(container + " .toggles[menu="+next_menu+"]").trigger("click");
      console.log();
      // $(container + " .list_blocks_container").stop().animate({"scrollLeft":menu_width * skip_menu},100);
    }
    var mouse = new function(){
      this.x = function(event){
        var current_touch_x;
        if(typeof event.pageX != "undefined")
          current_touch_x = event.pageX;
        else
          current_touch_x = event.originalEvent.touches[0].pageX;
        return current_touch_x;
      }
    }

    scrollCorrect();
  }
};

