import React , {Component} from "react";
import MyContext from '../MyContext.jsx';
import {Link} from "react-router-dom";
import { CgSearch } from 'react-icons/cg';
import Select from "react-select";
import DatePicker from 'react-date-picker';

import './CreditNotesReport.scss';

class CreditNotesReport extends Component{
  constructor(props){
    super(props);
    const { begin, end } = this.calculateFinancialYear();

    this.state = {
      customers    :   [],
      customer :   {},
      districts  : [],
      district : "",
      dealers : [],
      grand_total:0,
      from_date : begin,
      to_date : end,
    }
    this.customerChange = this.customerChange.bind(this);
    this.districtChange = this.districtChange.bind(this);
    this.formSubmit = this.formSubmit.bind(this);


  }
  componentDidMount(){
    var obj_this = this;
    const url = `${process.env.REACT_APP_SSS}/api/Reports/getCustomers`;
    // this.context.loading(1);
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      if(return_data.status == "success"){
        obj_this.setState({
         customers : return_data.dealers,
         districts: return_data.districts,
        });
        // obj_this.context.loading(0);
      }else{
        // obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
        obj_this.context.loading(0);
        alert("Network error");
    });

    obj_this.getCreditNoteDetails();
    
  }
   calculateFinancialYear() {
    const today = new Date();
    const currentMonth = today.getMonth() + 1;
    const currentYear = today.getFullYear();
  
    let begin, end;
  
    if (currentMonth >= 4) {
      begin = new Date(currentYear, 3, 1); 
      end = today; 
    } else {
      begin = new Date(currentYear - 1, 3, 1); 
      end = today; 
    }
  
    return { begin, end };
  }

  customerChange(option){
    this.setState({
      customer : option
    });
  } 
  districtChange(option){
    this.setState({
      district : option
    });
  }
  getCreditNoteDetails(){
    var obj_this = this;
    const url2 = `${process.env.REACT_APP_SSS}/api/Reports/getCreditNoteDetails`;
    this.context.loading(1);
    $.ajax({
      url : url2,
      data : {
        sid : this.context.user_details.sid,
        dealer_id : this.state.customer?.value,
        district_id : this.state.district?.value,
        from_date : this.state.from_date.toLocaleDateString('en-CA'),
        to_date : this.state.to_date.toLocaleDateString('en-CA')
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      var dealer =return_data.dealers.filter(dealer => dealer.cust_details.length >0)
     console.log(dealer);
      if(return_data.status == "success"){
        obj_this.setState({
          dealers : dealer,
          grand_total:return_data.grand_total
        });
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      alert("Network error");
    });
  }

  formSubmit(e){
    e.preventDefault();
    $(".credit_note_table tr").css("backgroud-color","");
    
    var obj_this = this;
    obj_this.getCreditNoteDetails();
    
  }

  fromDateChange(value){
    this.setState({from_date : value});
  }
  toDateChange(value){
    this.setState({to_date : value});
  }

  render(){
    return(
      <MyContext.Consumer>
        {context => (
        <div className="credit_note_page_container">
          <div className="page_header">
            <Link className="back_button" to="/dashboard"><i className="fas fa-arrow-left"></i></Link>
            <div className="page_title">Credit Notes Report</div>
          </div> 
          <form className="customer_filter_form" onSubmit={this.formSubmit}>
            <div className="blocks">
            <div className="input_grid">
                <div className="form_input">
                  <div className="from_to_label">
                    <div className="input_label">From Date</div>
                    <DatePicker
                      className="input custom_input"
                      onChange={(value) => this.fromDateChange(value)}
                      value={this.state.from_date}
                      clearIcon={null}
                      required
                      format="dd-MM-y"
                    />
                    <div className="input_label">To Date</div>
                    <DatePicker
                      className="input custom_input"
                      onChange={(value) => this.toDateChange(value)}
                      value={this.state.to_date}
                      minDate = {new Date(this.state.from_date)}
                      clearIcon={null}
                      required
                      format="dd-MM-y"
                    />
                  </div>
                  <div className="input_label">Customer</div>
                  <Select 
                    options={this.state.customers}  
                    value ={this.state.customer}
                    className="customer" 
                    onChange={e => this.customerChange(e)}
                    required
                  />
                  <div className="input_label">District</div>
                  <Select 
                    options={this.state.districts}  
                    value ={this.state.district}
                    className="customer" 
                    onChange={e => this.districtChange(e)}
                  />
                  <div className="submit_button_container">
                    <button className="submit_button">Submit</button>
                  </div>
                </div>
                
              </div>
            </div>
          </form>
          <div className="table_container">
            <table className="credit_note_table">
              <thead>
                <tr>
                  <th style={{maxWidth:"40px"}} align="center">#</th>
                  <th>Customer</th>
                </tr>
              </thead>
              <tbody>
              <tr style={{display:this.state.dealers.length?"none":"table-row"}}>
                <td colSpan="2" align="center">No result!</td>
              </tr>
              {this.state.dealers && this.state.dealers.map((dealer,index) => (
                <tr key={index} >
                  <td valign="top" align="center">{index+1}</td>
                  <td valign="top" style={{padding:"5px 0px"}}>
                    <div className="name_date" style={{fontWeight:"500",paddingLeft:"5px"}}>
                      <span>{dealer.name}</span>
                      {/* <span className="voucher_date">{dealer.date && dealer.date!="0000-00-00 00:00:00"?new Date(dealer.date).toLocaleDateString('en-GB'):''}</span> */}
                    </div>
                    <div className="cust_details_table_container" style={{display:dealer.cust_details.length?"block":"none"}}>
                      <table className="cust_table">
                        <thead>
                          <tr>
                            <th style={{minWidth:"100px"}}>Voucher Date</th>
                            <th style={{minWidth:"100px"}}>Item Name</th>
                            <th style={{minWidth:"40px"}}>Qty</th>
                            <th style={{minWidth:"40px"}}>Unit</th>
                            <th style={{minWidth:"60px"}}>Taxable<br></br>Value</th>
                            <th style={{minWidth:"40px"}}>Tax</th>
                            <th style={{minWidth:"80px"}}>Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dealer.cust_details && dealer.cust_details.map((cust,idx) => (
                            
                          <tr key={idx} >
                            <td>{new Date(cust.voucher_date).toLocaleDateString('en-GB')}</td>
                            <td align="left" style={{textAlign:"left"}}>
                              <span>{cust.stock_item_name}</span>
                            </td>
                            <td align="right" style={{textAlign:"right"}}>{cust.qty}</td>
                            <td align="centre" style={{textAlign:"centre"}}>
                              <span>{cust.unit}</span>
                            </td>
                            <td align="right" style={{textAlign:"right"}}>{cust.taxable_amount}</td>
                            <td align="right" style={{textAlign:"right"}}>{cust.tax}</td>
                            <td align="right" style={{textAlign:"right"}}>{cust.amount}</td>
                            
                          </tr>
                         
                         ))}
                         <tr className="subTotal">
                            <td></td>
                            <td colSpan="5" style={{textAlign:"right"}}>
                            <b>Total:</b>
                            </td>
                            <td className='ttl' align="right">
                            <b>{dealer.sub_total}</b> <span style={{marginLeft:"2px"}}><i className="fas fa-rupee-sign"></i></span>
                            </td>
                          </tr>
                          {/* <tr className="dealerTotal">
                            <td></td>
                            <td colSpan="4" style={{textAlign:"right"}}>
                            <b>Round Off :</b>
                            </td>
                            <td className='ttl' align="right">
                            <b>{dealer.round_off}</b> <span style={{marginLeft:"2px"}}><i className="fas fa-rupee-sign"></i></span>
                            </td>
                          </tr>
                          <tr className="dealerTotal">
                            <td></td>
                            <td colSpan="4" style={{textAlign:"right"}}>
                            <b>Total:</b>
                            </td>
                            <td className='ttl' align="right">
                            <b>{parseFloat(dealer.total).toFixed(2)}</b> <span style={{marginLeft:"2px"}}><i className="fas fa-rupee-sign"></i></span>
                            </td>
                          </tr> */}
                        
                        </tbody>
                        <tfoot>
                        
                        </tfoot>
                        
                      </table>
                    </div>
                  </td>
                </tr>
              ))}
              <tr className="finalTotal">
                {this.state.grand_total>0?
                <>
                <td align="right" colSpan="2">
                  <span><b>Grand Total: &nbsp;&nbsp;&nbsp;&nbsp;{(this.state.grand_total)}</b> <span style={{marginLeft:"2px"}}><i className="fas fa-rupee-sign"></i></span></span>
                </td>
                </>
                :null}
              </tr>
              </tbody>
             
            </table>
          </div>
        </div>

      )}
    </MyContext.Consumer>
  )}
}

export default CreditNotesReport;
CreditNotesReport.contextType = MyContext;
