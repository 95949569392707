
import {Link} from "react-router-dom";

import './ReportList.scss';

import { CgList, CgUserAdd } from 'react-icons/cg';
import { GiSandsOfTime } from 'react-icons/gi';
import { FaMoneyCheckAlt } from 'react-icons/fa';

import React, { useContext } from 'react';
import MyContext from '../MyContext.jsx';




const Reportlist = () => {
  const userDetails = useContext(MyContext);

  return (
    <div className="report_page_container">
      <div className="menu_container">
  

        <Link to="/srreport" 
          className="single_menu_container" style={{background:"rgba(0, 0, 0, 0) linear-gradient(315deg, rgb(140, 168, 90) 0%, rgb(71, 119, 1) 74%) repeat scroll 0% 0%"}}>
        
          <div className="menu_icon_container">
            <i className="fas fa-paper-plane" style={{fontSize:"36px"}}></i>
          </div>
          <div className="menu_label">Sample Request Report</div>
        </Link>
        <div style={{display:userDetails.user_details.designation=='ASM'?"block":userDetails.user_details.designation=='RSM'?"block":"none"}}>
        <Link to="/visitfrequencyreport" className="single_menu_container" 
         style={{background:"rgba(0, 0, 0, 0) linear-gradient(315deg, rgb(44, 15, 244) 0%, rgb(9, 207, 253) 74%) repeat scroll 0% 0%"}}>
        
          <div className="menu_icon_container">
            <i className="fas fa-users" style={{fontSize:"36px"}}></i>
          </div>
          <div className="menu_label">Visit Frequency Report</div>
        </Link>
        </div>

        
        <div style={{display:userDetails.user_details.ta_approval?"block":"none"}}>
          <Link to="/allowancereport" className="single_menu_container" style={{background:"rgba(0, 0, 0, 0) linear-gradient(315deg, rgb(29, 242, 184) 0%, rgb(5, 98, 28) 74%) repeat scroll 0% 0%"}} >
            <div className="menu_icon_container">
              <FaMoneyCheckAlt size='2.8em' className="menu_icon"/>
            </div>
            <div className="menu_label">Allowance Report</div>
          </Link>
        </div>
        
       
        <div style={{display:userDetails.user_details.es_approval?"block":"none"}}>
          <Link to="/deviationreport" className="single_menu_container"
          style={{background:"rgba(0, 0, 0, 0) linear-gradient(315deg, rgb(7, 28, 8) 0%, rgb(78, 121, 56) 74%) repeat scroll 0% 0%"}}>
          
            <div className="menu_icon_container">
              <i className="fa fa-calendar-check" style={{fontSize:"36px"}}></i>
            </div>
            <div className="menu_label">Schedule &amp; Deviation Report</div>
          </Link>
        </div>
        
        </div>
    </div>
  )
}

export default Reportlist;

