window.geo = new function(){
  // var platform = cordova.platformId;
  
  this.getLocation = function (callback){

    cordova.plugins.diagnostic.getPermissionsAuthorizationStatus(function(statuses){
      for (var permission in statuses){
        switch(statuses[permission]){
            case cordova.plugins.diagnostic.permissionStatus.GRANTED:
                console.log("Permission granted to use "+permission);
                requestLocationAccuracy();
                break;
            case cordova.plugins.diagnostic.permissionStatus.NOT_REQUESTED:
                console.log("Permission to use "+permission+" has not been requested yet");
                requestLocationAuthorization();
                break;
            case cordova.plugins.diagnostic.permissionStatus.DENIED_ONCE:
                console.log("Permission denied to use "+permission+" - ask again?");
                requestLocationAuthorization();
                break;
            case cordova.plugins.diagnostic.permissionStatus.DENIED_ALWAYS:
                console.log("Permission permanently denied to use "+permission+" - guess we won't be using it then!");
                requestLocationAuthorization();
                break;
        }
      }
    }, function(error){
      console.error("The following error occurred: "+error);
    },[
        cordova.plugins.diagnostic.permission.ACCESS_FINE_LOCATION,
        cordova.plugins.diagnostic.permission.ACCESS_COARSE_LOCATION
    ]);


    function requestLocationAuthorization() {
      cordova.plugins.diagnostic.requestLocationAuthorization(function(status){
        console.log("Location authorization status: "+ status);
          if(status != cordova.plugins.diagnostic.permissionStatus.GRANTED){
            console.log("User denied permission to use location");
            requestLocationAuthorization();
          }else{
            console.log("succes");
            requestLocationAccuracy();
          }
        }, function(error){
          console.error("The following error occurred: "+error);
        });
    }


    function requestLocationAccuracy(){
      cordova.plugins.locationAccuracy.canRequest(function(canRequest){
        if (canRequest) {
          cordova.plugins.locationAccuracy.request(function () {
            console.log("Location accuracy request successful");
            fetchLocation();
          }, function (error) {
              console.log("Error requesting location accuracy: " + JSON.stringify(error));
              if (error) {
                  // Android only
                  console.log("error code=" + error.code + "; error message=" + error.message);
                  // if (platform === "android" && error.code !== cordova.plugins.locationAccuracy.ERROR_USER_DISAGREED) {
                  //     if (window.confirm("Failed to automatically set Location Mode to 'High Accuracy'. Would you like to switch to the Location Settings page and do this manually?")) {
                  //         cordova.plugins.diagnostic.switchToLocationSettings();
                  //     }
                  // }
              }
            }, cordova.plugins.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY // iOS will ignore this
            );
          } else {
              // On iOS, this will occur if Location Services is currently on OR a request is currently in progress.
              // On Android, this will occur if the app doesn't have authorization to use location.
              console.log("Cannot request location accuracy");
          }
      });
    }

    function fetchLocation() {
      
      navigator.geolocation.getCurrentPosition(function(position) {
          var location = {
            latitude : position.coords.latitude,
            longitude : position.coords.longitude,
          };
          callback(location);
      }, function (error) {
          console.log('code: '    + error.code    + '\n' +
                'message: ' + error.message + '\n');
      }, { maximumAge: 3000, timeout: 5000, enableHighAccuracy: true });

    }

  }
}
