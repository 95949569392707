import React, { Component } from "react";
import {Link} from "react-router-dom";
import Select from "react-select";
import axios from 'axios';
import DatePicker from 'react-date-picker';



import './AttendanceLeave.scss';
import MyContext from '../MyContext.jsx';

var customer_XHR={'abort' : ()=>{}};



class AttendanceLeave extends Component {

    constructor(props) {
      super(props);
      this.LeaveTypeChange= React.createRef();

      this.state = {
        type : 'PRESENT',
        date : new Date(),
        from_date : '',
        to_date :'',
        leave_types:[],
        leave_type:[],
        no_of_days : '',
        reason:'',
        from_checked : false,
        to_checked : false,
        sid :'',
        attendancelist:[{
          id : '',
          date : '',
          type:'',
        }],
        leavelist:[{
          id:'',
          from_date:'',
          to_date:'',
          no_of_days:'',
          reason:'',
          leave_type:'',
          status:'',
        }]

      }
      this.formChange = this.formChange.bind(this);
    }

    componentDidMount(){  
      var obj_this = this;
      const url = `${process.env.REACT_APP_SSS}/api/getEmployeeAttendanceDetails`;
      // console.log(this.context.user_details)
      const data = {
        sid : this.context.user_details.sid
      };
      var result='';
      axios.post(url,data)
      .then(res =>{
        // console.log(res.data);
        if(res.data.status == "Attendance Fetched"){
          result=res.data.attendance;
          // console.log(result);
          for(var i=0;i<result.length;i++){
            var id = result[i]['id'];
            var date = result[i]['date'];
            var type = result[i]['type'];
            obj_this.state.attendancelist.push({ id, date,type})
          }
          obj_this.state.attendancelist.splice(0, 1);
          obj_this.context.loading(0);
          // console.log(obj_this.state.attendancelist);

        }
        else{
          alert('Fetch failed');
        }
      }
      );

      const url3=`${process.env.REACT_APP_SSS}/api/getLeaveTypes`;
      axios.post(url3,data)
      .then(res3=>{
        if(res3.data.status== "success"){
          obj_this.state.leave_types=res3.data.leave_types
        }
        else{
          alert("fetch failed");
        }
        obj_this.context.loading(0);
        // console.log(obj_this.state.leave_types);
      });


      const url2 = `${process.env.REACT_APP_SSS}/api/getEmployeeLeaveRequestDetails`;

      var result2='';
      axios.post(url2,data)
      .then(res2 =>{
        // console.log(res.data);
        if(res2.data.status == "Leave Request Fetched"){
          result2=res2.data.leave;
          // console.log(result2);
          for(var i=0;i<result2.length;i++){
            var id = result2[i]['id'];
            var from_date = result2[i]['from_date'];
            var to_date = result2[i]['to_date'];
            var no_of_days=result2[i]['no_of_days'];
            var leave_type=result2[i]['leave_type'];
            var reason=result2[i]['reason']
            var status = result2[i]['status'];
            obj_this.state.leavelist.push({ id, from_date,to_date,no_of_days,leave_type,reason,status})
          }
          obj_this.state.leavelist.splice(0, 1);
          obj_this.context.loading(0);
          // console.log(obj_this.state.leavelist);

        }
        else{
          alert('Fetch failed');
        }
      });



    }

    formChange(e){
      this.setState({
        [e.target.name] : e.target.value
      });

      // console.log(this.state);
    }
    LeaveChange(value){
      if(value){
      this.setState({
        leave_type :value
      })
      }
      else{
        alert("Select Leave type")
      }
    }

    typeChange(e){
      this.setState({
        type : e.target.value
      });
    } 
    
    onDateChange(value){
      if(value!=null){
      this.setState({date : value});
      // console.log(value);
      }
    }

    onFromChange(value){
      this.no_of_days=0
      if(value!=null){
      
        this.setState({from_date : value});
        var date1 = new Date(value);
        var date2 = new Date(this.state.to_date);

        var days = Math.abs(date2 - date1)
        var day = days / (1000 * 60 * 60 * 24);
        // alert(day);
        // var d = new Date(date2 - date1).getDate();
        
        this.getHalfDay(day);
      }
    }

    getHalfDay(d) {
      var h1 = document.querySelector('.from').checked;
      var h2 = document.querySelector('.to').checked;
      if(h1) {
        d = .5;
      }
      if(h2) {
        d = .5;
      }
      if(isNaN(d)) {
        d = "";
      }
      this.setState({
        no_of_days: d
      });
    }
    

    onToChange(value){
      this.no_of_days=0
      if(value!=null){
      this.setState({to_date : value});
      var date1 = new Date(this.state.from_date);
      var date2 = new Date(value);
    
      var days = Math.abs(date2 - date1)
      var day = days / (1000 * 60 * 60 * 24);
      this.getHalfDay(day);
      }
    }

     fromHalfDay(e) {
      if(this.state.from_date){
      const checked = e.target.checked;
      if (checked) {
        if(this.state.no_of_days==0){
        this.setState({
          no_of_days: (.5)
        });
        }
      } else {
        if(this.state.no_of_days==0){
        this.setState({
          no_of_days: (.5)
        });      
        }
      }
      }
    };
    toHalfDay(e) {
      if(this.state.to_date){
      const checked = e.target.checked;
      if (checked) {
        if(this.state.no_of_days==0){
        this.setState({
          no_of_days: (.5)
        });
      }
      } else {
        if(this.state.no_of_days==0){
          this.setState({
            no_of_days: (.5)
          });
        }
      }
      }
    };

    formSubmit(e){

      e.preventDefault();
      this.state.leavelist=[];
      this.state.attendancelist=[];
      var obj_this = this;

      if(this.state.type=='LEAVE' && !this.state.leave_type.value){
        this.LeaveTypeChange.current.focus();
       return;
     }

      obj_this.context.loading(0);
      this.context.loading(1);

      var sid =  obj_this.context.user_details.sid;
      var fd = new FormData(document.querySelector('form'));
      fd.append('no_of_days',this.state.no_of_days);

      fd.append('leave_type',this.state.leave_type.value)

      fd.append('sid',sid);

      const url = `${process.env.REACT_APP_SSS}/api/save/employee/attendance/leave/details`;

      axios.post(url,fd)
      .then(res =>{
        // console.log(res.data);
        if(res.data.status == "Attendance"){
          alert("Attendance added.");
          obj_this.context.loading(0);
          obj_this.props.history.replace('/dashboard');
        }
        else if(res.data.status == "Already"){
              alert("Attendance already added.");
              obj_this.context.loading(0);
              obj_this.props.history.replace('/dashboard');
        }
        else if(res.data.status == "Leave"){
          alert("Leave request added successfully.");
          obj_this.context.loading(0);
          obj_this.props.history.replace('/dashboard');
        }
        else {
          alert("Network Error.");
          obj_this.context.loading(0);
        }
      });
    }    

  render(){

  return (

    <MyContext.Consumer>
      {context => (
        <div className="new_marketing_attendance_container">
          <div className="page_header">
            <Link className="back_button" to="/dashboard"><i className="fas fa-arrow-left"></i></Link>
            <div className="page_title">ADD ATTENDANCE AND LEAVE</div>
          </div> 
          <form className="dealer_form"
            onSubmit={e => this.formSubmit(e)} encType="multipart/form-data" 
          >
            <div className="product_details_container blocks">

              <div className="input_label_head">{this.state.type == "PRESENT"?"Attendance Details":"Leave Details"}</div>
              <div className="input_radio_container">
                    <div className="lead_type_radio_buttons">
                      <div className="label_radio_container">
                        <input type="radio" 
                          name="type" 
                          value="PRESENT"  
                          checked={this.state.type == 'PRESENT'} 
                          onChange={e => this.typeChange(e)}
                        />
                        <div>PRESENT</div>
                      </div>
                      <div className="label_radio_container">
                        <input type="radio" 
                          name="type" 
                          value="LEAVE"
                          checked={this.state.type == 'LEAVE'} 
                          onChange={e => this.typeChange(e)}
                        />
                        <div>LEAVE</div>
                      </div>
                    </div>
                </div>
                <div style={{display:this.state.type == "PRESENT"?"block":"none"}}>
   
                  <div className="input_label">Date *</div>
                <DatePicker
                  className="input custom_input transaction_date"
                  name="date"
                  onChange={(value) => this.onDateChange(value)}
                  value={this.state.date}
                  format="dd-MM-y"
                  minDate={new Date()}
                  maxDate = {new Date()}
                  required={this.state.type=="PRESENT"}
                /> 
                </div>
                <div style={{display:this.state.type == "LEAVE"?"block":"none"}}>
                  <div className="input_label">Leave Type</div>
                  <Select 
                  options={this.state.leave_types}  
                  value ={this.state.leave_type}
                  className="dealer" 
                  ref={this.LeaveTypeChange}
                  onChange={e => this.LeaveChange(e)}
                  required={this.state.type=="LEAVE"}
                  />
                  
                  <span>
                <div className="input_label">From Date *</div>
                <DatePicker
                  className="input custom_input_date transaction_date"
                  name="from_date"
                  onChange={(value) => this.onFromChange(value)}
                  value={this.state.from_date}
                  minDate={new Date(new Date().toDateString())}
                  format="dd-MM-y"
                  required={this.state.type=="LEAVE"}
                /> 
                 <input type="checkbox" 
                      className="from"
                      name="" 
                      value="" 
                      onClick={e => this.fromHalfDay(e)}

                                       /> 
                  <span className="input_label">Half Day </span>

                </span>
                <div className="input_label">To Date *</div>
                <DatePicker
                  className="input custom_input_date transaction_date"
                  name="to_date"
                  onChange={(value) => this.onToChange(value)}
                  value={this.state.to_date}
                  format="dd-MM-y"
                  disabled={!this.state.from_date}
                  minDate = {this.state.from_date}
                  required={this.state.type=="LEAVE" && this.state.from_date}
                /> 
                <input type="checkbox" 
                      className="to"
                      name="" 
                      value="" 
                      onClick={e => this.toHalfDay(e)}
                    /> 
                  <span className="input_label">Half Day </span>
                  <div className="input_label">Total Days *</div>
                  <input className="name input custom_input"
                    type="number"
                    name="no_of_days"
                    disabled = {true}
                    value={this.state.no_of_days}
                    onChange={this.formChange}
                    required={this.state.type=="LEAVE"}
                    autoComplete="off"
                  />
                 <div className="input_label">Reason *</div>
                  <textarea className="input billing_address custom_input" 
                    name="reason"
                    value={this.state.reason}
                    onChange={this.formChange}
                    required={this.state.type=="LEAVE"}
                  /> 
                </div>
            </div>

              <button type="submit" className="submit_button"><i className="far fa-save"></i> Save</button>
            </form> 
            <div className="list_blocks_container" style={{display:this.state.type=="PRESENT"?"block":"none"}}>
              <div className="lists_container">
                {this.state.attendancelist.map((item,idx)=>(
                  <div className="single_collection_container" key={idx}>
                    <div className="collection_customer_name_delete_button_container">
                     
                      <div className="attendance_details_status_button_container">
                        <div className="collection_customer_name"><i className="fa fa-calendar-alt"></i> {new Date(item.date).toLocaleDateString('en-GB')}</div>
                        <div className="collection_status" style={{display:item.type == "ATTENDANCE"?"block":"none"}}><i className="fa fa-check" aria-hidden="true"></i>PRESENT</div>
                         
                      </div>
                    </div>
                    </div>

                ))}
              </div>
            </div>  
            <div className="list_blocks_container" style={{display:this.state.type=="LEAVE"?"block":"none"}}>
              <div className="lists_container">
                {this.state.leavelist.map((item,idx)=>(
                  <div className="single_collection_container" key={idx}>
                    <div className="collection_customer_name_delete_button_container">
                    <div className="leave2_details_status_button">
                        <div className="collection_customer_name"># Days : {item.no_of_days}</div>
                        <div className="leave_status_requested" style={{display:item.status=="REQ"?"block":"none"}}><i className="fa fa-clock" aria-hidden="true"></i> REQUESTED</div>
                        <div className="leave_status_approved" style={{display:item.status=="APR"?"block":"none"}}> <i className="fa fa-check" aria-hidden="true"></i>APPROVED</div>
                        <div className="leave_status_rejected" style={{display:item.status=="CNL"?"block":"none"}}> <i className="fa fa-times" aria-hidden="true"></i>REJECTED</div>
                      </div>
                      <div className="leave_details_status_button_container">
                        <div className="leave_date"><i className="fa fa-calendar-alt"></i> {new Date(item.from_date).toLocaleDateString('en-GB')}</div>
                        <div className="divider">---</div>
                        <div className="leave_date"><i className="fa fa-calendar-alt"></i> {new Date(item.to_date).toLocaleDateString('en-GB')}</div>
                      </div>
                      <div className="leave_type">Leave Type : {item.leave_type}</div>
                      <div className="leave2_reason">Reason : {item.reason}</div>
                    </div>
                  </div>

                ))}
              </div>
            </div>             
        </div>
      )}
    </MyContext.Consumer>
  )}
}

export default AttendanceLeave;
AttendanceLeave.contextType = MyContext;
