import React, { Component } from "react";
import {Link} from "react-router-dom";
import './Attachment.scss';

import MyContext from '../MyContext.jsx';


class Attachment extends Component {
  url1 = `${process.env.REACT_APP_SSS}/`;

  constructor(props) {
    super(props);

    this.state = {
      schemes : [],
    }
  }

  componentDidMount(){  
    const url = `${process.env.REACT_APP_SSS}/api/getAttachmentFiles`;
    this.context.loading(1);
    var obj_this = this;
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      if(return_data.status == "success"){
        obj_this.setState({
          schemes: return_data.catalogues,
        })
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      alert("Network error");
    });

    $(".scheme_container").on("click",".asm_download_button",function(e){
      e.stopPropagation();
      var apk_url = obj_this.url1 + this.id;
      console.log(apk_url);
      if(window.cordova)
        var ref = cordova.InAppBrowser.open(apk_url, '_system');
      else{
        alert("Only viewable on Mobile")
      }
    });

  }

  render(){

  return (

    <MyContext.Consumer>
      {context => (
       
        <div className="scheme_container">
        <div className="header_spacer"></div>
            <div className="scheme_attachment_container uijs swipemenu">
        
                <div className="list_blocks_container">
                    <div className="lists_container graph_block">
                        <div className="yellow_shade11">
                          <Link className="back_button" to="/dashboard"><i className="fas fa-arrow-left"></i></Link>
                          <div className="desigtn_block">Catalogue</div>
                        </div>
                        
                        <div style={{paddingTop:"15px"}}>
                            <div className="yellow_shade2">
                                <div className="sale_graph_container2">
                                    <table className="table_list2">
                                        <thead>
                                            <tr>
                                                <th valign="top" align="center" >Sl.No</th>
                                                <th valign="top" align="center" >Scheme Name</th>
                                                <th valign="top" align="center" >Attachment</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        <tr style={{display:this.state.schemes.length?"none":"table-row"}}>
                                         <td colSpan="3" align="center">No result!</td>
                                        </tr> 
                                        {this.state.schemes.map((item,index) => (
                                        <tr key={index}>
                                            <td align="center" valign="bottom" ><span>{index+1}</span></td>
                                            <td valign="bottom" ><span>{item.catalogue_name}</span></td>
                                            <td align="center" valign="bottom" >
                                              <span className="asm_download_button" id = {item.file_path}>
                                                <i className="fa fa-file" ></i>&nbsp;View
                                              </span>
                                            </td>
                                        </tr> ))}     
                                        
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
        
                    </div>
        
        
        
                </div>
        
            </div>
            <div className="bottom_navbar_spacer"></div>
        </div>
        
      )}
    </MyContext.Consumer>
  )}
}

export default Attachment;
Attachment.contextType = MyContext;
