import React, { Component } from "react";
import {Link} from "react-router-dom";
import DatePicker from 'react-date-picker';
import Select from "react-select";
import subDays from "date-fns/subDays";

import './NewSecondarySale.scss';
import MyContext from '../MyContext.jsx';


class NewSecondarySale extends Component {
  constructor(props) {
    super(props);
    this.customerSelect = React.createRef();
    this.state = {
      customers:{},
      customer : '',
      sale_date  : new Date(),
      amount: '',
    }
    this.formChange = this.formChange.bind(this);
  }

  componentDidMount(){   
    const url = `${process.env.REACT_APP_SSS}/api/get/secondarySaleDealers`;
      this.context.loading(1);
      var obj_this = this;
      $.ajax({
        url : url,
        data : {
          sid : this.context.user_details.sid,
        },
        type : "POST"
      }).done(function(return_data){
        console.log(return_data);
        if(return_data.status == "success"){
          obj_this.setState({
            customers : return_data.customers,
          });
          obj_this.context.loading(0);
        }else{
          obj_this.context.loading(0);
          obj_this.props.history.replace("/");
        }
      }).fail(function(){
        obj_this.context.loading(0);
        alert("Network error");
      });
     
  }

  formChange(e){
    this.setState({
      [e.target.name] : e.target.value
    });
  }

  customerChange(option){
    this.setState({
      customer : option
    });
  }

  onDateChange(value){
    // console.log(value);
    this.setState({sale_date : value});
  }



  formSubmit(e){
    e.preventDefault();
    // console.log(this.state);
    // return;
    var secondarysale = this.state;
    var obj_this = this;
    secondarysale.s_date=new Date(secondarysale.sale_date).toLocaleDateString('en-CA');
    if(!this.state.customer.value){
      this.customerSelect.current.focus();
      return;
    }
    const url = `${process.env.REACT_APP_SSS}/api/save/SaveSecondarySale`;
    this.context.loading(1);
    secondarysale.customers={};
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid,
        secondary_sale :  secondarysale
      },
      type : "POST"
    }).done(function(return_data){
      // console.log(return_data);
      // return;
      if(return_data.status == "success"){
        alert("Secondary Sale saved");
        obj_this.context.loading(0);
        obj_this.props.history.replace('/secondarysale');
      }
      else{
        alert("Something went wrong");
        obj_this.context.loading(0);
        obj_this.props.history.replace("/dashboard");
      }
    }).fail(function(){
      obj_this.context.loading(0);
      alert("Network error");
      obj_this.props.history.replace("/dashboard");

    });
  }

  render(){
  return (
    <MyContext.Consumer>
      {context => (
        <div className="new_secondary_sale_container">
          <div className="page_header">
            <Link className="back_button" to="/dashboard"><i className="fas fa-arrow-left"></i></Link>
            <div className="page_title">New Secondary Sale</div>
          </div> 
          <form className="schedule_form"
            onSubmit={e => this.formSubmit(e)}
          >
            <div className="customer_details_container blocks">
            <div className="customer_container">

            <div className="input_label">Customer</div>
            <Select
                  options={this.state.customers}  
                  value ={this.state.customer}
                  className="customer" 
                  ref={this.customerSelect}
                  onChange={e => this.customerChange(e)}
                  // isSearchable={false}
                  required
                />
            <div className="input_label">Date</div>
              <DatePicker
                className="input custom_input"
                onChange={(value) => this.onDateChange(value)}
                value={this.state.sale_date}
                minDate = {subDays(new Date(), 7)}
                required
                format="dd-MM-y"
              />
            <div className="input_label">Amount</div>
              <input className="billing_contact_no input custom_input"
                type="number"
                name="amount"
                value={this.state.amount}
                onChange={this.formChange}
                required
                autoComplete="off"
                min="0"
                step=".01"
              />
          
            </div>
            <button type="submit" className="submit_button">
            <i className="far fa-save"></i> {"Submit"}
            </button>
            </div>
          </form>           
        </div>
      )}
    </MyContext.Consumer>
  )}
}

export default NewSecondarySale;
NewSecondarySale.contextType = MyContext;
