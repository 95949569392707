import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import MyProvider from "./components/MyProvider.jsx";
import { useHistory} from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import $ from 'jquery';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './index.css';
window.jQuery = $;
window.$ = $;
global.jQuery = $;
import './assets/js/jquery_mods.js';
import './assets/js/ui.js';
import './assets/js/geo.js';
import './assets/scss/ui.scss';

const initializeFirebaseNotifications = (handleNotificationClick) => {
  document.addEventListener('deviceready', () => {
    if (window.cordova) {
      // Handle launchDetails if app was launched by a notification
      const launchDetails = cordova.plugins.notification.local.launchDetails;
      console.log(launchDetails);
      if (launchDetails && launchDetails.data) {
        console.log('App launched by notification:', launchDetails);
        handleNotificationClick(launchDetails.data);
      }

      cordova.plugins.firebase.messaging.onMessage((payload) => {
        // console.log("Foreground notification received: ", payload);
        console.log("Payload attributes:", JSON.stringify(payload, null, 2));
        if (payload.gcm) {
          cordova.plugins.notification.local.schedule({
            title: payload.gcm.title,
            text: payload.gcm.body,
            data: payload.data,
          });
        }
      });

      cordova.plugins.notification.local.on('click', (notification) => {
        console.log('Notification clicked: ', JSON.stringify(notification, null, 2));
        let payload = notification.data;
        if (typeof payload === 'string') {
          payload = JSON.parse(payload);
        }
        handleNotificationClick(payload);
      });

      cordova.plugins.firebase.messaging.onBackgroundMessage((payload) => {
        console.log("Background notification received:  on Background", JSON.stringify(payload, null, 2));
        handleNotificationClick(payload);
      });

      // Process any queued events
      cordova.plugins.notification.local.fireQueuedEvents();
    }
  }, false);
};

const requestLocalNotificationPermission = () => {
  if (window.cordova) {
    cordova.plugins.notification.local.hasPermission((granted) => {
      if (!granted) {
        cordova.plugins.notification.local.requestPermission((granted) => {
          if (granted) {
            console.log("Local notification permission granted.");
          } else {
            console.log("Local notification permission denied.");
          }
        });
      } else {
        console.log("Local notification permission already granted.");
      }
    });
  }
};

const NotificationHandler = () => {
  const history = useHistory();

  useEffect(() => {
    requestLocalNotificationPermission();

    const handleNotificationClick = (payload) => {
      console.log("Notification payload:", JSON.stringify(payload, null, 2));
      let dataload = payload;
      if (typeof dataload === 'string') {
        dataload = JSON.parse(dataload);
      }
      const actionURLString = dataload?.actionURL;
      let actionapp;

      if (typeof actionURLString === 'string') {
        try {
          const actionURL = JSON.parse(actionURLString);
          actionapp = actionURL?.app;
        } catch (e) {
          console.error("Failed to parse actionURL:", e);
        }
      } else {
        const actionURL = actionURLString;
        actionapp = actionURL?.app;
      }

      const route = actionapp || '/';
      history.push(route);
    };

    initializeFirebaseNotifications(handleNotificationClick);
  }, [history]);

  return null;
};

const renderReactDom = () => {
  ReactDOM.render(
    <MyProvider>
      <BrowserRouter>
        <NotificationHandler />
        <App />
      </BrowserRouter>
    </MyProvider>,
    document.getElementById('root')
  );
};

if (window.cordova) {
  window.project_path = "/android_asset/www";
  document.addEventListener('deviceready', () => {
    initializeFirebaseNotifications();
    renderReactDom();
  }, false);
} else {
  window.project_path = "";
  renderReactDom();
}
reportWebVitals();