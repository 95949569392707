import React, { Component } from "react";
import {Link} from "react-router-dom";
import { FiChevronDown } from 'react-icons/fi';

import './Orders.scss';
import MyContext from '../MyContext.jsx';


class SampleRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      req_samplerequests : [],
      pend_samplerequests : [],
      sr_confirmed_samplerequests : [],
      cancelled_samplerequests : [],
      samplerequest_id : '',
      so_reject_reason : '',
    }
    this.formChange = this.formChange.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
  }

  componentDidMount(){    
    var obj_this = this;
    const url = `${process.env.REACT_APP_SSS}/api/getExeSampleRequestorders`;
    this.context.loading(1);
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      // return;
      if(return_data.status == "success"){ 
        obj_this.setState(return_data.samplerequests);
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      obj_this.context.loading(0);
      alert("Network error");
    });

    // $(".sales_orders_page_container").on("click",".so_approve_button",function(e){
    //   e.stopPropagation();
    //   if(!confirm("Do you want to confirm this order")){
    //     return;
    //   }
    //   const samplerequest_id = $(this).attr("samplerequest_id");
    //   const url = `${process.env.REACT_APP_SSS}/api/SampleRequestApproval`;
    //   obj_this.context.loading(1);
    //   $.ajax({
    //     url : url,
    //     data : {
    //       sid : obj_this.context.user_details.sid,
    //       sm_approval : obj_this.context.user_details.sm_approval,
    //       samplerequest_id : samplerequest_id
    //     },
    //     type : "POST"
    //   }).done(function(return_data){
    //       // console.log(return_data);
    //     if(return_data.status == "success"){
    //       obj_this.setState(return_data.samplerequests,()=>{
    //         $(".sales_orders_page_container .single_so_container").css("height","50px").find(".arrow_down").css("transform","rotate(0deg)");
    //       });
    //       obj_this.context.loading(0);
    //       alert("Samples Requested");
    //     }else{
    //       obj_this.context.loading(0);
    //       obj_this.props.history.replace("/");
    //     }
    //   }).fail(function(){
    //     alert("Network error");
    //   });

    // });

    $(".sales_orders_page_container").on("click",".so_edit_button",function(e){
       e.stopPropagation();
       const samplerequest_id = $(this).attr("samplerequest_id");
       obj_this.props.history.push("/samplerequest/edit/"+samplerequest_id);
    });

    $(".sales_orders_page_container").on("click",".single_so_container",function(){
      if($(this).outerHeight() == "50"){
        $(this).heightAuto();
        $(this).find(".arrow_down").css("transform","rotate(180deg)");
      }else{
        $(this).css("height","50px");
        $(this).find(".arrow_down").css("transform","rotate(0deg)");
      }
    });

    $(".sales_orders_page_container").on("click",".asm_reject_button",function(e){
      e.stopPropagation();
      obj_this.setState({
        samplerequest_id : $(this).attr("samplerequest_id")
      });
      $(".close_form_ghost").css("display","block");

    });

    $(".close_form_ghost .close_button").on("click",function(){
      $(".close_form_ghost").css("display","none");
    });


    ui.swipeMenu(".sales_orders_container");
  }

  formChange(e){
    this.setState({
      [e.target.name] : e.target.value
    });
  }

  formSubmit(e){
    e.preventDefault();
    console.log(this.state);
    var obj_this = this;
    const url = `${process.env.REACT_APP_SSS}/api/sampleRequestCancel`;
    obj_this.context.loading(1);
    $(".close_form_ghost").css("display","none");
    $.ajax({
      url : url,
      data : {
        sid : obj_this.context.user_details.sid,
        so_reject_reason : obj_this.state.so_reject_reason,
        samplerequest_id : obj_this.state.samplerequest_id
      },
      type : "POST"
    }).done(function(return_data){
        // console.log(return_data);
      if(return_data.status == "success"){
        obj_this.setState(return_data.samplerequests,()=>{
          $(".sales_orders_page_container .single_so_container").css("height","50px").find(".arrow_down").css("transform","rotate(0deg)");
        });
        obj_this.context.loading(0);
        alert("Sample Request Cancelled.")
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      alert("Network error");
    });

  }
  render(){
  return (
    <MyContext.Consumer>
      {context => (
        <div className="sales_orders_page_container noselect">
          <div className="header_spacer"></div>
          <div className="sales_orders_container uijs swipemenu">
            <div className="page_header">
              <div className="page_title">Sample Request List</div>
              <Link className="new_sales_order_button" 
                to="/samplerequest/new"
                style={{visibility:this.context.user_details.doctor_visit?"visible":"hidden"}}
              > <i className="fa fa-plus plus_icon"></i> New</Link>
            </div>

            <div className="toggles_container">
              <div className="list_1 toggles" menu="0">
                <i className="fa fa-clipboard-list order_status_icon"></i>
                <div className="order_status">Request</div>
                <div className="order_count">{this.state.req_samplerequests.length}</div>
              </div>
              {/* <div className="list_2 toggles" menu="1" onClick={e => e.preventDefault()} >
                <i className="fa fa-hourglass-start order_status_icon"></i>
                <div className="order_status">Pending</div>
                <div className="order_count">{this.state.pend_samplerequests.length}</div>
              </div> */}
              <div className="list_3 toggles" menu="2">
                <i className="fa fa-box order_status_icon"></i>
                <div className="order_status">SR Approved</div>
                <div className="order_count">{this.state.sr_confirmed_samplerequests.length}</div>
              </div>
              <div className="list_4 toggles" menu="3">
                <i className="fa fa-times order_status_icon"></i>
                <div className="order_status">Cancelled</div>
                <div className="order_count">{this.state.cancelled_samplerequests.length}</div>
              </div>
            </div>

            <div className="list_blocks_container">
              <div className="lists_container">
                {this.state.req_samplerequests.map((item,idx)=>(
                  <div className="single_so_container" key={idx}>
                    <div className="so_details_container">
                      <div>
                        {/* <div className="so_customer_name"><i className="fa fa-user"></i> {item.name}</div> */}
                        <div className="so_customer_name">Order date : {item.order_date}</div>
                      </div>
                      {/* <div className="so_approve_button"
                        samplerequest_id = {item.id}
                        style={{visibility:this.context.user_details.place_order || this.context.user_details.sm_approval?"visible":"hidden"}}
                      >
                        <i className="fas fa-check"></i>
                      </div> */}
                      <div className="so_edit_button" 
                        samplerequest_id ={item.id}
                         style={{display:this.context.user_details.sm_approval ?"block":"none"}}
                      >
                        <i className="fa fa-pencil-alt"></i>
                      </div>
                      <div className="asm_reject_button"
                        samplerequest_id = {item.id}
                         style={{display:this.context.user_details.sm_approval?"block":"none"}}
                      >
                        <i className="fas fa-times"></i>
                      </div>
                      
                      <div className="so_products_button">
                        <FiChevronDown className="arrow_down" />
                      </div>
                    </div>
                    <table className="so_products_container" style={{display:item.products.length?"table":"none"}}>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Product</th>
                          <th >Qty</th>
                          <th>Unit</th>
                          <th align="center">Unit Price</th>
                          <th align="center">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                      {item.products.map((product,index) => (
                        <tr key={index}>
                          <td valign="top">{index+1}</td>
                          <td valign="top">{product.product_specification == null || product.product_specification == ""?product.name:product.name+"/"+product.product_specification }</td>
                          <td valign="top">{product.qty}</td>
                          <td valign="top">{product.unit}</td>
                          <td valign="top" align="right">{product.unit_price}</td>
                          <td align="right" valign="top">{product.total}</td>
                        </tr>
                      ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th colSpan="5" align="right">Total :</th>
                          <th align="right">{parseFloat(item.products.reduce((total, product) => total + parseFloat(product.total), 0)).toFixed(2)}</th>
                        </tr>
                        <tr style={{display:item.tax?"table-row":"none"}}>
                          <th colSpan="5" align="right">Tax : </th>
                          <th align="right">{item.tax}</th>
                        </tr>
                        <tr style={{display:item.grand_total?"table-row":"none",fontSize : "12px",color : "#ea8000"}}>
                          <th colSpan="5" align="right">Grand Total (Rs) :</th>
                          <th align="right">{item.grand_total}</th>
                        </tr>
                      </tfoot>
                    </table>

                  </div>
                ))}
              </div>
              <div className="lists_container">
                {this.state.pend_samplerequests.map((item,idx)=>(
                  <div className="single_so_container" key={idx}
                    style={{backgroundColor:item.status == "HOLD"?"rgba(255, 255, 0, 0.31)":item.status == "SOCANCEL"?"rgba(255, 0, 0, 0.26)":""}}
                  >
                    <div className="so_details_container">
                      <div>
                        {/* <div className="so_customer_name"><i className="fa fa-user"></i> {item.name}</div> */}
                        <div className="so_customer_name">Order date : {item.order_date}</div>
                      </div>
                      <div></div>
                      <div></div>
                      
                      <div className="so_products_button">
                        <FiChevronDown className="arrow_down" />
                      </div>
                    </div>
                    <table className="so_products_container" style={{display:item.products.length?"table":"none"}}>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Product</th>
                          <th>Qty</th>
                          <th>Unit</th>
                          <th align="center">Unit Price</th>
                          <th align="center">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                      {item.products.map((product,index) => (
                        <tr key={index}>
                          <td valign="top">{index+1}</td>
                          <td valign="top">{product.product_specification == null || product.product_specification == ""?product.name:product.name+"/"+product.product_specification}</td>
                          <td valign="top">{product.qty}</td>
                          <td valign="top">{product.unit}</td>
                          <td valign="top" align="right">{product.unit_price}</td>
                          <td align="right" valign="top">{product.total}</td>
                        </tr>
                      ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th colSpan="5" align="right">Total :</th>
                          <th align="right">{parseFloat(item.products.reduce((total, product) => total + parseFloat(product.total), 0)).toFixed(2)}</th>
                        </tr>
                        {/*<tr style={{display:item.tax?"table-row":"none"}}>
                          <th colSpan="5" align="right">Tax : </th>
                          <th align="right">{item.tax}</th>
                        </tr>
                        <tr style={{display:item.grand_total?"table-row":"none",fontSize : "12px",color : "#ea8000"}}>
                          <th colSpan="5" align="right">Grand Total (Rs) :</th>
                          <th align="right">{item.grand_total}</th>
                        </tr>*/}
                      </tfoot>
                    </table>

                  </div>
                ))}
              </div>
              <div className="lists_container">
                {this.state.sr_confirmed_samplerequests.map((item,idx)=>(
                  <div className="single_so_container" key={idx}>
                    <div className="so_details_container">
                      <div>
                        {/* <div className="so_customer_name"><i className="fa fa-user"></i> {item.name}</div> */}
                        <div className="so_customer_name">Order date : {item.order_date}</div>
                      </div>
                      <div></div>
                      <div></div>
                      
                      <div className="so_products_button">
                        <FiChevronDown className="arrow_down" />
                      </div>
                    </div>
                    <table className="so_products_container" style={{display:item.products.length?"table":"none"}}>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Product</th>
                          <th>Qty</th>
                          <th>Unit</th>
                          <th align="center">Unit Price</th>
                          <th align="center">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                      {item.products.map((product,index) => (
                        <tr key={index}>
                          <td valign="top">{index+1}</td>
                          <td valign="top">{product.product_specification == null || product.product_specification == ""?product.name:product.name+"/"+product.product_specification }</td>
                          <td valign="top">{product.qty}</td>
                          <td valign="top">{product.unit}</td>
                          <td valign="top" align="right">{product.unit_price}</td>
                          <td align="right" valign="top">{product.total}</td>
                        </tr>
                      ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th colSpan="5" align="right">Total :</th>
                          <th align="right">{parseFloat(item.products.reduce((total, product) => total + parseFloat(product.total), 0)).toFixed(2)}</th>
                        </tr>
                        <tr style={{display:item.tax?"table-row":"none"}}>
                          <th colSpan="5" align="right">Tax : </th>
                          <th align="right">{item.tax}</th>
                        </tr>
                        <tr style={{display:item.grand_total?"table-row":"none",fontSize : "12px",color : "#ea8000"}}>
                          <th colSpan="5" align="right">Grand Total (Rs) :</th>
                          <th align="right">{item.grand_total}</th>
                        </tr>
                      </tfoot>
                    </table>

                  </div>
                ))}
              </div>
              <div className="lists_container">
                {this.state.cancelled_samplerequests.map((item,idx)=>(
                  <div className="single_so_container" key={idx}>
                    <div className="so_details_container">
                      <div>
                        {/* <div className="so_customer_name"><i className="fa fa-user"></i> {item.name}</div> */}
                        <div className="so_customer_name">Order date : {item.order_date}</div>
                      </div>
                      <div></div>
                      <div></div>
                      
                      <div className="so_products_button">
                        <FiChevronDown className="arrow_down" />
                      </div>
                    </div>
                    <table className="so_products_container" style={{display:item.products.length?"table":"none"}}>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Product</th>
                          <th>Qty</th>
                          <th>Unit</th>
                          <th align="center">Unit Price</th>
                          <th align="center">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                      {item.products.map((product,index) => (
                        <tr key={index}>
                          <td valign="top">{index+1}</td>
                          <td valign="top">{product.product_specification == null || product.product_specification == ""?product.name:product.name+"/"+product.product_specification }</td>
                          <td valign="top">{product.qty}</td>
                          <td valign="top">{product.unit}</td>
                          <td valign="top" align="right">{product.unit_price}</td>
                          <td align="right" valign="top">{product.total}</td>
                        </tr>
                      ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th colSpan="5" align="right">Total :</th>
                          <th align="right">{parseFloat(item.products.reduce((total, product) => total + parseFloat(product.total), 0)).toFixed(2)}</th>
                        </tr>
                        <tr style={{display:item.tax?"table-row":"none"}}>
                          <th colSpan="5" align="right">Tax : </th>
                          <th align="right">{item.tax}</th>
                        </tr>
                        <tr style={{display:item.grand_total?"table-row":"none",fontSize : "12px",color : "#ea8000"}}>
                          <th colSpan="5" align="right">Grand Total (Rs) :</th>
                          <th align="right">{item.grand_total}</th>
                        </tr>
                      </tfoot>
                    </table>

                  </div>
                ))}
              </div>

            </div>
            <div className="close_form_ghost">
              <form className="close_form" onSubmit={this.formSubmit} method="post">
                <i className="close_button fas fa-times"></i>
                <div className="input_label">Reason</div>
                <textarea className="custom_input so_reject_reason"
                  name="so_reject_reason"
                  onChange={this.formChange}
                  required  
                />
                <button className="confirm_reject_button">CONFIRM</button>
              </form>
            </div>
            
          </div>
          <div className="bottom_navbar_spacer"></div>

        </div>
      )}
    </MyContext.Consumer>
  )}
}

export default SampleRequest;
SampleRequest.contextType = MyContext;
