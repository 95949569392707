import {Component} from "react";
import './TravelAllowanceApproval.scss';
import {Link} from "react-router-dom";
import MyContext from '../MyContext.jsx';
import Select from "react-select";

class TravelAllowanceApproval extends Component{
  constructor(props){
    super(props);
    this.state = {
      allowances : [],
      executives  : [],
      executive : ''
    }
  }
  componentDidMount(){
    this.context.loading(1);
    var obj_this = this;
    $.ajax({
      url : process.env.REACT_APP_SSS + "/api/get/TravelAllowancesApprovalList",
      data : {
        sid : this.context.user_details.sid
      },
      type : "POST"
    }).done(function(return_data){
      //console.log(return_data);
      if (return_data.status == "success"){
        obj_this.setState({
          allowances : return_data.travel_allowance,
          executives  : return_data.executives,
        });
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.push("/");
      }
    }).fail(function(){
      alert("Network error");
    });

    $(".travel_allowance_approval_page_container").on("click",".allowance_approve_button",function(e){
      e.stopPropagation();
      if(!confirm("Do you want to approve this allowance?")){
        return;
      }
      const allowance_id = $(this).attr("allowance_id");
      obj_this.setState({
        allowance_id : $(this).attr("allowance_id")
      });
      const url = `${process.env.REACT_APP_SSS}/api/allowanceRequestApproval`;
      obj_this.context.loading(1);
      $.ajax({
        url : url,
        data : {
          sid : obj_this.context.user_details.sid,
          allowance_id : allowance_id,
        },
        type : "POST"
      }).done(function(return_data){
          //console.log(return_data);
        if(return_data.status == "success"){
          obj_this.afterExecutiveChange();
          obj_this.context.loading(0);
          alert("Allowance Approved.")
        }else{
          obj_this.context.loading(0);
          obj_this.props.history.replace("/");
        }
      }).fail(function(){
        obj_this.context.loading(0);
        alert("Network error");
      });
  });
  $(".travel_allowance_approval_page_container").on("click",".allowance_approve_all_button",function(e){
    e.stopPropagation();
    if(!confirm("Do you want to approve all allowances?")){
      return;
    }
    let allowance_ids=null;
    let requested_allowances = obj_this.state.allowances.filter((item) => item.ta_status == "DRAFT");
    allowance_ids=requested_allowances.map(item=>item.emp_ta_id);
    const url = `${process.env.REACT_APP_SSS}/api/approveAll/allAllowanceRequestApproval`;
    obj_this.context.loading(1);
    $.ajax({
      url : url,
      data : {
        sid : obj_this.context.user_details.sid,
        allowance_ids : allowance_ids,
      },
      type : "POST"
    }).done(function(return_data){
        //console.log(return_data);
      if(return_data.status == "success"){
        obj_this.afterExecutiveChange();
        obj_this.context.loading(0);
        alert("Allowance Approved.")
      }else if(return_data.status == "fail"){
        obj_this.context.loading(0);
        alert("No Allowance to Approve");
      }
      else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      obj_this.context.loading(0);
      alert("Network error");
    });
  });

  $(".travel_allowance_approval_page_container").on("click",".allowance_cancel_all_button",function(e){
    e.stopPropagation();
    if(!confirm("Do you want to reject all allowances?")){
      return;
    }
    let allowance_ids=null;
    let requested_allowances = obj_this.state.allowances.filter((item) => item.ta_status == "DRAFT");
    allowance_ids=requested_allowances.map(item=>item.emp_ta_id);
    const url = `${process.env.REACT_APP_SSS}/api/rejectAll/allAllowanceRequestReject`;
    obj_this.context.loading(1);
    $.ajax({
      url : url,
      data : {
        sid : obj_this.context.user_details.sid,
        allowance_ids : allowance_ids,
      },
      type : "POST"
    }).done(function(return_data){
        //console.log(return_data);
      if(return_data.status == "success"){
        obj_this.afterExecutiveChange();
        obj_this.context.loading(0);
        alert("Allowance Rejected.")
      }else if(return_data.status == "fail"){
        obj_this.context.loading(0);
        alert("No Allowance to Reject");
      }
      else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      obj_this.context.loading(0);
      alert("Network error");
    });
  });

  $(".travel_allowance_approval_page_container").on("click",".allowance_cancel_button",function(e){
    e.stopPropagation();
    if(!confirm("Do you want to reject this allowance?")){
      return;
    }
    const allowance_id = $(this).attr("allowance_id");
    obj_this.setState({
      allowance_id : $(this).attr("allowance_id")
    });
    const url = `${process.env.REACT_APP_SSS}/api/allowanceRequestReject`;
    obj_this.context.loading(1);
    $.ajax({
      url : url,
      data : {
        sid : obj_this.context.user_details.sid,
        allowance_id : allowance_id,
      },
      type : "POST"
    }).done(function(return_data){
        //console.log(return_data);
      if(return_data.status == "success"){
        obj_this.afterExecutiveChange();
        obj_this.context.loading(0);
        alert("Allowance Rejected.")
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      obj_this.context.loading(0);
      alert("Network error");
    });
});

  }

  executiveChange(value){
    this.setState({
      executive : value
    }, () => {
      this.afterExecutiveChange();
  });
  }
  
  afterExecutiveChange(){
    var obj_this = this;
      const url = `${process.env.REACT_APP_SSS}/api/get/TravelAllowancesApprovalList`;
      this.context.loading(1);
      $.ajax({
        url : url,
        data : {
          sid : this.context.user_details.sid,
          executive_id  : obj_this.state.executive.value
        },
        type : "POST"
      }).done(function(return_data){
        //console.log(return_data);
        if(return_data.status == "success"){ 
          
          obj_this.setState({
            allowances :return_data.travel_allowance,
          });
  
          obj_this.context.loading(0);
          //console.log(obj_this.state);
        }else{
          obj_this.context.loading(0);
          obj_this.props.history.replace("/");
        }
      }).fail(function(){
        obj_this.context.loading(0);
        alert("Network error");
      });
  
  
  }

  render(){
    return(
      <div className="travel_allowance_approval_page_container">
         <div className="header_spacer"></div>
          <div className="travel_allowances_container">
            <div className="page_header">
              <div className="page_title">Travel allowances</div>
              <Select 
              options = {this.state.executives}
              value = {this.state.executive}
              onChange={value=>this.executiveChange(value)}
              />
            </div>
            <div className="current_month">{new Date().toLocaleString('default', { month: 'long' })} { new Date().getFullYear()}</div>
            

            <div className="list_blocks_container">
              <div className="lists_container">
              <div className="approve_all_cancel_all_allowance">
                    <div className="allowance_approve_all_button"
                      >
                        <i className="fas fa-check"><span>APPROVE ALL</span></i>
                    </div>
                    <div className="allowance_cancel_all_button"
                      >
                        <i className="fa fa-times-circle"><span>REJECT ALL</span></i>
                    </div>
              </div>
                {this.state.allowances.map((item,idx)=>(
                  <div className="single_collection_container" key={idx}>
                    <div className="collection_customer_name_delete_button_container">
                      <div className="collection_date_container">
                        <div className="collection_no">{idx+1}</div>
                      </div>
                      <div>
                        <div className="collection_customer_name"><i className="fa fa-user"></i> {item.staffname}</div>
                        <div className="collection_details_status_button_container">
                          <div>
                            <div className="col_date">TA Date : {new Date(item.ta_date).toLocaleDateString('en-GB')}</div>
                            <div className="amount">REQ Date : {new Date(item.req_date).toLocaleDateString('en-GB')}</div>
                          </div>
                          <div className="collection_status" >{item.ta_status}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="allowance_div">
                      <div></div>
                      <table className="allowance_container" style={{display:"table"}}>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th >Type</th>
                          <th>Cost</th>
                          {/* <th align="center">Unit Price</th>
                          <th align="center">Total</th> */}
                        </tr>
                      </thead>
                      <tbody>
                      {item.allowance.map((ta_head,index) => (
                        <tr key={index}>
                          <td valign="top">{index+1}</td>
                          <td valign="top">{ta_head.name }</td>
                          {/* <td valign="top">{ta_head.qty}</td> */}
                          {/* <td valign="top">{ta_head.unit}</td> */}
                          <td valign="top" align="right">{ta_head.ta_cost}</td>
                          {/* <td align="right" valign="top">{ta_head.total}</td> */}
                        </tr>
                      ))}
                      </tbody>
                      {/* <tfoot>
                        <tr>
                          <th colSpan="5" align="right">Total :</th>
                          <th align="right">{parseFloat(item.products.reduce((total, product) => total + parseFloat(product.total), 0)).toFixed(2)}</th>
                        </tr>
                        <tr style={{display:item.tax?"table-row":"none"}}>
                          <th colSpan="5" align="right">Tax : </th>
                          <th align="right">{item.tax}</th>
                        </tr>
                        <tr style={{display:item.grand_total?"table-row":"none",fontSize : "12px",color : "#ea8000"}}>
                          <th colSpan="5" align="right">Grand Total (Rs) :</th>
                          <th align="right">{item.grand_total}</th>
                        </tr>
                      </tfoot> */}
                      </table>
                    </div>
                    <div className="approve_cancel_allowance">
                      <div className="allowance_approve_button"
                            allowance_id = {item.emp_ta_id}
                            style={{display:item.ta_status=="DRAFT"?"block":"none"}}
                        >
                          <span className="">APPROVE</span>
                      </div>
                      <div className="allowance_cancel_button"
                            allowance_id = {item.emp_ta_id}
                            style={{display:item.ta_status=="DRAFT"?"block":"none"}}
                        >
                          {/* <i className="fa fa-times-circle"></i> */}
                          <span className="">REJECT</span>
                      </div>
                    </div>
                          
                  </div>

                ))}
              </div>
            </div>

          </div>
          <div className="bottom_navbar_spacer"></div>

      </div>
    )
  }
}

export default TravelAllowanceApproval;
TravelAllowanceApproval.contextType = MyContext;