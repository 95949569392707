import './BottomNavbar.scss';

import React, { Component } from "react";

import MyContext from '../MyContext.jsx';



class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount(){
    this.select_nav_button(this.props.history.location.pathname)
    var obj_this = this;
    $("#root").on("click",".nav_button",function(){
       obj_this.props.history.push($(this).attr("to"));
    });
   
   this.unlisten = this.props.history.listen((location, action) => {
      this.select_nav_button(location.pathname)
    });

    if(window.cordova){
      window.addEventListener('keyboardWillShow', function () {
        $(".bottom_nav_placeholder").css("display","none");
      });
      window.addEventListener('keyboardDidShow', function () {
        document.activeElement.scrollIntoView({behavior: "smooth",block: "center"});
      });
      window.addEventListener('keyboardDidHide', function () {
        // document.activeElement.scrollIntoView({behavior: "smooth",block: "center"});
        $(".bottom_nav_placeholder").css("display","block");
      });
    }
  }
  componentWillUnmount() {
      this.unlisten();
  }

  select_nav_button(current_url){
    var nav_buttons_DOMs = $(".nav_button");
    nav_buttons_DOMs.each(function() {
      if(current_url.search($(this).attr("to")) >= 0){
        // console.log(current_url,$(this).attr("to"));
        var left = $(this).offset().left;
        left = left+$(this).width()/2;
        $(".nav_button").css("color","#808080b0");
        $(this).css("color","#420b3e");
        $(".bottom_nav_container .shuttle").css("left" , left);
      }
    });
  }
render(){
  const {pathname} = this.props.location;
  if(pathname === "/android_asset/www/index.html" || pathname === "/") {
    return null;
  }
  return (
    <MyContext.Consumer>
      {context => (
      <div className="bottom_nav_placeholder">
        <div className="bottom_nav_container noselect">
          <div className="shuttle"></div>
          <div className="bottom_nav">
            <div className="grid_item nav_button" to='/home'>
              <i className="fas fa-home"></i>
              <div>Home</div>
            </div>

            <div className="grid_item nav_button" to={2==1?'#':'/salesorders'} >
              <i className="fas fa-clipboard-list"></i>
              <div>Salesorder</div>
            </div>

             {/* <div className="grid_item nav_button" to={this.context.user_details.designation=='FSM'|| this.context.user_details.designation=='FSM 1'?'/schedules':'/jointvisit'}>
              <i className="fas fa-calendar-alt"></i>
              <div>Schedules</div>
            </div> */}

              <div className="grid_item nav_button" to={2==1?'#':'/outstandings'}>
                <i className="fa fa-th-list"></i>
              <div>Outstanding</div>
            </div>



            <div className="grid_item nav_button" to={2==1?'#':'/distributorsalesreport'}>
              <i className="fas fa-paper-plane"></i>
              <div>D/S Report</div>
            </div>

            <div className="grid_item nav_button" to={2==1?'#':'/dashboard'}>
              <i className="fas fa-th"></i>
              <div>Dashboard</div>
            </div>


          </div>
        </div>
      </div>
      )}
    </MyContext.Consumer>
  )}
}

export default Footer;
Footer.contextType = MyContext;
