//Epoch To Date
function epochToDate(epoch) {
  if(epoch < 10000000000)
    epoch *= 1000; // convert to milliseconds (Epoch is usually expressed in seconds, but Javascript uses Milliseconds)
  var date_str = new Date(epoch);
  return date_str;
}

export {
  epochToDate
}