import React, { Component, createRef } from "react";
import {Link} from "react-router-dom";
import DatePicker from 'react-date-picker';
import Select from "react-select";
import axios from 'axios';


import './ScheduleDetails.scss';
import MyContext from '../MyContext.jsx';
import {epochToDate} from '../../assets/js/handy.mjs';

class ScheduleDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      purposes : [],
      purpose : {},
      complaint : '',
      activity : '',
      outstanding : 0,
      exe_schedule : {},
      inf_image:'',
      file : '',
      file_attached : ''
    }
    this.formChange = this.formChange.bind(this);
    this.purposeofVisitSelect= React.createRef();
  }

  componentDidMount(){   
    const url = `${process.env.REACT_APP_SSS}/api/getScheduleDetails`;
    this.context.loading(1);
    var obj_this = this;
    // if(window.cordova){
    //   geo.getLocation(function(location){
    //     obj_this.context.setLocation(location);
    //   });
    // }
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid,
        schedule_id : this.props.match.params.sched_id,
        edit_schedule_id : this.props.match.params.edit_sched_id,
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      if(return_data.status == "success"){
        obj_this.setState({
          purposes : return_data.purposes,
          outstanding : return_data.outstanding,
          sales : return_data.sales,
          exe_schedule : return_data.exe_schedule,
          purpose : return_data.purpose,
          complaint : return_data.exe_schedule.complaint??'',
          activity : return_data.exe_schedule.activity??'',
          lead_types  : return_data.lead_types??'',
          lead_type   : return_data.lead_type??'',
          remarks : return_data.exe_schedule.remarks
        });
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      alert("Network error");
    });   

    // $(".location_button").on("click",function(){
    //   if(window.cordova){
    //       geo.getLocation(function(location){
    //         obj_this.context.setLocation(location);
    //       });
    //   }else{
    //     alert("Couldn't fetch your location");
    //   }
    // });
  }

  purposeChange(option) {
    this.setState({
      purpose: option
    });
  }

  LeadTypeChange(option){
    this.setState({
      lead_type :option
    });
  }

  formChange(e){
    this.setState({
      [e.target.name] : e.target.value
    });
  }

  uploadImage = (e) => {
    this.setState({
        file: e.target.files[0]
    })
  }

  formSubmit(e){
    e.preventDefault();
    var obj_this = this;

    if(!this.state.purpose){
       this.purposeofVisitSelect.current.focus();
      return;
    }
    // console.log(this.state);
    // return;
    var obj_this = this;
    // var location_coordinates = this.context.location;
    // if(window.cordova){
    //   if(!location_coordinates.latitude){
    //     if(!confirm("Submit without location coordinates")){
    //       geo.getLocation(function(location){
    //         location_coordinates = location;
    //         obj_this.context.setLocation(location);
    //       });
    //       return;
    //     }
    //   }
    // }
    // const url = `${process.env.REACT_APP_SSS}/api/saveScheduleDetails`;
    this.context.loading(1);
    var obj_this = this;
    // var location_coordinates = this.context.location;
    // if(window.cordova){
    //   if(!location_coordinates.latitude){
    //     if(!confirm("Submit without location coordinates")){
    //       geo.getLocation(function(location){
    //         location_coordinates = location;
    //         obj_this.context.setLocation(location);
    //       });
    //       return;
    //     }
    //   }
    // }
    var sid =  this.context.user_details.sid;
      if(this.props.match.params.sched_id == undefined) {
        this.props.match.params.sched_id = "";
      }
      var fd = new FormData(document.querySelector('form'));
      fd.append('sid',sid);
      fd.append('schedule_id',this.props.match.params.sched_id);
      fd.append('outstanding',this.state.outstanding);
      fd.append('sales',this.state.sales);
      // fd.append('latitude',location_coordinates.latitude);
      // fd.append('longitude',location_coordinates.longitude);

      fd.append('edit_schedule_id',this.props.match.params.edit_sched_id);


      const url = `${process.env.REACT_APP_SSS}/api/saveScheduleDetails`;

      axios.post(url,fd)
      .then(res =>{
        console.log(res.data);
        if(res.data.status == "success"){
          alert("Schedule details updated");
          obj_this.props.history.replace('/schedules');
          obj_this.context.loading(0);
  
        }
        else {
          alert("Network Error.");
          obj_this.context.loading(0);
        }
      });
  }

  render(){
  return (
    <MyContext.Consumer>
      {context => (
        <div className="schedule_details_page_container">
          <div className="page_header">
            <Link className="back_button" to="/schedules"><i className="fas fa-arrow-left"></i></Link>
            <div className="page_title">Schedule</div>
          </div>
          <div className="customer_details">
            <div className="customer_name">{this.state.exe_schedule.customer_name}</div>
            <div className="outstanding_sales_container" style={{display:this.state.exe_schedule.customer_type=="D"?"grid":"none"}}>
              <div className="outstanding">
                <div className="amount"><i className="fas fa-rupee-sign"></i> {this.state.outstanding}</div>
                <div className="label">Outstanding</div>
              </div>
              <div className="sales">
                <div className="amount"><i className="fas fa-rupee-sign"></i> {this.state.sales}</div>
                <div className="label">Sales</div>
              </div>
            </div>
          </div> 
          <form className="schedule_form"
            onSubmit={e => this.formSubmit(e)} 
            encType="multipart/form-data"
          >
            <div className="schedule_details_container blocks">
              <div className="input_label">Purpose of visit *</div>
              <Select 
                className="purposes" 
                ref={this.purposeofVisitSelect}
                placeholder={""}
                options= {this.state.purposes}
                value={this.state.purpose}
                name ="purpose"
                onChange={e => this.purposeChange(e)}
                required
              />
              <div style={{display:this.state.exe_schedule.customer_type=='DOCTORS'?'block':'none'}}>
                <div className="input_label" >Lead type</div>
                <Select 
                  className="purposes" 
                  name="leadtype"
                  placeholder={""}
                  options= {this.state.lead_types}
                  value =  {this.state.lead_type}
                  onChange={e => this.LeadTypeChange(e)}
                  required
                />
              </div>
              <div style={{display:this.state.exe_schedule.customer_type=='DOCTORS'?'block':'none'}}>
                <div className="input_label">Remarks</div>
                  <textarea className="input remarks custom_input" 
                    name="remarks"
                    value={this.state.remarks}
                    onChange={this.formChange}
                    autoComplete="off"
                  />
              </div>
              <div style={{display:this.state.exe_schedule.customer_type=='DOCTORS'?'none':'block'}}>
                <div className="input_label">Complaint if any</div>
                <input className="complaint input custom_input"
                  type="text"
                  name="complaint"
                  value={this.state.complaint}
                  onChange={this.formChange}
                  autoComplete="off"
                />
              </div>
              <div style={{display:this.state.exe_schedule.customer_type=='DOCTORS'?'none':'block'}}>
                <div className="input_label">Activity</div>
                <textarea className="input activity custom_input" 
                  name="activity"
                  value={this.state.activity}
                  onChange={this.formChange}
                  autoComplete="off"
                />
              </div>
              {/* <div className="input_label">Location coordinates</div>
              <div className="location_container">
                <input className="complaint input custom_input"
                  type="text"
                  name="location"
                  value={this.context.location.latitude+", "+this.context.location.longitude}
                  onChange={this.formChange}
                  autoComplete="off"
                  disabled
                />
                <div className="location_button">
                  <i className="fas fa-map-marker-alt"></i>
                </div>
              </div> */}
            </div>
            <button type="submit" 
              className="submit_button"
            >
              <i className="far fa-save"></i> {this.props.match.params.sched_id?"CHECK OUT":"UPDATE"}
            </button>
          </form>           
        </div>
      )}
    </MyContext.Consumer>
  )}
}

export default ScheduleDetails;
ScheduleDetails.contextType = MyContext;
