import React, { Component } from "react";
import axios from "axios";
// import $ from 'jquery';

import './Header.scss';
import MyContext from '../MyContext.jsx';
import Select from "react-select";


class Header extends Component {
  constructor(props) {
    super(props);
    // this.props.history.replace('/');
    this.state = {
    }
    this.divisionChange = this.divisionChange.bind(this);

  }

  componentDidMount(){ 
    var obj_this = this;
    $("#root").on("click",".logout_button", function(){
      if(!confirm("Are you sure you want to logout?")){
        return;
      }
      // obj_this.context.loading(1);
      var user_details = window.localStorage.getItem("user_details_pharmacopius_emak_app");
      console.log(user_details);
      const url = `${process.env.REACT_APP_SSS}/api/logout`;
      $.ajax({
        url : url,
        data : {
          user_details : user_details,
          sid : obj_this.context.user_details.sid
        },
        type : "POST"
      }).done(function(return_data){
        console.log(return_data);
        if(return_data.status == "success"){
          alert("Logged out successfully");
          obj_this.context.setUserDetails({});
          user_details = JSON.parse(user_details);
          let new_user= {
            "username":user_details.username,
            "password" : user_details.password
          };
          window.localStorage.setItem("user_details_pharmacopius_emak_app",JSON.stringify(new_user));
          obj_this.props.history.replace('/');
        }else{
          alert("Network error!!");
          obj_this.props.history.replace('/');
        }
        // obj_this.context.loading(0);
      }).fail(function(){
          alert("Network error!!");
          obj_this.props.history.replace('/');
      });
    });
    // cordova.plugins.locationAccuracy.request(function(){console.log("location success")}, function(){"location error"});
  }
  divisionChange(option){
    if(option.value == this.context.user_details.division.value){
      return;
    }
    if(!confirm("Are you sure, Change division to "+option.label+" ?")){
      return;
    }
    var obj_this = this;
    obj_this.context.loading(1);
    $.ajax({
      url : process.env.REACT_APP_SSS + "/api/changeDivision",
      data : {
        division: option,
        sid : obj_this.context.user_details.sid,
        username : obj_this.context.user_details.username,
        password : obj_this.context.user_details.password,
      },
      type : "POST"
    }).done(function(return_data){
      // console.log(return_data);
      if(return_data.status == "success"){
        delete return_data.status;
        return_data.division = option;
        obj_this.context.setUserDetails(return_data);
        window.localStorage.setItem("user_details_pharmacopius_emak_app",JSON.stringify(return_data));
        obj_this.props.history.replace('/dashboard');
        obj_this.props.history.replace('/home');
      }else{
        alert("You are not authorized to access");
      }
      obj_this.context.loading(0);
    }).fail(function(){
      alert("Network error");
    });
  }


  render(){
  const {pathname} = this.props.location;
  if(pathname === "/android_asset/www/index.html" || pathname === "/") {
    return null;
  }
  return (
    <MyContext.Consumer>
      {context => (
        <div className="header_container">
          <div className="app_name">E-MAK Pro</div>
          <div className="name_division_container">
            <div className="username">Hello, {this.context.user_details.fullname}</div>
            {/* <div className="division">{this.context.user_details.division?this.context.user_details.division.label:""}</div> */}
            <div className="division">
            <Select className="division_select"
              options={this.context.user_details.divisions}
              value={this.context.user_details.division}
              onChange={this.divisionChange}
              isSearchable={false}
            />
          </div>
          </div>
          <div className="logout_button">
            <i className="fa fa-sign-out-alt" style={{padding:"10px"}}></i>
          </div>
        </div>
      )}
    </MyContext.Consumer>
  )}
}

export default Header;
Header.contextType = MyContext;

