// PrivateRoute.js
import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import MyContext from './../MyContext';

class PrivateRoute extends Component {
  static contextType = MyContext;

  render() {
    const { component: Component, ...rest } = this.props;
    const { user_details } = this.context;

    const divisionExists = user_details && user_details.division;

    return (
      <Route
        {...rest}
        render={(props) =>
          divisionExists ? <Component {...props} /> : <Redirect to="/division" />
        }
      />
    );
  }
}

export default PrivateRoute;
