import React , {Component} from "react";
import MyContext from '../MyContext.jsx';
import {Link} from "react-router-dom";
import { CgSearch } from 'react-icons/cg';
import Select from "react-select";

import './Myvisitfrequency.scss';

class MyVisitFrequency extends Component{
  constructor(props){
    super(props);
    this.state = {
      doctors : [],
      showing_doctors:[],
      search:''
    }
    this.formChange = this.formChange.bind(this);

  }
  componentDidMount(){
    var obj_this = this;
    const url2 = `${process.env.REACT_APP_SSS}/api/getVisitFrequency`;
    this.context.loading(1);
    $.ajax({
      url : url2,
      data : {
        sid : this.context.user_details.sid,
        // executive_id : this.state.executive.value,
        // due_period_id : this.state.due_period.value
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      // doctors = doctors.sort(function(a,b){
      //   return a.no_due - b.no_due
      // });
      // return;
      
      if(return_data.status == "success"){
        obj_this.setState({
         doctors : return_data.doctors,
         showing_doctors:return_data.doctors
        });
        // debugger;
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      alert("Network error");
    });
    
  }

  formChange(e){
    // console.log(e.target.value);
    var showing_doctor =[];
    if(e.target.value){
      showing_doctor = this.state.showing_doctors.filter((item) => {return item.influencer_name.toLowerCase().includes(e.target.value.toLowerCase())});
    }
    else{
      showing_doctor = this.state.doctors;
    }
    // showing_doctors = showing_doctors.length?showing_doctors:this.state.doc_name;
    this.setState({
      showing_doctors : showing_doctor
    });
  }

  render(){
    return(
      <MyContext.Consumer>
        {context => (
        <div className="my_visit_frequency_page_container">
          <div className="page_header">
            <Link className="back_button" to="/home"><i className="fas fa-arrow-left"></i></Link>
            <div className="page_title">Visit Frequency Report</div>
          </div> 
          <div className="search_container">
            <CgSearch/>
            <input className="customer_search_input" 
              placeholder="Search doctor.."
              onChange={this.formChange}
              value={this.search}
            />
          </div>
          <div className="table_container">
            <table className="customer_outstandings_table">
              <thead>
                <tr>
                  <th style={{maxWidth:"40px"}} align="center">#</th>
                  <th>Doctor</th>
                </tr>
              </thead>
              <tbody>
              <tr style={{display:this.state.showing_doctors.length?"none":"table-row"}}>
                <td colSpan="2" align="center">No result!</td>
              </tr>
              {this.state.showing_doctors && this.state.showing_doctors.map((cust,index) => (
                <tr key={index}  style={{backgroundColor:cust.checkin.length<4 ?"#ffa8b3":""}}>
                  <td valign="top" align="center">{index+1}</td>
                  <td valign="top" style={{padding:"5px 0px"}}>
                    <div style={{fontWeight:"500",paddingLeft:"5px"}}>{cust.influencer_name}</div>
                    <div className="bills_table_container" style={{display:cust.checkin.length>0?"block":"none"}}>
                      {/* <span style={{display:cust.checkin.length==0?"block":"none"}}>
                        <span style={{align:"center"}}>No Check in !!!</span>
                      </span> */}
                      <table className="bills_table input-grid" >
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Remarks</th>
                          </tr>
                        </thead>
                        <tbody >
                          {cust.checkin.map((custs,idx) => (
                          <tr key={idx}>
                            <td>{custs.check_in}</td>
                            <td>{custs.remarks}</td>
                          </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>

      )}
    </MyContext.Consumer>
  )}
}

export default MyVisitFrequency;
MyVisitFrequency.contextType = MyContext;
